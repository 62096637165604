import { useMemo } from "react";
import {
  Control,
  FieldErrors,
  useForm,
  UseFormGetValues,
  UseFormSetValue,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import yup from "@/shared/validation/yup";
import { MAX_LENGTH } from "@/configs/constants";

export type DocumentVideosAttributesForm = {
  id?: number | string;
  video_id?: {
    label: string;
    value: number;
  } | null;
  document_id?: {
    label: string;
    value: number;
  } | null;
  _destroy?: boolean;
};

export interface ICreateContentForm {
  title: string;
  attachment_attributes: {
    id?: number;
    _destroy?: boolean;
    key: string;
    name: string;
    content_type: string;
  } | null;
  content: string | null;
  document_videos_attributes: DocumentVideosAttributesForm[];
  content_not_null: string | null | undefined;
}

export const useCreateContentForm = () => {
  const validationScheme = useMemo(
    () =>
      yup.object().shape({
        title: yup
          .string()
          .label("admin.document.create.title")
          .max(MAX_LENGTH.VARCHAR)
          .noEmoji()
          .required(),
        content: yup
          .string()
          .label("admin.document.create.content")
          .max(MAX_LENGTH.TEXT)
          .nullable(),
        attachment_attributes: yup
          .object()
          .shape({
            id: yup.string().nullable(),
            _destroy: yup.boolean().nullable(),
            key: yup.string().nullable(),
            name: yup.string().nullable(),
            content_type: yup.string().nullable(),
          })
          .default(null)
          .nullable(),
        content_not_null: yup
          .string()
          .when(["content", "attachment_attributes"], {
            is: (value1: string, value2: any) => {
              const isAttachmentNull =
                value2 == null || !value2.name || value2._destroy;
              return !value1 && isAttachmentNull;
            },
            then: (schema) => schema.required("コンテンツを入力してください。"),
            otherwise: (schema) => schema.nullable(),
          }),
        document_videos_attributes: yup
          .array()
          .of(yup.object())
          .label("admin.document.create.document_videos_attributes.video")
          .optional()
          .nullable(),
      }),
    [],
  );

  return useForm<ICreateContentForm>({
    resolver: yupResolver(validationScheme) as any,
    shouldFocusError: true,
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    defaultValues: {
      document_videos_attributes: [
        {
          id: undefined,
          video_id: undefined,
          document_id: undefined,
        },
      ],
    },
  });
};

export interface ICreateContentVideoForm {
  title: string;
  youtube_id: string | null;
  youtube_file_name: string | null;
  document_videos_attributes: DocumentVideosAttributesForm[];
}

export const useCreateContentVideoFForm = () => {
  const validationScheme = useMemo(
    () =>
      yup.object().shape({
        title: yup
          .string()
          .label("admin.video_id.create.title")
          .max(MAX_LENGTH.VARCHAR)
          .noEmoji()
          .required(),
        youtube_id: yup
          .string()
          .label("admin.video_id.create.content")
          .required(),
        youtube_file_name: yup.string().nullable(),
        document_videos_attributes: yup
          .array()
          .of(yup.object())
          .label("admin.document.create.document_videos_attributes.document")
          .optional()
          .nullable(),
      }),
    [],
  );

  return useForm<ICreateContentVideoForm>({
    resolver: yupResolver(validationScheme) as any,
    shouldFocusError: true,
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    defaultValues: {
      document_videos_attributes: [
        {
          id: undefined,
          video_id: undefined,
          document_id: undefined,
        },
      ],
    },
  });
};
export interface ICreateContentChildFormProps {
  control: Control<ICreateContentForm, any>;
  errors: FieldErrors<ICreateContentForm>;
  setValue: UseFormSetValue<ICreateContentForm>;
  getValues: UseFormGetValues<ICreateContentForm>;
}

export interface ICreateContentVideoFormProps {
  control: Control<ICreateContentVideoForm, any>;
  errors: FieldErrors<ICreateContentVideoForm>;
  setValue: UseFormSetValue<ICreateContentVideoForm>;
  getValues: UseFormGetValues<ICreateContentVideoForm>;
}
