import { Box } from "@chakra-ui/react";
import Logo2 from "@/assets/logo2.svg";
import SidebarItems, {
  Option,
} from "@/components/atoms/SidebarItems/sidebar-item";
import UsersIcon from "@/assets/icon/users-01.svg";
import FileAttachmentIcon from "@/assets/icon/file-attachment-04.svg";
import FileCheckIcon from "@/assets/icon/file-check-03.svg";
import EditIcon from "@/assets/icon/edit-02.svg";
import FolderIcon from "@/assets/icon/folder.svg";
import TagIcon from "@/assets/icon/tag.svg";
import BuildingIcon from "@/assets/icon/building-01.svg";
import { ADMIN_ROUTES_CONSTANT } from "@/configs/constants";
import { delay } from "framer-motion";
import { JSX } from "react/jsx-runtime";
// import { useLogoutMutation } from "@/api/auth";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { STORAGE_KEYS } from "@/api/axios";
import { useGlobalStore } from "@/states/global.state";
import { useAuthStore } from "@/states/auth.state";
import { ROLE_ENUM } from "@/configs/enum";
import { useMemo } from "react";
import SidebarSkeleton from "./sidebar.skeleton";

export interface SidebarProps {}

const options: Option[] = [
  {
    key: "users-management",
    label: "ユーザー管理",
    icon: <UsersIcon />,
    route: ADMIN_ROUTES_CONSTANT.MANAGEMENT.USER,
    roles: [ROLE_ENUM.ADMIN_SYSTEM, ROLE_ENUM.CONTENT_MANAGER],
  },
  {
    key: "e-learning-list",
    label: "Eラーニング一覧",
    icon: <FileAttachmentIcon />,
    route: ADMIN_ROUTES_CONSTANT.MANAGEMENT.ELEARNING,
    roles: [ROLE_ENUM.ADMIN_SYSTEM, ROLE_ENUM.CONTENT_MANAGER],
  },
  {
    key: "compliance-training",
    label: "コンプライアンス研修",
    icon: <FileCheckIcon />,
    route: ADMIN_ROUTES_CONSTANT.MANAGEMENT.COMPLIANCE_TRAINING,
    roles: [ROLE_ENUM.ADMIN_SYSTEM, ROLE_ENUM.CONTENT_MANAGER],
  },
  {
    key: "test-list",
    label: "テスト一覧",
    icon: <EditIcon />,
    route: ADMIN_ROUTES_CONSTANT.MANAGEMENT.QUIZZ,
    roles: [ROLE_ENUM.ADMIN_SYSTEM, ROLE_ENUM.CONTENT_MANAGER],
  },
  {
    key: "content-list",
    label: "コンテンツ",
    icon: <FolderIcon />,
    route: ADMIN_ROUTES_CONSTANT.MANAGEMENT.CONTENT,
    roles: [ROLE_ENUM.ADMIN_SYSTEM, ROLE_ENUM.CONTENT_MANAGER],
    children: [
      {
        key: "document-list",
        label: "ドキュメント一覧",
        route: ADMIN_ROUTES_CONSTANT.MANAGEMENT.CONTENT_DOCUMENT,
      },
      {
        key: "video-list",
        label: "動画一覧",
        route: ADMIN_ROUTES_CONSTANT.MANAGEMENT.CONTENT_VIDEO,
      },
    ],
  },
  {
    key: "category-tag",
    label: "カテゴリ・タグ",
    icon: <TagIcon />,
    route: ADMIN_ROUTES_CONSTANT.MANAGEMENT.CATEGORY_TAG,
    roles: [ROLE_ENUM.ADMIN_SYSTEM, ROLE_ENUM.CONTENT_MANAGER],
    children: [
      {
        key: "category-list",
        label: "カテゴリ一覧",
        route: ADMIN_ROUTES_CONSTANT.MANAGEMENT.CATEGORY,
      },
      {
        key: "tag-list",
        label: "タグ一覧",
        route: ADMIN_ROUTES_CONSTANT.MANAGEMENT.TAG,
      },
    ],
  },
  {
    key: "affiliation-management",
    label: "所属管理",
    icon: <BuildingIcon />,
    route: ADMIN_ROUTES_CONSTANT.MANAGEMENT.AFFILIATION,
    roles: [ROLE_ENUM.ADMIN_SYSTEM],
    children: [
      {
        key: "office-list",
        label: "支社一覧",
        route: ADMIN_ROUTES_CONSTANT.MANAGEMENT.OFFICE,
      },
      {
        key: "department-list",
        label: "事業部一覧",
        route: ADMIN_ROUTES_CONSTANT.MANAGEMENT.DEPARTMENT,
      },
    ],
  },
];

/**
 * Renders the sidebar component.
 *
 * @returns {JSX.Element} The rendered sidebar component.
 */
function Sidebar(): JSX.Element {
  const navigate = useNavigate();
  const { userInfo } = useAuthStore();
  // const logoutMutation = useLogoutMutation();
  const { setLoading } = useGlobalStore();

  const handleLogout = async () => {
    try {
      setLoading(true, true);
      // await logoutMutation.mutateAsync({});
    } finally {
      delay(() => {
        setLoading(false, false);
        Cookies.remove(STORAGE_KEYS.ACCESS_TOKEN);
        navigate(ADMIN_ROUTES_CONSTANT.LOGIN);
      }, 500);
    }
  };

  const sidebarItems = useMemo(() => {
    if (!userInfo) return [];
    return options.filter((i) => {
      if (i.roles) {
        return i.roles.includes(userInfo.role.id);
      }
      return true;
    });
  }, [userInfo]);

  return (
    <Box
      minWidth={"280px"}
      background={"white"}
      padding={"24px 16px"}
      display="flex"
      flexDirection={"column"}
      height={"100vh"}
      overflow={"auto"}
    >
      <Box
        display="flex"
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"column"}
        marginTop={"8px"}
        cursor="pointer"
        onClickCapture={() => {
          if (userInfo?.role.id === ROLE_ENUM.ADMIN_SYSTEM)
            navigate(ADMIN_ROUTES_CONSTANT.MANAGEMENT.USER_LIST);
          else navigate(ADMIN_ROUTES_CONSTANT.MANAGEMENT.ELEARNING_LIST);
        }}
      >
        <Logo2 />
        <h6 className="font-black mt-2">管理画面</h6>
      </Box>
      {userInfo ? (
        <>
          <div className="w-full h-[1px] bg-[var(--border-primary)] my-7"></div>
          <SidebarItems options={sidebarItems} />
          <Box
            as="button"
            marginTop={"28px"}
            padding={"4px 16px"}
            className="body2"
            width={"fit-content"}
            color={"var(--error-main-color)"}
            onClickCapture={handleLogout}
          >
            ログアウト
          </Box>
        </>
      ) : (
        <SidebarSkeleton />
      )}
    </Box>
  );
}

export default function AnimatedSidebar() {
  return <Sidebar />;
}
