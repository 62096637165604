import { ControlledInput } from "@/components/molecules/ControlledInput/controlled-input";
import { ICreateQuizzForm } from "./validation";
import { MAX_LENGTH } from "@/configs/constants";
import {
  Control,
  FieldErrors,
  UseFormSetError,
  UseFormSetValue,
  UseFormWatch,
  useFieldArray,
  useWatch,
} from "react-hook-form";
import Button from "@/components/atoms/Button/button";
import PlusIcon from "@/assets/icon/plus.svg";
import CircleCloseIcon from "@/assets/icon/circle-close.svg";
import { ControlledSelect } from "@/components/molecules/ControlledSelect/controlled-select";
import { v4 as uuidv4 } from "uuid";
import { useCallback, useMemo } from "react";
import clsx from "clsx";
import { isNumeric } from "@/shared/get/check";

interface SingleChoiceComponentProps {
  index: number;
  control: Control<ICreateQuizzForm>;
  errors: FieldErrors<ICreateQuizzForm>;
  watch: UseFormWatch<ICreateQuizzForm>;
  setValue: UseFormSetValue<ICreateQuizzForm>;
  setError: UseFormSetError<ICreateQuizzForm>;
}
export default function SingleChoiceComponent({
  index,
  control,
  errors,
  watch,
  setValue,
}: SingleChoiceComponentProps) {
  const { fields, remove, append } = useFieldArray({
    control,
    name: `questions.${index}.answers`,
  });
  const answers = useWatch({
    name: `questions.${index}.answers`,
    control,
  });

  const correctAnswerOptions = useMemo(() => {
    if (!answers) return [];
    return answers
      .filter((i) => i.title?.length)
      .map((answer, _index) => ({
        value: answer._id,
        label: `項目${_index + 1}`,
      }));
  }, [answers]);

  const onAddAnswer = useCallback(() => {
    append({
      _id: uuidv4(),
      title: "",
      point: 0,
    });
  }, [append]);

  const onRemoveAnswer = useCallback(
    (_index: number) => {
      const deleted = watch(`questions.${index}.answers_deleted`);
      const answer = watch(`questions.${index}.answers.${_index}`);
      const correctAnswer = watch(`questions.${index}.correct_answer`);
      if (correctAnswer && correctAnswer.value === answer._id) {
        setValue(`questions.${index}.correct_answer`, null);
      }
      if (isNumeric(answer._id))
        setValue(`questions.${index}.answers_deleted`, [...deleted, answer]);

      remove(_index);
    },
    [remove, watch, setValue],
  );

  return (
    <div>
      <div>
        <p className="subtitle1 font-bold text-[#637381]">回答項目</p>
        {fields.map((field, _index) => (
          <div className="flex items-center gap-x-4" key={field._id}>
            <span className="w-2 h-2 rounded-full bg-[#637381] mt-8"></span>
            <ControlledInput
              control={control}
              formField={`questions.${index}.answers.${_index}.title`}
              isTrim={true}
              placeholder="項目を入力してください"
              className="mt-3 w-full"
              label={`項目${_index + 1}`}
              maxLength={MAX_LENGTH.VARCHAR}
              errorMessage={
                errors.questions?.[index]?.answers?.[_index]?.title?.message ??
                ""
              }
            />
            {_index > 0 && (
              <CircleCloseIcon
                className="cursor-pointer mt-8"
                onClick={() => onRemoveAnswer(_index)}
              />
            )}
          </div>
        ))}
      </div>

      <Button
        size="xs"
        className="ml-6 mt-3 w-[136px]"
        buttonCustom={{
          fontSize: "12px",
          lineHeight: "16px",
          padding: "7px 12px",
        }}
        variant={"primary"}
        startSlot={<PlusIcon width={20} height={20} />}
        onClick={onAddAnswer}
      >
        項目を追加する
      </Button>

      <p className="mt-6 subtitle1 font-bold text-[#637381]">正解の項目</p>
      <ControlledSelect
        options={correctAnswerOptions}
        control={control}
        formField={`questions.${index}.correct_answer`}
        placeholder=""
        isRequired={true}
        isClearable={true}
        className="mt-3"
        label="項目番号"
        errorMessage={
          errors.questions?.[index]?.correct_answer?.message?.toString() ?? ""
        }
      />
      <div className="mt-3 flex items-end">
        <ControlledInput
          control={control}
          formField={`questions.${index}.point`}
          placeholder="点数を入力してください"
          label="点数"
          isTrim={true}
          isNumber={true}
          isInteger={true}
          autoConvertToHalfWidth
          min={0}
          errorMessage={errors.questions?.[index]?.point?.message ?? ""}
          className="w-[240px]"
        />
        <span
          className={clsx(
            "body1 text-[#212B36] ml-[10px]",
            (errors.questions?.[index]?.point?.message?.toString() ?? "")
              .length > 0 && "mb-4",
          )}
        >
          点
        </span>
      </div>
    </div>
  );
}
