import FrontLayout from "@/pages/_layouts/front-main";
import ELearningDashboardList from "@/components/organisms/ELearningList/e-learning-list-dashboard";
import ArrowRightIcon from "@/assets/icon/arrow-right-dashboard.svg";
import AnimatedRoute from "@/router/transition.route";
import { ROUTES_CONSTANT } from "@/configs/constants";
import NotificationDashboardFront from "@/components/molecules/Notification/notification-dashboard.front";
import { useNavigate } from "react-router-dom";
import { getRoute } from "@/shared/get/route";
import HistoriesList from "@/components/organisms/Histories/histories-list";
type GoToPage = "E-learning" | "Content";
export function DashboardComponent() {
  const navigate = useNavigate();
  const goToPage = (page: GoToPage) => {
    if (page === "E-learning") {
      navigate(getRoute(ROUTES_CONSTANT.ELEARNING_LIST));
    }
    if (page === "Content") {
      navigate(getRoute(ROUTES_CONSTANT.CONTENT_DOCUMENT_LIST));
    }
  };
  return (
    <div className="desktop:py-12 flex max-w-[960px] w-full gap-8 flex-col desktop:mx-auto mobile:py-6 mobile:px-4 desktop:px-0">
      <div className="flex flex-row items-center justify-between flex-wrap gap-y-2 gap-x-4">
        <h4>ホーム</h4>
        <div className="flex gap-x-8 gap-y-2 flex-wrap">
          <div className="flex gap-3 items-center">
            <button
              className="body1 text-[#223909] cursor-pointer"
              onClick={() => goToPage("E-learning")}
            >
              Eラーニングへ
            </button>
            <ArrowRightIcon />
          </div>
          <div className="flex gap-3 items-center">
            <button
              className="body1 text-[#223909] cursor-pointer"
              onClick={() => goToPage("Content")}
            >
              コンテンツ一覧へ
            </button>
            <ArrowRightIcon fill="" style={{ fill: "#223909" }} />
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-5">
        <div className="flex desktop:flex-row mobile:flex-col gap-5">
          <div className="desktop:w-[calc(100%-380px)] mobile:w-full bg-white rounded-xl [box-shadow:0px_0px_16px_0px_#22390914] py-5 border-[1px] border-solid border-[#E6E8EA] flex flex-col">
            <h6 className="h6 !font-black text-[var(--primary-main-color)] pb-3 px-5">
              未対応Eラーニング
            </h6>
            <ELearningDashboardList />
          </div>
          <div className="desktop:w-[360px] mobile:w-full h-[274px] bg-white rounded-xl [box-shadow:0px_0px_16px_0px_#22390914] py-5 border-[1px] border-solid border-[#E6E8EA] flex flex-col">
            <h6 className="h6 !font-black text-[var(--primary-main-color)] px-5 pb-3">
              新着通知
            </h6>
            <NotificationDashboardFront />
          </div>
        </div>
        <div className="h-[214px] bg-white rounded-xl [box-shadow:0px_0px_16px_0px_#22390914] py-5 border-[1px] border-solid border-[#E6E8EA] flex flex-col">
          <h6 className="h6 !font-black text-[var(--primary-main-color)] px-5 pb-3">
            更新情報
          </h6>
          <HistoriesList />
        </div>
      </div>
    </div>
  );
}

const AnimatedFrontDashboard = AnimatedRoute(DashboardComponent, false);

export default function FrontDashboard() {
  return (
    <FrontLayout>
      <AnimatedFrontDashboard />
    </FrontLayout>
  );
}
