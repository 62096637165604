import { useCallback, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import Spinner from "@/components/atoms/Spinner/spinner";
import { MESSAGES } from "@/shared/validation/message";
import { delay } from "lodash";
import { useQueryClient } from "@tanstack/react-query";
import { PAGINATION_CONSTANT, ROUTES_CONSTANT } from "@/configs/constants";
import { formatDate } from "@/shared/format";
import clsx from "clsx";
import { HistoriesItem } from "@/api/histories/request";
import { fetchFrontListHistoryQuery } from "@/api/histories";
import { useNavigate } from "react-router-dom";
import { getRoute } from "@/shared/get/route";
import CustomTooltip from "@/components/atoms/Tooltip/tooltip";
import { ActionHistoriesText } from "@/configs/enum";

export default function HistoriesList() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [isFetching, setIsFetching] = useState(false);
  const [page, setPage] = useState<number>(PAGINATION_CONSTANT.DEFAULT_PAGE);
  const [history, setHistory] = useState<HistoriesItem[] | []>([]);
  const [hasMore, setHasMore] = useState(true);

  const handleLoadMore = useCallback(async () => {
    if (isFetching) return;
    const nextPage = page + 1;
    setPage(nextPage);
    delay(async () => {
      await fetchHistory(nextPage);
    }, 300);
  }, [page, isFetching]);

  const fetchHistory = async (page = 1) => {
    const res = await fetchFrontListHistoryQuery(queryClient, {
      page,
      per_page: 3,
    });
    const datas = res.histories;
    const paging = res.paging;
    setHasMore(paging.total_pages > page);
    if (paging.current_page === 1) {
      setHistory(datas);
    } else {
      setHistory((prev) => {
        return [...prev, ...datas];
      });
    }
    setIsFetching(false);
  };

  useEffect(() => {
    fetchHistory();
  }, []);
  const handleGoto = (item?: HistoriesItem) => {
    if (!item?.actived) return;
    if (item?.historiable_type === "Course") {
      navigate(
        getRoute(ROUTES_CONSTANT.ELEARNING_DETAIL, {
          id: item.historiable?.id,
        }),
      );
      return;
    }
    if (item?.historiable_type === "Folder") {
      navigate(
        `${
          item?.historiable?.kind === "document"
            ? ROUTES_CONSTANT.CONTENT_DOCUMENT_LIST
            : ROUTES_CONSTANT.CONTENT_VIDEO_LIST
        }?id=${item?.historiable?.id}&init=1`,
      );
      return;
    }
    navigate(
      getRoute(
        item?.historiable_type === "Video"
          ? ROUTES_CONSTANT.CONTENT_VIDEO_DETAIL
          : ROUTES_CONSTANT.CONTENT_DOCUMENT_DETAIL,
        {
          id: item?.historiable?.id,
          folder_id: item?.historiable?.folder_id,
        },
      ),
    );
  };

  const historiesText = (item: HistoriesItem) => {
    const textTitle =
      item?.historiable_type === "Folder"
        ? item?.historiable?.name
        : item?.historiable?.title;

    return textTitle
      ? `${textTitle} ${ActionHistoriesText?.[item?.action]}`
      : item?.content || "";
  };

  return (
    <InfiniteScroll
      next={handleLoadMore}
      height={"134px"}
      hasMore={hasMore}
      loader={null}
      endMessage={null}
      hasChildren={true}
      dataLength={history?.length}
      className="flex flex-col px-5"
    >
      {isFetching && page === 1 ? (
        <div className="flex justify-center items-center min-h-40">
          <Spinner
            circleStyles={{
              borderColor: `var(--primary-main-color) transparent transparent transparent`,
            }}
          />
        </div>
      ) : history?.length === 0 ? (
        <p className="w-full rounded-xl gap-2 p-4 border-primary bg-white text-center subtitle2">
          {MESSAGES.MSG_010}
        </p>
      ) : (
        history?.map((item: HistoriesItem, index) => (
          <div
            key={index}
            className={clsx(
              "flex flex-row gap-3 py-3 hover:bg-[#919EAB14] cursor-pointer flex-wrap",
              index !== 0 && "border-t-[1px] border-solid border-[#E6E8EA]",
            )}
            onClickCapture={() => handleGoto(item)}
          >
            <div className="subtitle2 text-[#212B36] line-clamp-2 line-break-anywhere">
              <CustomTooltip
                max={120}
                text={historiesText(item)}
              ></CustomTooltip>
            </div>
            <div className="caption1 text-[#637381] mt-1">
              {formatDate(item?.updated_at, "YYYY/MM/DD")}
            </div>
          </div>
        ))
      )}
    </InfiniteScroll>
  );
}
