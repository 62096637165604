import {
  showUserCoursesQuery,
  useCreateUserCourseContentMutation,
  useCreateUserCourseMutation,
  useShowCourseQuery,
  useUpdateUserCourseContentMutation,
  useUpdateUserCourseMutation,
} from "@/api/e-learning";
import {
  CreateUserCourseContentRequestBody,
  ShowUserCoursesResponseBody,
  UpdateUserCourseContentRequestBody,
} from "@/api/e-learning/request";
import { showQuizzQuery } from "@/api/quizz";
import { ShowQuizzResponseBody } from "@/api/quizz/request";
import Breadcrumb, {
  BeardcrumbOption,
} from "@/components/atoms/Breadcrumb/breadcrumb";
import Button from "@/components/atoms/Button/button";
import { ModalError } from "@/components/molecules/ModalError/modal-error";
import { Stepper } from "@/components/molecules/Stepper/stepper";
import ElearningComplianceTrainingContent from "@/components/organisms/ElearningContentDetail/compliance-training-content";
import ElearningDocumentContent from "@/components/organisms/ElearningContentDetail/document-content";
import ElearningExamContentControl, {
  ElearningExamContentControlEvent,
} from "@/components/organisms/ElearningContentDetail/exam-content-control";
import ElearningRolePlayingContent from "@/components/organisms/ElearningContentDetail/role-playing-content";
import ElearningVideoContent from "@/components/organisms/ElearningContentDetail/video-content";
import {
  ELEARNING_CONTENT_TYPE_LABEL_2,
  ROUTES_CONSTANT,
} from "@/configs/constants";
import { ELEARNING_CONTENT_KIND_ENUM } from "@/configs/enum";
import { handleApiError } from "@/hooks/error";
import useUpload from "@/hooks/upload";
import FrontLayout from "@/pages/_layouts/front-main";
import AnimatedRoute from "@/router/transition.route";
import { createFileFromBlobURL } from "@/shared/transform/file";
import { useFileStore } from "@/states/file.state";

import { Skeleton, useDisclosure } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import clsx from "clsx";
import { delay, isNil } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

function FrontELearningDetail() {
  const params = useParams();
  const navigate = useNavigate();
  const id = parseInt(params.id as string);
  const queryClient = useQueryClient();
  const quizzRef = useRef<ElearningExamContentControlEvent | null>(null);
  const {
    isOpen: isOpenModalError,
    onOpen: onOpenModalError,
    onClose: onCloseModalError,
  } = useDisclosure();

  const { reset: resetFileStore, files: filesSelected } = useFileStore();

  const [errorPopup, setErrorPopup] = useState("");
  const [activeIndexStep, setActiveIndexStep] = useState(0);
  const [ytbIdsDeleted, setYtbIdsDeleted] = useState<string[]>([]);
  const [isDisabledBtnNext, setIsDisabledBtnNext] = useState(false);
  const [isQuizPassed, setIsQuizPassed] = useState(false);
  const [coursesLearned, setCoursesLearned] =
    useState<ShowUserCoursesResponseBody | null>(null);
  const { data: courseInfo, refetch: refetchCourseInfo } =
    useShowCourseQuery(id);
  const createUserCourseMutation = useCreateUserCourseMutation();
  const createUserCourseContentMutation = useCreateUserCourseContentMutation();
  const updateUserCourseContentMutation = useUpdateUserCourseContentMutation();
  const updateUserCourse = useUpdateUserCourseMutation();
  const { uploadYoutubeFn } = useUpload();
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const isFromMypage = useMemo(() => {
    return location.pathname.startsWith("/my-page");
  }, [location.pathname]);

  /**
   * Get breadcrumbs
   */
  const breadcrumbs: BeardcrumbOption[] = useMemo(() => {
    return [
      {
        label: isFromMypage ? "マイページ" : "Eラーニング",
        route: isFromMypage
          ? ROUTES_CONSTANT.MY_PAGE
          : ROUTES_CONSTANT.ELEARNING_LIST,
      },
      {
        label: courseInfo?.title,
      },
    ];
  }, [courseInfo]);

  /**
   * Get categories
   */
  const category = useMemo(() => {
    const arr: string[] = [];
    if (courseInfo?.parent_category) {
      arr.push(courseInfo?.parent_category.name);
    }
    if (courseInfo?.sub_category) {
      arr.push(courseInfo?.sub_category.name);
    }
    return arr.join(" / ");
  }, [courseInfo]);

  const isLearnedAllCourse = useMemo(() => {
    return courseInfo?.current_user_course?.status === "done";
  }, [courseInfo]);

  /**
   * Get list content
   */
  const courseContents = useMemo(() => {
    if (!courseInfo) return [];

    return (
      courseInfo?.course_contents
        .sort((a, b) => a.position - b.position)
        .map((i) => {
          return {
            id: i.id,
            label: ELEARNING_CONTENT_TYPE_LABEL_2[
              i.kind as keyof typeof ELEARNING_CONTENT_TYPE_LABEL_2
            ] as string,
            isDisabled: true,
          };
        }) ?? []
    );
  }, [courseInfo, coursesLearned]);

  /**
   * Get current course content
   */
  const currentCourseContent = useMemo(() => {
    if (
      !courseInfo ||
      !courseInfo?.course_contents?.length ||
      !courseContents?.length ||
      !courseContents[activeIndexStep]
    )
      return null;
    const courseContentId = courseContents[activeIndexStep].id as number;
    return courseInfo?.course_contents.find((i) => i.id === courseContentId);
  }, [courseInfo, activeIndexStep, courseContents]);

  /**
   * Check if kind of current course cotent is ROLEPLAYING
   */
  const isKindRolePlaying = useMemo(() => {
    return currentCourseContent
      ? currentCourseContent.kind ===
          ELEARNING_CONTENT_KIND_ENUM.ROLEPLAYING_THEME
      : false;
  }, [currentCourseContent, activeIndexStep]);

  /**
   *  Check if kind of current course cotent is DOCUMENT
   */
  const isKindDocument = useMemo(() => {
    return currentCourseContent
      ? currentCourseContent.kind === ELEARNING_CONTENT_KIND_ENUM.DOCUMENT
      : false;
  }, [currentCourseContent, activeIndexStep]);

  /**
   *  Check if kind of current course cotent is COMPLICANCE
   */
  const isKindCompliance = useMemo(() => {
    return currentCourseContent
      ? currentCourseContent.kind === ELEARNING_CONTENT_KIND_ENUM.COMPLIANCE
      : false;
  }, [currentCourseContent, activeIndexStep]);

  /**
   * Check if kind of current course cotent is VIDEO
   */
  const isKindVideo = useMemo(() => {
    return currentCourseContent
      ? currentCourseContent.kind === ELEARNING_CONTENT_KIND_ENUM.VIDEO
      : false;
  }, [currentCourseContent, activeIndexStep]);

  /**
   * Check if kind of current course cotent is QUIZ
   */
  const isKindQuizz = useMemo(() => {
    return currentCourseContent
      ? currentCourseContent.kind === ELEARNING_CONTENT_KIND_ENUM.QUIZ
      : false;
  }, [currentCourseContent, activeIndexStep]);

  /**
   * Check if this is the last content
   */
  const isEndContent = useMemo(() => {
    if (!courseContents) return false;

    return activeIndexStep === courseContents.length - 1;
  }, [courseContents, activeIndexStep]);

  /**
   * Get list content learned of user
   */
  const getUserCourses = useCallback(async () => {
    if (!courseInfo || !courseInfo.current_user_course) return;
    const res = await showUserCoursesQuery(queryClient, {
      course_id: courseInfo.id,
      id: courseInfo.current_user_course.id,
    });
    setCoursesLearned(res);
  }, [courseInfo]);

  /**
   * handle when page initial
   */
  const initUserCourse = useCallback(async () => {
    if (!courseInfo) {
      return;
    }
    if (isNil(courseInfo.current_user_course)) {
      // if user not learn yet this content
      await createUserCourseMutation.mutateAsync(courseInfo.id);
      await refetchCourseInfo();
    } else {
      await getUserCourses();
    }
    setIsLoaded(true);
  }, [courseInfo]);

  /**
   * Get info content learned of user
   */
  const courseLearned = useMemo(() => {
    if (!coursesLearned || !currentCourseContent) return null;
    return coursesLearned!.user_course_contents.find(
      (i) => i.course_content_id === currentCourseContent?.id,
    );
  }, [coursesLearned, currentCourseContent]);

  const [currentQuizzContent, setCurrentQuizzContent] =
    useState<ShowQuizzResponseBody | null>(null);

  const getQuizzContent = useCallback(async () => {
    const res = await showQuizzQuery(
      queryClient,
      currentCourseContent!.quiz!.id,
    );
    setCurrentQuizzContent(res);
  }, [currentCourseContent?.quiz?.id]);

  useEffect(() => {
    if (isKindQuizz && currentCourseContent?.quiz?.id) {
      getQuizzContent();
    }
  }, [isKindQuizz, currentCourseContent]);

  const sampleVideo = useMemo(() => {
    if (currentCourseContent?.youtube_id?.length) {
      return {
        youtube_id: currentCourseContent.youtube_id as string,
        youtube_file_name: currentCourseContent.youtube_file_name as string,
      };
    }
    if (currentCourseContent?.video) {
      return {
        youtube_id: currentCourseContent.video.youtube_id as string,
        youtube_file_name: currentCourseContent.video
          .youtube_file_name as string,
        video_title: currentCourseContent.video.title as string,
      };
    }

    return null;
  }, [currentCourseContent]);

  const [errorUpload, setErrUpload] = useState("");

  const uploadYtb = async () => {
    if (!filesSelected.length) return null;
    const fileStore = filesSelected[0];

    const newFile = await createFileFromBlobURL(
      fileStore.objectURL,
      fileStore.name,
      fileStore.type,
    );

    const response = await uploadYoutubeFn(newFile, courseInfo?.folder?.id);
    if (response?.error) {
      setErrUpload(response?.error);
      return null;
    }

    return [
      {
        youtube_id: response?.youtube_id as string,
        youtube_file_name: response?.youtube_file_name as string,
      },
    ];
  };

  /**
   * handle when click next step
   */
  const onNextStep = useCallback(async () => {
    if (isDisabledBtnNext) return;

    // In case of roleplaying, upload / recording must be allowed
    if (courseLearned || (!isKindRolePlaying && isLearnedAllCourse)) {
      if (
        currentCourseContent!.kind ===
          ELEARNING_CONTENT_KIND_ENUM.ROLEPLAYING_THEME &&
        ytbIdsDeleted.length
      ) {
        const payload: UpdateUserCourseContentRequestBody = {
          user_course_id: courseInfo!.current_user_course!.id as number,
          user_course_content_id: courseLearned!.id as number,
          user_course_contents: {
            youtubes_attributes: [],
          },
        };
        setIsLoading(true);
        const videoUpload = await uploadYtb();
        if (!videoUpload) {
          setIsLoading(false);
          return;
        }

        payload.user_course_contents.youtubes_attributes = [
          {
            id: courseLearned?.youtubes?.[0].id as number,
            youtube_file_name: videoUpload?.[0].youtube_file_name,
            youtube_id: videoUpload?.[0].youtube_id,
          },
        ];

        await updateUserCourseContentMutation.mutateAsync(payload);
        setIsLoading(false);
      }

      setActiveIndexStep(activeIndexStep + 1);
      if (isEndContent) {
        navigate(ROUTES_CONSTANT.ELEARNING_LIST);
      }
      return;
    }

    if (isKindQuizz && quizzRef.current && !isQuizPassed) {
      const isPassed = quizzRef.current.onSubmit();
      setIsQuizPassed(isPassed);
      if (!isPassed) {
        setIsDisabledBtnNext(true);
      }
      return;
    }

    try {
      setIsLoading(true);
      let videoUpload: any[] | null = [];

      if (isKindRolePlaying) {
        videoUpload = await uploadYtb();
        if (!videoUpload) {
          setIsLoading(false);
          return;
        }
      }

      const payload: CreateUserCourseContentRequestBody = {
        user_course_id: courseInfo!.current_user_course!.id as number,
        user_course_contents: {
          course_content_id: currentCourseContent!.id as number,
          youtubes_attributes: videoUpload,
        },
      };

      if (isQuizPassed && isKindQuizz && quizzRef.current) {
        payload.user_course_contents = {
          ...payload.user_course_contents,
          pass_point: currentCourseContent?.quiz?.pass_point,
          title: currentCourseContent?.quiz?.title,
          user_quiz_questions_attributes:
            quizzRef.current.getUserQuizQuestionAttribute(),
        };
      }

      await createUserCourseContentMutation.mutateAsync(payload);
      setActiveIndexStep(activeIndexStep + 1);

      if (isEndContent) {
        await updateUserCourse.mutateAsync({
          course_id: courseInfo!.id as number,
          user_course_id: courseInfo!.current_user_course!.id as number,
          user_courses: {
            status: "done",
          },
        });
      }
      setIsLoading(false);

      resetFileStore();
      await getUserCourses();
      if (isEndContent) {
        navigate(ROUTES_CONSTANT.ELEARNING_LIST);
      }
    } catch (errRes) {
      const err = handleApiError(errRes);
      if ((err?.path ?? err?.[0]?.path) === "popup") {
        onOpenModalError();
        setErrorPopup(err?.message ?? err?.[0]?.message);
      }

      await getUserCourses();
      if (isEndContent) {
        navigate(ROUTES_CONSTANT.ELEARNING_LIST);
      }
    }
  }, [
    isDisabledBtnNext,
    quizzRef,
    currentCourseContent,
    courseInfo,
    getUserCourses,
    courseLearned,
    isEndContent,
    isKindQuizz,
    isQuizPassed,
    ytbIdsDeleted,
    isKindRolePlaying,
    isLearnedAllCourse,
  ]);

  // /**
  //  * Check if target content is learned
  //  */
  // const checkContentIsLearned = useCallback(
  //   (index: number) => {
  //     const course = courseInfo?.course_contents[index];
  //     return coursesLearned!.user_course_contents.find(
  //       (i) => i.course_content_id === course?.id,
  //     );
  //   },
  //   [courseInfo, coursesLearned],
  // );

  useEffect(() => {
    return () => {
      resetFileStore();
    };
  }, []);

  useEffect(() => {
    initUserCourse();
  }, [courseInfo]);

  useEffect(() => {
    setIsQuizPassed(false);

    // In case of roleplaying, upload / recording must be allowed
    if (courseLearned || (!isKindRolePlaying && isLearnedAllCourse)) {
      setIsDisabledBtnNext(false);
    } else if (currentCourseContent) {
      if (
        isKindDocument &&
        !currentCourseContent.attachment &&
        !currentCourseContent.document?.attachment
      ) {
        setIsDisabledBtnNext(false);
      } else if (
        isKindCompliance &&
        !currentCourseContent.attachment &&
        !currentCourseContent.compliance?.attachment
      ) {
        setIsDisabledBtnNext(false);
      } else {
        setIsDisabledBtnNext(true);
      }
    }

    delay(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 300);
  }, [activeIndexStep, courseLearned, isLearnedAllCourse, isKindRolePlaying]);

  return (
    <div className="m-auto max-w-[960px] w-full mt-6 px-4">
      <Breadcrumb options={breadcrumbs} />
      <div className="mt-5">
        <h4 className="font-black">{courseInfo?.title}</h4>
        <span className="caption1 text-[#73BE1E] mt-1">{category}</span>
      </div>
      <div className="mt-8">
        {isLoaded ? (
          <Stepper
            listStep={courseContents}
            activeIndex={activeIndexStep}
            onChange={() => {
              // if (checkContentIsLearned(index)) setActiveIndexStep(index);
            }}
            className="mb-6"
          />
        ) : (
          <Skeleton height={"56px"} className="mb-6" />
        )}

        {isKindRolePlaying && (
          <div className="flex flex-col bg-white py-[8px] px-5 mb-6">
            <span className="caption1 text-[#637381]">テーマ</span>
            <h5 className="font-black text-[#212B36] text-wrap">
              {currentCourseContent?.roleplaying_theme}
            </h5>
            <div className="flex flex-row ">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_74_2337)">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.99992 0.833374C10.4602 0.833374 10.8333 1.20647 10.8333 1.66671V2.50004C10.8333 2.96028 10.4602 3.33337 9.99992 3.33337C9.53968 3.33337 9.16658 2.96028 9.16658 2.50004V1.66671C9.16658 1.20647 9.53968 0.833374 9.99992 0.833374ZM3.49391 3.49404C3.81935 3.1686 4.34699 3.1686 4.67243 3.49404L5.17251 3.99412C5.49794 4.31956 5.49794 4.84719 5.17251 5.17263C4.84707 5.49807 4.31943 5.49807 3.994 5.17263L3.49391 4.67255C3.16848 4.34711 3.16848 3.81947 3.49391 3.49404ZM16.5061 3.49411C16.8315 3.81959 16.8315 4.34723 16.506 4.67262L16.0058 5.1727C15.6803 5.4981 15.1526 5.49803 14.8273 5.17255C14.5019 4.84708 14.5019 4.31944 14.8274 3.99404L15.3276 3.49396C15.6531 3.16857 16.1807 3.16863 16.5061 3.49411ZM9.99992 5.83337C7.69873 5.83337 5.83325 7.69885 5.83325 10C5.83325 12.3012 7.69873 14.1667 9.99992 14.1667C12.3011 14.1667 14.1666 12.3012 14.1666 10C14.1666 7.69885 12.3011 5.83337 9.99992 5.83337ZM4.16658 10C4.16658 6.77838 6.77826 4.16671 9.99992 4.16671C13.2216 4.16671 15.8333 6.77838 15.8333 10C15.8333 12.327 14.4707 14.3358 12.4999 15.272V16.6667C12.4999 18.0474 11.3806 19.1667 9.99992 19.1667C8.61921 19.1667 7.49992 18.0474 7.49992 16.6667V15.272C5.52913 14.3358 4.16658 12.327 4.16658 10ZM9.16658 15.7743V16.6667C9.16658 17.1269 9.53968 17.5 9.99992 17.5C10.4602 17.5 10.8333 17.1269 10.8333 16.6667V15.7743C10.5611 15.8132 10.2829 15.8334 9.99992 15.8334C9.71698 15.8334 9.43875 15.8132 9.16658 15.7743ZM0.833252 10C0.833252 9.5398 1.20635 9.16671 1.66659 9.16671H2.49992C2.96016 9.16671 3.33325 9.5398 3.33325 10C3.33325 10.4603 2.96016 10.8334 2.49992 10.8334H1.66659C1.20635 10.8334 0.833252 10.4603 0.833252 10ZM16.6666 10C16.6666 9.5398 17.0397 9.16671 17.4999 9.16671H18.3333C18.7935 9.16671 19.1666 9.5398 19.1666 10C19.1666 10.4603 18.7935 10.8334 18.3333 10.8334H17.4999C17.0397 10.8334 16.6666 10.4603 16.6666 10Z"
                    fill="#5B9817"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_74_2337">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <span className="body2 text-[#5B9817]">
                テーマに沿ってロールプレイングを実施・撮影してください
              </span>
            </div>
          </div>
        )}
        {isLoaded ? (
          <div className="w-full ">
            {isKindDocument && (
              <div
                className={clsx(
                  currentCourseContent?.document?.content && "p-5 bg-white ",
                )}
              >
                <ElearningDocumentContent
                  documentContent={currentCourseContent?.document?.content}
                  attachment={currentCourseContent?.attachment}
                  documentAttachment={
                    currentCourseContent?.document?.attachment
                  }
                  key={currentCourseContent?.id}
                  handleWatchFullPDF={() => {
                    setIsDisabledBtnNext(false);
                  }}
                />
              </div>
            )}

            {isKindCompliance && (
              <div
                className={clsx(
                  currentCourseContent?.compliance?.content && "p-5 bg-white ",
                )}
              >
                <ElearningComplianceTrainingContent
                  documentContent={currentCourseContent?.compliance?.content}
                  attachment={currentCourseContent?.attachment}
                  documentAttachment={
                    currentCourseContent?.compliance?.attachment
                  }
                  key={currentCourseContent?.id}
                  handleWatchFullPDF={() => {
                    setIsDisabledBtnNext(false);
                  }}
                />
              </div>
            )}
            {isKindVideo && (
              <ElearningVideoContent
                attachment={
                  currentCourseContent?.youtube_id
                    ? {
                        youtube_file_name:
                          currentCourseContent.youtube_file_name,
                        youtube_id: currentCourseContent.youtube_id,
                      }
                    : undefined
                }
                videoAttachment={
                  currentCourseContent?.video
                    ? {
                        youtube_file_name:
                          currentCourseContent.video.youtube_file_name,
                        youtube_id: currentCourseContent.video.youtube_id,
                      }
                    : undefined
                }
                key={currentCourseContent?.id}
                handleWatchFullVideo={() => {
                  setIsDisabledBtnNext(false);
                }}
              />
            )}
            {isKindQuizz && (
              <div className="p-5 bg-white rounded-[20px]">
                <ElearningExamContentControl
                  isLearnedAllCourse={isLearnedAllCourse}
                  ref={quizzRef}
                  questions={currentQuizzContent?.quiz_questions}
                  passPoint={currentQuizzContent?.pass_point}
                  totalPoint={currentQuizzContent?.point}
                  key={currentQuizzContent?.id}
                  oldQuestionAnswers={courseLearned?.user_quiz_question}
                  onStatus={(status) => {
                    if (isLearnedAllCourse) return;
                    setIsQuizPassed(false);
                    switch (status) {
                      case "is-completed": {
                        setIsDisabledBtnNext(false);
                        break;
                      }
                      case "is-not-completed": {
                        setIsDisabledBtnNext(true);
                        break;
                      }
                    }
                  }}
                />
              </div>
            )}
            {isKindRolePlaying && (
              <ElearningRolePlayingContent
                isAdmin={false}
                title={currentCourseContent?.roleplaying_theme}
                key={currentCourseContent?.id}
                sampleVideo={sampleVideo}
                oldYtb={
                  courseLearned?.youtubes?.length
                    ? {
                        id: courseLearned.youtubes[0].youtube_id as string,
                        name: courseLearned.youtubes[0]
                          .youtube_file_name as string,
                      }
                    : undefined
                }
                errorUpload={errorUpload}
                setErrUpload={setErrUpload}
                onChange={(status, data) => {
                  switch (status) {
                    case "is-finish": {
                      setIsDisabledBtnNext(false);
                      break;
                    }
                    case "is-deleted": {
                      setIsDisabledBtnNext(true);
                      if (data) {
                        setYtbIdsDeleted((prev) => [...prev, data.id]);
                      }
                      break;
                    }
                    default: {
                      setIsDisabledBtnNext(true);
                      break;
                    }
                  }
                }}
              />
            )}
          </div>
        ) : (
          <Skeleton height="500px" rounded={4} />
        )}
      </div>
      <div className="flex justify-center w-full my-8 ">
        <Button
          type="button"
          variant="primary"
          size="md2"
          className="w-[160px]"
          onClick={onNextStep}
          isLoading={isLoading}
          isDisabled={isDisabledBtnNext}
        >
          {isEndContent ? "完了" : "次へ"}
        </Button>
      </div>
      <ModalError
        id="modal-error"
        isOpen={isOpenModalError}
        message={
          <p className="w-full text-error--main input-error__message">
            {errorPopup}
          </p>
        }
        onClose={() => {
          setErrorPopup("");
          onCloseModalError();
        }}
      />
    </div>
  );
}
const AnimatedELearningDetailPage = AnimatedRoute(FrontELearningDetail, false);
export default function ELearningDetailPage() {
  return (
    <FrontLayout>
      <AnimatedELearningDetailPage />
    </FrontLayout>
  );
}
