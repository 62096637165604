import { QueryFunctionContext } from "@tanstack/react-query";
import { Paging } from "../type";
import { request } from "../axios";
import { getRoute } from "@/shared/get/route";
import { API_PATH_CONSTANT } from "../constant";
import { ActionHistories } from "@/configs/enum";

export type HistoriableType = "Video" | "Document" | "Course" | "Folder";
export type TypeKind = "video" | "document";
export type HistoriesItem = {
  id: number;
  creator_id: number;
  action: ActionHistories;
  content?: string | null;
  actived?: boolean;
  historiable?: {
    id: number;
    creator_id?: number;
    folder_id?: number;
    title?: string;
    name?: string;
    youtube_file_name?: string;
    youtube_id?: string;
    kind?: TypeKind;
  };
  historiable_type?: HistoriableType;
  updated_at?: string;
};

export type FilterListHistoryResponseBody = {
  histories: HistoriesItem[];
  paging: Paging;
};
export type FrontFilterListHistoryRequestBody = {
  page: number;
  per_page: number;
};
export const frontListHistoryApi = (
  context: QueryFunctionContext,
): Promise<FilterListHistoryResponseBody> => {
  return request({
    method: "GET",
    url: getRoute(API_PATH_CONSTANT.FRONT_HISTORIES),
    params: context.queryKey[1],
  });
};
