import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { ReactNode, useRef } from "react";

interface ModalProps {
  id?: string;
  isOpen: boolean;
  header?: ReactNode | string;
  body?: ReactNode | string;
  footer?: ReactNode | string;
  size?:
    | "xs"
    | "sm"
    | "md"
    | "lg"
    | "xl"
    | "2xl"
    | "3xl"
    | "4xl"
    | "5xl"
    | "6xl"
    | "full";
  blockScrollOnMount?: boolean;
  scrollBehavior?: "inside" | "outside";
  onClose: () => void;
  variant?: string;
}
export const ModalCommon = (prop: ModalProps) => {
  const refToDisableFocus = useRef(null);
  return (
    <Modal
      id={prop.id}
      isCentered
      size={prop.size}
      autoFocus={false}
      onClose={prop.onClose}
      isOpen={prop.isOpen}
      closeOnEsc={false}
      finalFocusRef={refToDisableFocus}
      closeOnOverlayClick={false}
      motionPreset="slideInBottom"
      blockScrollOnMount={prop.blockScrollOnMount}
      scrollBehavior={prop.scrollBehavior}
      variant={prop?.variant}
    >
      <ModalOverlay />
      <ModalContent className="mx-[16px] my-[0]">
        <ModalHeader>{prop.header}</ModalHeader>
        <ModalBody>{prop.body}</ModalBody>
        <ModalFooter>{prop.footer}</ModalFooter>
      </ModalContent>
    </Modal>
  );
};
