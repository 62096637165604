import { QueryFunctionContext } from "@tanstack/react-query";
import { VideoPayload, OrderDirection, Paging, TVideoList } from "../type";
import { getRoute } from "@/shared/get/route";
import { API_PATH_CONSTANT } from "../constant";
import { request } from "../axios";

export type FilterVideoRequestBody = {
  page: number;
  per_page: number;
  order_by?: string | null;
  order_direction?: OrderDirection;
  title?: string;
  folder_id?: number | null;
};

export type FilterVideoResponseBody = {
  videos: TVideoList[];
  paging: Paging;
};

export const listVideoApi = (
  context: QueryFunctionContext,
): Promise<FilterVideoResponseBody> => {
  return request({
    method: "GET",
    url: getRoute(API_PATH_CONSTANT.LIST_VIDEO),
    params: context.queryKey[1],
  });
};

export type UpsertVideoRequestBody = {
  id?: number;
  folder_id: number;
  videos: VideoPayload;
};

export type UpsertVideoResponseBody = {
  id: number;
};

export const upsertVideoApi = (
  data: UpsertVideoRequestBody,
): Promise<UpsertVideoResponseBody> => {
  return request({
    method: data.id ? "PUT" : "POST",
    url: data.id
      ? getRoute(API_PATH_CONSTANT.UPDATE_VIDEO, {
          id: data.id,
          folder_id: data.folder_id,
        })
      : getRoute(API_PATH_CONSTANT.CREATE_VIDEO, {
          folder_id: data.folder_id,
        }),
    data: {
      videos: data.videos,
    },
  });
};
export type documentVideos = {
  id?: number;
  video?: {
    id: number;
    title: string;
    folder_id: number;
  };
  document?: {
    id: number;
    title: string;
    folder_id: number;
  };
};

export type ShowVideoResponseBody = {
  id: number;
  title: string;
  youtube_id: string | null;
  youtube_file_name: string | null;
  root_folder?: {
    id?: number | null;
    course_id?: number | null;
    parent_id?: number | null;
    user_course_id?: number | null;
  };
  document_videos: documentVideos[];
  user_course_id?: number | null;
  creator_id?: number | null;
};
export const showVideoApi = (
  context: QueryFunctionContext,
): Promise<ShowVideoResponseBody> => {
  return request({
    method: "GET",
    url: getRoute(API_PATH_CONSTANT.SHOW_VIDEO, {
      folder_id: context.queryKey[1],
      id: context.queryKey[2],
    }),
  });
};

export type DeleteVideoRequestBody = {
  id: number;
  folder_id: number;
};

export type DeleteVideoResponseBody = {
  id: number;
};
export const deleteVideoApi = (
  data: DeleteVideoRequestBody,
): Promise<UpsertVideoResponseBody> => {
  return request({
    method: "DELETE",
    url: getRoute(API_PATH_CONSTANT.UPDATE_VIDEO, {
      id: data.id,
      folder_id: data.folder_id,
    }),
  });
};

export type CheckValidateVideoRequestBody = {
  id?: number;
  folder_id: number;
  videos: VideoPayload;
};

export type CheckValidateVideoResponseBody = {
  id: number;
};

export const checkValidateVideoApi = (
  data: CheckValidateVideoRequestBody,
): Promise<CheckValidateVideoResponseBody> => {
  return request({
    method: data.id ? "PUT" : "POST",
    url: data.id
      ? getRoute(API_PATH_CONSTANT.CHECK_UPDATE_VIDEO, {
          id: data.id,
          folder_id: data.folder_id,
        })
      : getRoute(API_PATH_CONSTANT.CHECK_CREATE_VIDEO, {
          folder_id: data.folder_id,
        }),
    data: {
      videos: data.videos,
    },
  });
};
