import { useDetailFolderQuery } from "@/api/folder";
import { DetailFolderResponseBody } from "@/api/folder/request";
import Breadcrumb, {
  BeardcrumbOption,
} from "@/components/atoms/Breadcrumb/breadcrumb";
import CreateVideoForm from "@/components/organisms/ContentManagement/video";
import { ADMIN_ROUTES_CONSTANT } from "@/configs/constants";
import { ROLE_ENUM } from "@/configs/enum";
import useRole from "@/hooks/role";
import AnimatedRoute from "@/router/transition.route";
import { getFoldersBreadcrumbs } from "@/shared/transform";
import { Skeleton } from "@chakra-ui/react";
import { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";

function CreateVideoPage() {
  useRole(true, [ROLE_ENUM.ADMIN_SYSTEM, ROLE_ENUM.CONTENT_MANAGER]);
  const params = useParams();
  const folder_id = params.folder_id as string;
  const {
    data: folderInfo,
    isFetching: isFetchingFolder,
    refetch,
  } = useDetailFolderQuery({
    id: Number(folder_id),
  });

  const breadcrumbs: BeardcrumbOption[] = useMemo(() => {
    const getFolders = getFoldersBreadcrumbs(folderInfo);
    const urlFolders = getFolders?.map((item: DetailFolderResponseBody) => {
      return {
        label: item?.name,
        route: `${ADMIN_ROUTES_CONSTANT.MANAGEMENT.CONTENT_VIDEO_LIST}?id=${item?.id}&init=1`,
      };
    });

    return [
      {
        label: "動画一覧",
        route: ADMIN_ROUTES_CONSTANT.MANAGEMENT.CONTENT_VIDEO_LIST,
      },
      ...urlFolders,
      {
        label: "新規作成",
      },
    ];
  }, [folderInfo]);

  useEffect(() => {
    refetch();
  }, [folder_id]);

  return (
    <div className="max-w-[100%] m-auto my-6">
      {isFetchingFolder ? (
        <Skeleton height={"16px"} rounded={12} />
      ) : (
        <Breadcrumb options={breadcrumbs} />
      )}
      <h4 className="mt-3 font-black">新規作成</h4>
      <div className="mt-8">
        <CreateVideoForm folder_id={Number(params!.folder_id)} isAdmin={true} />
      </div>
    </div>
  );
}

const AnimatedCreateVideoPage = AnimatedRoute(CreateVideoPage);
export default AnimatedCreateVideoPage;
