import Button from "@/components/atoms/Button/button";
import { ControlledInput } from "@/components/molecules/ControlledInput/controlled-input";
import {
  DocumentVideosAttributesForm,
  ICreateContentVideoForm,
  useCreateContentVideoFForm,
} from "./validation";
import { useNavigate } from "react-router-dom";
import {
  ADMIN_ROUTES_CONSTANT,
  MAX_LENGTH,
  ROUTES_CONSTANT,
} from "@/configs/constants";
import useToast from "@/hooks/toast";
import { useGlobalStore } from "@/states/global.state";
import {
  handleApiError,
  mapAPIError,
  useScrollToFirstError,
} from "@/hooks/error";
import { cutString } from "@/shared/transform";
import { useEffect, useMemo, useState } from "react";
import useUpload from "@/hooks/upload";
import { HttpStatusCode } from "axios";
import { ShowVideoResponseBody } from "@/api/video/request";
import { useCheckValidateMutation, useUpsertVideoMutation } from "@/api/video";
import UploadVideo from "@/components/atoms/UploadDocument/upload-video";
import { useDisclosure } from "@chakra-ui/react";
import { ModalError } from "@/components/molecules/ModalError/modal-error";
import { FileItem, useFileStore } from "@/states/file.state";
import { createFileFromBlobURL } from "@/shared/transform/file";
import { MESSAGES } from "@/shared/validation/message";
import { isObjectURL } from "@/shared/get/check";
import LinkDocument from "./link-document";
import { findDifferenceLink } from "./utils";

interface CreateVideoFormProps {
  videoInfo?: ShowVideoResponseBody;
  folder_id: number;
  forUser?: boolean;
  isAdmin?: boolean;
}

export default function CreateVideoForm({
  videoInfo,
  folder_id,
  forUser,
  isAdmin = true,
}: CreateVideoFormProps): JSX.Element {
  const navigate = useNavigate();

  const { showToast } = useToast();
  const { loading, setLoading, loadingFC, setLoadingFC } = useGlobalStore();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [errorApi, setErrorApi] = useState("");
  const [document_videos, setDocumentVideos] = useState<
    DocumentVideosAttributesForm[]
  >([]);
  const {
    control,
    handleSubmit,
    setError,
    formState,
    setValue,
    reset,
    getValues,
    clearErrors,
  } = useCreateContentVideoFForm();
  useScrollToFirstError(formState);
  const { errors } = formState;
  const upsertVideoMutation = useUpsertVideoMutation();
  const checkValidation = useCheckValidateMutation();
  const { uploadYoutubeFn, deleteYoutubeFn } = useUpload();

  const {
    reset: resetFileStore,
    files: filesSelected,
    removeFile: removeFileStore,
  } = useFileStore();

  useEffect(() => {
    if (loading.open !== loadingFC.open) {
      setLoadingFC(loading.open, "保存中...");
    }
  }, [loading.open, loadingFC.open]);

  const uploadYtb = async (payload: any) => {
    const ytbId = getValues("youtube_id");
    if (!ytbId) return true;

    const isLinkLocal = isObjectURL(ytbId);
    if (!isLinkLocal) return true;
    const fileStore = filesSelected.find((i) => i.objectURL === ytbId);
    if (!fileStore) return true;
    const newFile = await createFileFromBlobURL(
      fileStore.objectURL,
      fileStore.name,
      fileStore.type,
    );
    const response = await uploadYoutubeFn(newFile);
    if (response?.error) {
      setError("youtube_id", {
        message: MESSAGES.MSG_036,
      });

      return false;
    }

    removeFileStore(fileStore.objectURL);
    payload.videos.youtube_file_name = response?.youtube_file_name ?? "";
    payload.videos.youtube_id = response?.youtube_id ?? "";

    return true;
  };

  const onSubmit = async (values: ICreateContentVideoForm) => {
    try {
      const documentsValid = values?.document_videos_attributes?.filter(
        (document) => !!document?.document_id,
      );
      let documentVideosAttributes = documentsValid.map((document) => ({
        document_id: document.document_id?.value,
      }));
      if (videoInfo) {
        const findUnique = findDifferenceLink(
          values?.document_videos_attributes,
          document_videos as any,
          "document",
        )?.filter((document) => !!document?.document_id);
        documentVideosAttributes = findUnique?.map((document) => ({
          id: document?.id,
          document_id: document.document_id?.value,
          _destroy: !!document?.id,
        }));
      }

      const payload = {
        id: videoInfo?.id,
        folder_id,
        videos: {
          title: values.title,
          youtube_id: values.youtube_id,
          youtube_file_name: values.youtube_file_name,
          in_admin: isAdmin,
          document_videos_attributes: documentVideosAttributes,
        },
      };

      setLoading(true);
      await checkValidation.mutateAsync(payload);

      const isValid = await uploadYtb(payload);
      if (!isValid) return;

      await upsertVideoMutation.mutateAsync(payload);

      // delete old document
      if (
        videoInfo?.youtube_id &&
        values.youtube_id !== videoInfo?.youtube_id
      ) {
        await deleteYoutubeFn(videoInfo.youtube_id);
      }

      showToast({
        title: `${cutString(values.title)}${
          videoInfo?.id ? "を保存しました" : "を作成しました"
        }`,
        type: "success",
      });
      navigate(
        `${
          forUser
            ? ROUTES_CONSTANT.CONTENT_VIDEO_LIST
            : ADMIN_ROUTES_CONSTANT.MANAGEMENT.CONTENT_VIDEO_LIST
        }?id=${folder_id}&init=1`,
      );
    } catch (errRes) {
      const err = handleApiError(errRes);
      mapAPIError(err, setError, (e) => {
        let path = e.path;
        if (e.path === "attachment") {
          path = "attachment_attributes";
        } else if (e.path === "id") {
          path = "title";
        } else if (e.path === "popup") {
          onOpen();
          setErrorApi(e.message);
          return;
        }

        setError(path, {
          message: e.message,
        });
      });
      if (
        err?.statusCode &&
        err.statusCode != HttpStatusCode.UnprocessableEntity
      ) {
        setError("title", {
          message: err?.message,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const onRemove = () => {
    clearErrors("youtube_id");

    setValue("youtube_id", null);
    setValue("youtube_file_name", null);
  };

  const onUpload = async (content: FileItem | null) => {
    clearErrors("youtube_id");

    setValue("youtube_id", content?.objectURL ?? null);
    setValue("youtube_file_name", content?.name ?? null);
  };

  const handleCancel = async () => {
    return navigate(-1);
  };

  const youtubeInfo = useMemo(() => {
    if (!videoInfo) return null;
    return {
      id: videoInfo?.youtube_id,
      name: videoInfo?.youtube_file_name,
    };
  }, [videoInfo]);

  useEffect(() => {
    if (videoInfo?.id) {
      const documentVideosAttributes = videoInfo?.document_videos?.map(
        (item) => {
          return {
            id: item?.id,
            document_id: {
              label: item?.document?.title,
              value: item?.document?.id,
            },
          };
        },
      );
      setDocumentVideos(
        documentVideosAttributes as DocumentVideosAttributesForm[],
      );
      reset({
        title: videoInfo.title,
        youtube_id: videoInfo.youtube_id,
        youtube_file_name: videoInfo.youtube_file_name,
        document_videos_attributes:
          documentVideosAttributes.length > 0
            ? documentVideosAttributes
            : [{ document_id: null }],
      });
    } else {
      reset({
        title: "",
        youtube_id: null,
        youtube_file_name: "",
        document_videos_attributes: [
          {
            document_id: null,
          },
        ],
      });
    }
  }, [videoInfo]);

  useEffect(() => {
    return () => {
      resetFileStore();
    };
  }, []);

  return (
    <div className="flex flex-col desktop:gap-y-8 mobile:gap-y-6">
      <div className="shadow-card bg-white p-6 rounded-[20px]">
        <ControlledInput
          control={control}
          formField="title"
          isRequired={true}
          isTrim={true}
          label="タイトル"
          maxLength={MAX_LENGTH.VARCHAR}
          placeholder="タイトルを入力してください"
          errorMessage={errors?.title?.message}
        />
      </div>

      <div>
        <h6 className="font-black mb-3">コンテンツ</h6>
        <div className="shadow-card bg-white p-6 rounded-[20px]">
          <UploadVideo
            mimeSize={2}
            sizeUnit="GB"
            onChange={onUpload}
            onError={(message) =>
              setError("youtube_id", {
                message,
              })
            }
            onRemove={onRemove}
            isError={!!errors.youtube_id?.message?.length}
            youtubeInfo={youtubeInfo}
            isTakeOfPhoto
          />
          {errors.youtube_id?.message && (
            <div className="mt-1 w-full text-error--main input-error__message is-invalid">
              {errors.youtube_id?.message}
            </div>
          )}
        </div>
      </div>
      <LinkDocument
        control={control}
        errors={errors}
        getValues={getValues}
        setValue={setValue}
      />
      <div className="flex gap-[10px] desktop:justify-end mobile:justify-center">
        <Button
          size="sm"
          variant="info"
          className="min-w-[128px] w-fit"
          buttonCustom={{
            padding: "12px 12px",
          }}
          onClick={handleCancel}
        >
          キャンセル
        </Button>

        <Button
          size="sm"
          className="min-w-[128px] w-fit"
          buttonCustom={{
            padding: "12px 12px",
          }}
          isLoading={loading.open}
          onClick={handleSubmit(onSubmit)}
        >
          保存
        </Button>
      </div>
      <ModalError
        isOpen={isOpen}
        message={
          <p className="w-full text-error--main input-error__message">
            {errorApi}
          </p>
        }
        onClose={() => {
          setErrorApi("");
          onClose();
        }}
      />
    </div>
  );
}
