import IconInfoCircleError from "@/assets/icon/icon-info-circle-error.svg";
import IconInfoCircle from "@/assets/icon/icon-info-circle.svg";
import CustomTooltip from "@/components/atoms/Tooltip/tooltip";
import clsx from "clsx";
import { MouseEventHandler } from "react";

/** Represents the data structure for an e-learning item*/
interface DataELearningItem {
  id: number;
  isError?: boolean;
  className?: string;
  titleName?: string;
  timeLearning?: string | null;
  dateLearning?: string | null;
  description?: string;
  type?: string;
  typeJoin?: string;
  isJoin?: boolean;
  required?: boolean;
}

/** Properties for the ELearningItem component*/
interface ELearningItemProps {
  data: DataELearningItem;
  isDashboard?: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
}

/**
 * eLearningItem is a component that displays information about an item e-learning.
 * @param props - Properties of e-Learning Item
 * @returns A JSX element that displays information about an item e-learning.
 */
export const ELearningDashboardItem = ({
  data: {
    isError = false,
    titleName,
    timeLearning,
    dateLearning,
    description,
    type,
    typeJoin,
    isJoin,
    className,
    required,
  },
  onClick,
}: ELearningItemProps) => {
  const infoCircle = isError ? <IconInfoCircleError /> : <IconInfoCircle />;
  return (
    <div
      className={clsx(
        `${className} w-full flex gap-2 bg-white py-3 hover:bg-[#919EAB14]`,
      )}
      onClickCapture={onClick}
    >
      <div className="flex desktop:justify-between w-full mobile:flex-col-reverse desktop:flex-row gap-2">
        <div className="flex gap-2 flex-col">
          <div className="flex gap-x-2 gap-y-0 items-start flex-wrap">
            {typeJoin && (
              <div
                className={`${
                  isJoin ? "text-disabled" : "text-error--main"
                } py-1 px-2 caption1 bg-[#F5F6F8] mt-1 flex-shrink-0`}
              >
                {typeJoin}
              </div>
            )}
            <div className="subtitle1 line-clamp-2 line-break-anywhere">
              <CustomTooltip max={100} text={titleName}></CustomTooltip>
            </div>
            {required && (
              <div className="bg-[#F70905] min-w-8 py-[2px] px-1 rounded-md text-white font-normal text-xs mt-1">
                必須
              </div>
            )}

            {type && (
              <div className="rounded-xl py-1 px-2 font-normal text-xs text-primary--main bg-primary--lighter mt-[2px]">
                {type}
              </div>
            )}
          </div>
          <div className="font-normal text-xs text-secondary-custom">
            {description}
          </div>
          {dateLearning && (
            <div className="flex gap-1 items-center">
              {infoCircle}
              <div
                className={`${
                  isError ? "text-error--main" : "text-secondary-custom"
                } font-normal caption1`}
              >
                学習期日：{dateLearning}
              </div>
            </div>
          )}
        </div>
        {timeLearning && (
          <div className="subtitle2 text-secondary-custom flex-shrink-0">
            受講日：{timeLearning}
          </div>
        )}
      </div>
    </div>
  );
};
