import Button from "@/components/atoms/Button/button";
import { ControlledInput } from "@/components/molecules/ControlledInput/controlled-input";
import {
  DocumentVideosAttributesForm,
  ICreateContentForm,
  useCreateContentForm,
} from "./validation";
import UploadPdf from "@/components/atoms/UploadDocument/upload-pdf";
import { useNavigate } from "react-router-dom";
import {
  ADMIN_ROUTES_CONSTANT,
  MAX_LENGTH,
  ROUTES_CONSTANT,
} from "@/configs/constants";
import useToast from "@/hooks/toast";
import { useGlobalStore } from "@/states/global.state";
import {
  handleApiError,
  mapAPIError,
  useScrollToFirstError,
} from "@/hooks/error";
import { cutString } from "@/shared/transform";
import { useUpsertDocumentMutation } from "@/api/document";
import { useEffect, useState } from "react";
import { UploadResponse } from "@/hooks/upload";
import { ControlledRichTextEditor } from "@/components/molecules/ControlledRichTextEditor/controlled-rich-text-editor";
import { ShowDocumentResponseBody } from "@/api/document/request";
import { HttpStatusCode } from "axios";
import { ModalError } from "@/components/molecules/ModalError/modal-error";
import { useDisclosure } from "@chakra-ui/react";
import { findDifferenceLink } from "./utils";
import LinkVideo from "./link-video";

interface CreateDocumentFormProps {
  documentInfo?: ShowDocumentResponseBody;
  folder_id: number;
  forUser?: boolean;
  isAdmin?: boolean;
}
export default function CreateDocumentForm({
  documentInfo,
  folder_id,
  forUser,
  isAdmin = true,
}: CreateDocumentFormProps): JSX.Element {
  const navigate = useNavigate();
  const { showToast } = useToast();
  const { loading, setLoading } = useGlobalStore();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [errorApi, setErrorApi] = useState("");
  const [document_videos, setDocumentVideos] = useState<
    DocumentVideosAttributesForm[]
  >([]);
  const {
    control,
    handleSubmit,
    setError,
    formState,
    setValue,
    reset,
    getValues,
  } = useCreateContentForm();
  useScrollToFirstError(formState);
  const { errors } = formState;
  const upsertDocumentMutation = useUpsertDocumentMutation();

  const onSubmit = async (values: ICreateContentForm) => {
    try {
      const videosValid = values?.document_videos_attributes?.filter(
        (video) => !!video?.video_id,
      );
      let documentVideosAttributes = videosValid.map((video) => ({
        video_id: video.video_id?.value,
      }));
      if (documentInfo) {
        const findUnique = findDifferenceLink(
          values?.document_videos_attributes,
          document_videos as any,
          "video",
        )?.filter((video) => !!video?.video_id);
        documentVideosAttributes = findUnique?.map((video) => ({
          id: video?.id,
          video_id: video.video_id?.value,
          _destroy: !!video?.id,
        }));
      }
      setLoading(true);
      await upsertDocumentMutation.mutateAsync({
        id: documentInfo?.id,
        folder_id,
        documents: {
          title: values.title,
          content: values.content,
          attachment_attributes: values.attachment_attributes,
          in_admin: isAdmin,
          document_videos_attributes: documentVideosAttributes,
        },
      });
      showToast({
        title: `${cutString(values.title)}${
          documentInfo?.id ? "を保存しました" : "を作成しました"
        }`,
        type: "success",
      });
      navigate(
        `${
          forUser
            ? ROUTES_CONSTANT.CONTENT_DOCUMENT_LIST
            : ADMIN_ROUTES_CONSTANT.MANAGEMENT.CONTENT_DOCUMENT_LIST
        }?id=${folder_id}`,
      );
    } catch (errRes) {
      const err = handleApiError(errRes);
      mapAPIError(err, setError, (e) => {
        let path = e.path;
        if (e.path === "base") {
          path = "content";
        } else if (e.path === "id") {
          path = "title";
        } else if (e.path === "popup") {
          onOpen();
          setErrorApi(e.message);
          return;
        }

        setError(path, {
          message: e.message,
        });
      });
      if (
        err?.statusCode &&
        err.statusCode != HttpStatusCode.UnprocessableEntity
      ) {
        setError("title", {
          message: err?.message,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const onUpload = async (content: UploadResponse | null) => {
    if (!content) {
      setValue("attachment_attributes", null);
      return;
    }
    if (content?._destroy) {
      setValue("attachment_attributes", {
        id: content.id,
        key: content.key,
        name: content.name,
        content_type: content.content_type,
        _destroy: true,
      });
    } else {
      setValue("attachment_attributes", {
        key: content.key,
        name: content.name,
        content_type: content.content_type,
      });
    }
  };

  useEffect(() => {
    if (documentInfo?.id) {
      const documentVideosAttributes = documentInfo?.document_videos?.map(
        (item) => {
          return {
            id: item?.id,
            video_id: {
              label: item?.video?.title,
              value: item?.video?.id,
            },
          };
        },
      );
      setDocumentVideos(
        documentVideosAttributes as DocumentVideosAttributesForm[],
      );
      reset({
        title: documentInfo?.title,
        content: documentInfo?.content,
        attachment_attributes: documentInfo?.attachment,
        document_videos_attributes:
          documentVideosAttributes.length > 0
            ? documentVideosAttributes
            : [{ video_id: null }],
      });
    } else {
      reset({
        title: "",
        content: null,
        attachment_attributes: null,
        document_videos_attributes: [
          {
            video_id: null,
          },
        ],
      });
    }
  }, [documentInfo?.id]);

  return (
    <div className="flex flex-col desktop:gap-y-8 mobile:gap-y-6">
      <div className="shadow-card bg-white p-6 rounded-[20px]">
        <ControlledInput
          control={control}
          formField="title"
          isRequired={true}
          isTrim={true}
          maxLength={MAX_LENGTH.VARCHAR}
          label="タイトル"
          placeholder="タイトルを入力してください"
          errorMessage={errors?.title?.message}
        />
      </div>
      <div>
        <h6 className="font-black mb-3">コンテンツ</h6>
        <div className="shadow-card bg-white p-6 rounded-[20px] mb-3">
          <UploadPdf
            onChange={onUpload}
            isError={
              !!errors.attachment_attributes?.message ||
              !!errors?.content_not_null?.message
            }
            onError={(message) => {
              setError("attachment_attributes", {
                message,
              });
            }}
            onRemove={onUpload}
            attachment={documentInfo?.attachment}
          />
          {errors.attachment_attributes?.message && (
            <div className="mt-1 w-full text-error--main input-error__message is-invalid">
              {errors.attachment_attributes.message}
            </div>
          )}
        </div>
        <>
          <ControlledRichTextEditor
            control={control}
            formField="content"
            errorMessage={
              errors?.content?.message || errors?.content_not_null?.message
            }
            onError={(message) =>
              setError("content", {
                message,
              })
            }
          />
        </>
      </div>
      <LinkVideo
        control={control}
        errors={errors}
        getValues={getValues}
        setValue={setValue}
      />
      <div className="flex gap-[10px] desktop:justify-end mobile:justify-center">
        <Button
          size="sm"
          variant="info"
          className="min-w-[128px] w-fit"
          buttonCustom={{
            padding: "12px 12px",
          }}
          onClick={() => {
            navigate(-1);
          }}
        >
          キャンセル
        </Button>
        <Button
          size="sm"
          className="min-w-[128px] w-fit"
          buttonCustom={{
            padding: "12px 12px",
          }}
          isLoading={loading.open}
          onClick={handleSubmit(onSubmit)}
        >
          保存
        </Button>
      </div>
      <ModalError
        isOpen={isOpen}
        message={
          <p className="w-full text-error--main input-error__message">
            {errorApi}
          </p>
        }
        onClose={() => {
          setErrorApi("");
          onClose();
        }}
      />
    </div>
  );
}
