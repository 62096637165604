import MediaRecorder from "@/components/molecules/MediaRecorder/media-recorder";
import { Box, Skeleton, useDisclosure } from "@chakra-ui/react";
import {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { motion } from "framer-motion";
import { useBoolean } from "usehooks-ts";
import useUpload from "@/hooks/upload";
import clsx from "clsx";
import Button from "@/components/atoms/Button/button";
import useScreenSize from "@/hooks/screen";
import { formatTimeRecord } from "@/shared/format";
import { getErrorMessage } from "@/shared/validation/yup";
import { MESSAGES } from "@/shared/validation/message";
import {
  convertGBtoByte,
  convertToMp4,
  removeExtension,
} from "@/shared/transform/file";
import { isValidMimeTypeVideo } from "@/shared/get/check";
import { VideoViewModal } from "@/components/molecules/VideoView/video-view.modal";
import VideoView from "@/components/molecules/VideoView/video-view";
import {
  getLinkYoutube,
  getMimeTypeRecorder,
  getSourceVideoJS,
  isFLV,
  isMpeg,
} from "@/shared/get";
import { delay } from "lodash";
import VideoPlayer from "@/components/molecules/VideoView/video-view";
import MediaRecorderChromeIos from "@/components/molecules/MediaRecorder/media-recorder.chrome-ios";
import { FFmpeg } from "@ffmpeg/ffmpeg";
import { useFileStore } from "@/states/file.state";
import RecordsIcon from "@/assets/icon/records.svg";
import RecordsDisableIcon from "@/assets/icon/records-disabled.svg";
import { ModalConfirm } from "@/components/molecules/ModalConfirm/modal-confirm";
import { cutString } from "@/shared/transform";
import { ModalError } from "@/components/molecules/ModalError/modal-error";
import { useAuthStore } from "@/states/auth.state";
import dayjs from "dayjs";

interface ElearningRolePlayingContentProps {
  isAdmin?: boolean;
  title?: string;
  oldYtb?: {
    id: string | null;
    name: string | null;
  };
  sampleVideo?: {
    youtube_id: string | null;
    youtube_file_name: string | null;
    video_title?: string | null;
  } | null;
  errorUpload?: string;
  setErrUpload?: React.Dispatch<React.SetStateAction<string>>;
  onChange?: (
    status: "is-finish" | "is-deleted",
    youtube?: {
      id: string;
      name: string;
    },
  ) => void;
}

const videoJsOptions = {
  controls: true,
  bigPlayButton: false,
  loop: false,
  fluid: false,
  aspectRatio: "16:9",
  language: "ja",
  controlBar: {
    fullscreenToggle: true,
    volumePanel: false,
  },
  playsinline: true,
  plugins: {
    record: {
      image: false,
      audio: true,
      video: true,
      maxLength: 3600 * 24,
      displayMilliseconds: false,
      videoMimeType: getMimeTypeRecorder(),
      maxFileSize: 2147483648, // 2GB
      debug: false,
    },
  },
};

interface MediaDataI {
  name: string;
  type: string;
  youtubeId: string;
  objectURL: string;
}

export default function ElearningRolePlayingContent({
  isAdmin = true,
  title,
  onChange,
  oldYtb,
  sampleVideo,
  errorUpload,
  setErrUpload,
}: ElearningRolePlayingContentProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const playerRef = useRef<any>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { userInfo } = useAuthStore();

  const {
    isOpen: isOpenModalDelete,
    onOpen: openModalDelete,
    onClose: closeModalDelete,
  } = useDisclosure();

  const {
    isOpen: isOpenModalError,
    onOpen: openModalError,
    onClose: closeModalError,
  } = useDisclosure();

  const {
    isOpen: isOpenMediaRecorder,
    onOpen: onOpenMediaRecorder,
    onClose: onCloseMediaRecorder,
  } = useDisclosure();
  const { getRandFileName } = useUpload();
  const { isMobile } = useScreenSize();
  const {
    value: isReady,
    setTrue: setIsReady,
    setFalse: setIsNotReady,
  } = useBoolean(false);
  const imgUrl = new URL("@/assets/video.png", import.meta.url).href;
  const {
    value: isStart,
    setTrue: setIsStart,
    setFalse: setIsNotStart,
  } = useBoolean(false);
  const {
    value: isUploading,
    setTrue: setIsUploading,
    setFalse: setIsNotUploading,
  } = useBoolean(false);
  const { value: isDeleting, toggle: toggleStatusDelete } = useBoolean(false);
  const [timeRecord, setTimeRecord] = useState(0);
  const [process, setProcess] = useState(0);
  const [isRecording, setIsRecording] = useState(false);
  const ytbIdRef = useRef("");
  const ffmpegRef = useRef(new FFmpeg());
  const [isShowOFFCamera, setIsShowOFFCamera] = useState(false);
  const [configModalViewVideo, setConfigModalViewVideo] = useState({
    isOpen: false,
    youtubeId: "",
  });
  const [videoFromCam, setVideoFromCam] = useState(false);
  const initial = {
    name: "",
    type: "",
    youtubeId: "",
    objectURL: "",
  };
  const [currentFile, setFile] = useState<MediaDataI>(initial);

  const {
    setFile: setFileStore,
    removeFile: removeFileStore,
    files,
  } = useFileStore();

  const onPlayerReady = (player: any) => {
    playerRef.current = player;
    player.on("deviceReady", () => {
      setIsReady();
      console.log("device is ready!");
    });

    player.on("enumerateReady", () => {});

    player.on("startRecord", () => {
      setErrUpload?.("");
      console.log("started recording!");
    });

    player.on("finishRecord", async () => {
      setVideoFromCam(true);
      setTimeRecord(player.record().getDuration());
      setIsRecording(false);
      setIsUploading();
      const onlyExt = getRandFileName(true);
      const currentDate = dayjs().format("YYYYMMDDHHmmss");
      const fileName = `${userInfo?.first_name}${userInfo?.last_name}_${currentDate}.${onlyExt}`;
      const recordedData = player.recordedData;
      setIsUploading();
      const file = new File([recordedData], fileName, {
        type: recordedData.type,
      });
      const objectURL = URL.createObjectURL(file);
      setIsNotUploading();
      setFile({
        name: fileName,
        type: recordedData.type,
        youtubeId: "",
        objectURL,
      });

      setFileStore({
        name: fileName,
        objectURL,
        type: recordedData.type,
      });

      if (isMobile) {
        onOpen();
      }

      onChange?.("is-finish");
    });

    // monitor stream data during recording
    player.on("timestamp", () => {
      // timestamps
    });

    player.on("error", (element: any, error: any) => {
      setIsNotReady();
      console.warn(element, error);
    });

    player.on("deviceError", () => {
      setIsNotReady();
    });
  };

  const onStartRecord = useCallback(() => {
    if (isStart) {
      finishRecord();
    } else {
      startRecord();
    }
  }, [isStart]);

  const startRecord = useCallback(() => {
    if (!playerRef.current) return;
    setIsStart();
    setTimeRecord(0);
    setIsRecording(true);
    delay(() => playerRef.current.record().start(), 200);
  }, [playerRef.current]);

  const finishRecord = useCallback(() => {
    if (!playerRef.current) return;
    setIsNotStart();
    playerRef.current.record().stop();
  }, [playerRef.current]);

  /**
   * reset media recorder
   */
  const resetMediaRecorder = (
    autoGetDevice = false,
    autoResetTimeRecord = false,
  ) => {
    setIsNotStart();
    setIsRecording(false);
    autoResetTimeRecord && setTimeRecord(0);
    setFile(initial);
    if (playerRef.current) playerRef.current.record().reset();
    if (playerRef.current && autoGetDevice)
      playerRef.current.record().getDevice();
  };

  /**
   * handle when click delete file
   * exec delete file uploaded from pc
   */
  const handleDeleteFile = async () => {
    playerRef.current = null;
    toggleStatusDelete();
    if (inputRef.current) inputRef.current.value = "";
    if (currentFile.youtubeId) {
      const id = currentFile.youtubeId;
      setFile(initial);
      resetMediaRecorder(false, true);
      onChange?.("is-deleted", {
        id,
        name: "",
      });
    } else {
      removeFileStore(currentFile.objectURL);
      onChange?.("is-deleted");
      resetMediaRecorder(true, true);
    }
    closeModalDelete();
    toggleStatusDelete();
  };

  /**
   * handle when click upload
   * exec upload file from pc
   */
  const onUploadFile = async (e: ChangeEvent<HTMLInputElement>) => {
    setVideoFromCam(false);
    setErrUpload?.("");
    if (!e.target.files) return;
    const file = e.target.files[0];
    if (!isValidMimeTypeVideo(file)) {
      setErrUpload?.(
        getErrorMessage(MESSAGES.MSG_016, {
          type: `flv・mp4・mov・mpeg`,
        }),
      );
      return;
    }
    if (file.size > convertGBtoByte(2)) {
      setErrUpload?.(
        getErrorMessage(MESSAGES.MSG_014, {
          max: `2GB`,
        }),
      );
      return;
    }

    setIsUploading();
    playerRef.current = null;
    ffmpegRef.current.on("progress", ({ progress: _process }) => {
      setProcess(_process * 100);
    });
    let objectURL = "";
    let type = file.type;
    if (isFLV(file.type, file.name) || isMpeg(file.type)) {
      const blob = await convertToMp4(ffmpegRef.current, file);
      if (blob) {
        type = "video/mp4";
        objectURL = URL.createObjectURL(blob);
      }
    } else {
      objectURL = URL.createObjectURL(file);
    }
    const name = `${removeExtension(file.name)}.${
      type === "video/mp4" ? "mp4" : type
    }`;

    setIsNotUploading();

    setFile({
      name,
      type,
      youtubeId: "",
      objectURL,
    });

    setFileStore({
      name,
      objectURL,
      type,
    });

    if (isMobile) {
      onOpen();
    }

    onChange?.("is-finish");
  };

  const handleFinishRecordInMobile = async (
    recordedData: Blob,
    duration: number,
  ) => {
    const onlyExt = getRandFileName(true);
    const currentDate = dayjs().format("YYYYMMDDHHmmss");
    const fileName = `${userInfo?.first_name}${userInfo?.last_name}_${currentDate}.${onlyExt}`;
    if (recordedData.size > convertGBtoByte(2)) {
      setErrUpload?.(
        getErrorMessage(MESSAGES.MSG_014, {
          max: `2GB`,
        }),
      );
      return;
    }

    if (duration && duration !== Infinity) {
      setTimeRecord(duration);
    }

    setIsUploading();
    const file = new File([recordedData], fileName, {
      type: recordedData.type,
    });

    const objectURL = URL.createObjectURL(file);
    setIsNotUploading();
    setFile({
      name: file.name,
      type: file.type,
      youtubeId: "",
      objectURL,
    });

    setFileStore({
      name: file.name,
      objectURL,
      type: file.type,
    });

    onOpen();

    onChange?.("is-finish");
  };

  const onOpenModalViewVideo = useCallback((youtubeId: string) => {
    setConfigModalViewVideo({
      isOpen: true,
      youtubeId,
    });
  }, []);

  const onCloseModalViewVideo = useCallback(() => {
    setConfigModalViewVideo({
      isOpen: false,
      youtubeId: "",
    });
  }, []);

  useEffect(() => {
    ytbIdRef.current = oldYtb?.id ?? "";
  }, [oldYtb]);

  useEffect(() => {
    setFile({
      youtubeId: ytbIdRef.current,
      name: oldYtb?.name ?? "",
      objectURL: "",
      type: "",
    });
  }, [ytbIdRef.current]);

  const getSources = useCallback((mediaData: MediaDataI) => {
    if (mediaData.youtubeId) {
      return [
        {
          type: "video/youtube",
          src: getLinkYoutube(mediaData.youtubeId),
        },
      ];
    }
    if (mediaData.objectURL) {
      return [
        getSourceVideoJS(mediaData.objectURL, mediaData.type, mediaData.name),
      ];
    }

    return [];
  }, []);

  const options1 = useMemo(() => {
    let sources: any[] = [];
    if (currentFile) {
      sources = getSources(currentFile);
    }

    return {
      fullscreen: { options: { navigationUI: "show" } },
      sources,
    };
  }, [currentFile, getSources]);

  const isDisableBtnUpload = useMemo(() => {
    if (isRecording) return true;
    if (isUploading) return true;
    if (currentFile.objectURL?.length) return true;
    if (currentFile.youtubeId?.length) return true;

    return false;
  }, [currentFile, isRecording, isUploading]);

  const isDisableBtnDelete = useMemo(() => {
    if (isRecording) return true;
    if (isUploading) return true;
    if (!currentFile.objectURL?.length && !currentFile.youtubeId?.length)
      return true;

    return false;
  }, [isRecording, currentFile, isUploading]);

  const canShowPreviewInPC = useMemo(() => {
    if (currentFile.youtubeId) return true;
    if (currentFile.objectURL) return true;

    return false;
  }, [currentFile, oldYtb, isMobile]);

  const [canShowMediaRecord, setCanShowMediaRecord] = useState(false);
  useEffect(() => {
    delay(() => {
      if (!currentFile.youtubeId && !currentFile.objectURL) {
        setCanShowMediaRecord(true);
      }
    }, 500);
  }, []);

  const toggleCamera = () => {
    setIsShowOFFCamera(!isShowOFFCamera);
  };

  return (
    <>
      <div className="w-full">
        {isAdmin && (
          <div className="flex flex-col bg-white py-[8px]">
            <span className="caption1 text-[#637381]">テーマ</span>
            <h5 className="font-black text-[#212B36] text-wrap">{title}</h5>
            <div className="flex flex-row ">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_74_2337)">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.99992 0.833374C10.4602 0.833374 10.8333 1.20647 10.8333 1.66671V2.50004C10.8333 2.96028 10.4602 3.33337 9.99992 3.33337C9.53968 3.33337 9.16658 2.96028 9.16658 2.50004V1.66671C9.16658 1.20647 9.53968 0.833374 9.99992 0.833374ZM3.49391 3.49404C3.81935 3.1686 4.34699 3.1686 4.67243 3.49404L5.17251 3.99412C5.49794 4.31956 5.49794 4.84719 5.17251 5.17263C4.84707 5.49807 4.31943 5.49807 3.994 5.17263L3.49391 4.67255C3.16848 4.34711 3.16848 3.81947 3.49391 3.49404ZM16.5061 3.49411C16.8315 3.81959 16.8315 4.34723 16.506 4.67262L16.0058 5.1727C15.6803 5.4981 15.1526 5.49803 14.8273 5.17255C14.5019 4.84708 14.5019 4.31944 14.8274 3.99404L15.3276 3.49396C15.6531 3.16857 16.1807 3.16863 16.5061 3.49411ZM9.99992 5.83337C7.69873 5.83337 5.83325 7.69885 5.83325 10C5.83325 12.3012 7.69873 14.1667 9.99992 14.1667C12.3011 14.1667 14.1666 12.3012 14.1666 10C14.1666 7.69885 12.3011 5.83337 9.99992 5.83337ZM4.16658 10C4.16658 6.77838 6.77826 4.16671 9.99992 4.16671C13.2216 4.16671 15.8333 6.77838 15.8333 10C15.8333 12.327 14.4707 14.3358 12.4999 15.272V16.6667C12.4999 18.0474 11.3806 19.1667 9.99992 19.1667C8.61921 19.1667 7.49992 18.0474 7.49992 16.6667V15.272C5.52913 14.3358 4.16658 12.327 4.16658 10ZM9.16658 15.7743V16.6667C9.16658 17.1269 9.53968 17.5 9.99992 17.5C10.4602 17.5 10.8333 17.1269 10.8333 16.6667V15.7743C10.5611 15.8132 10.2829 15.8334 9.99992 15.8334C9.71698 15.8334 9.43875 15.8132 9.16658 15.7743ZM0.833252 10C0.833252 9.5398 1.20635 9.16671 1.66659 9.16671H2.49992C2.96016 9.16671 3.33325 9.5398 3.33325 10C3.33325 10.4603 2.96016 10.8334 2.49992 10.8334H1.66659C1.20635 10.8334 0.833252 10.4603 0.833252 10ZM16.6666 10C16.6666 9.5398 17.0397 9.16671 17.4999 9.16671H18.3333C18.7935 9.16671 19.1666 9.5398 19.1666 10C19.1666 10.4603 18.7935 10.8334 18.3333 10.8334H17.4999C17.0397 10.8334 16.6666 10.4603 16.6666 10Z"
                    fill="#5B9817"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_74_2337">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <span className="body2 text-[#5B9817]">
                テーマに沿ってロールプレイングを実施・撮影してください
              </span>
            </div>
          </div>
        )}

        {isAdmin || (isShowOFFCamera && isDisableBtnDelete) ? (
          <img
            src={imgUrl}
            className={clsx(
              "w-full h-[618px] object-cover",
              isAdmin ? "h-[618px]" : "h-[522px]",
            )}
          />
        ) : (
          <>
            {!isMobile && canShowPreviewInPC && (
              <VideoView
                isFlip={videoFromCam}
                options={options1}
                onReady={(player: any) => {
                  player.one("loadedmetadata", function () {
                    const duration = player.duration();
                    if (duration && duration !== Infinity) {
                      setTimeRecord(duration);
                    }
                  });
                }}
              />
            )}

            {!isMobile && !canShowPreviewInPC && (
              <div className="relative">
                {isUploading && (
                  <div className="absolute w-full h-full bg-[#f0f0f0] top-0 left-0 z-10 flex justify-center items-center">
                    <div className="flex flex-col justify-center">
                      <span className="text-[#73be1e] font-bold text-sm text-center">
                        ファイルをMP4へ変換しています。しばらくお待ちください。
                        <br />
                        {process.toFixed(2)} %
                      </span>
                    </div>
                  </div>
                )}

                {canShowMediaRecord ? (
                  <MediaRecorder
                    options={videoJsOptions}
                    onReady={onPlayerReady}
                    autoGetDevice={canShowMediaRecord}
                  />
                ) : (
                  <Skeleton height="522px" />
                )}
              </div>
            )}
          </>
        )}

        {!isMobile && !canShowPreviewInPC && (
          <div
            className={clsx(
              "bg-white flex justify-center p-4 relative",
              isAdmin ? "" : "rounded-bl-[20px] rounded-br-[20px]",
            )}
          >
            <Box
              as={"button"}
              borderRadius={"32px"}
              border={"1px solid #E6E8EA"}
              className={clsx(
                "bg-white flex items-center justify-center rounded-[32px] [box-shadow:0px_0px_16px_0px_#22390914]",
                !isReady || isUploading ? "cursor-default" : "cursor-pointer",
                isAdmin
                  ? "w-[56px] h-[56px]"
                  : "h-[52px] gap-[17px] py-3 px-[22px]",
              )}
              onClickCapture={() => {
                if (!isUploading && isReady && !isShowOFFCamera) {
                  onStartRecord();
                }
                if (!isUploading && isReady && isShowOFFCamera) {
                  openModalError();
                }
              }}
            >
              {isAdmin ? (
                <motion.div
                  style={{
                    width: 32,
                    height: 32,
                    borderRadius: isAdmin ? "50%" : !isStart ? "50%" : "0%",
                    backgroundColor:
                      !isReady || isUploading ? "#919eab" : "#F70905",

                    margin: "auto",
                  }}
                  animate={
                    isAdmin
                      ? {}
                      : {
                          width: !isStart ? 32 : 24,
                          height: !isStart ? 32 : 24,
                        }
                  }
                  whileHover={
                    isAdmin || isUploading || !isReady ? {} : { scale: 1.1 }
                  }
                  whileTap={
                    isAdmin || isUploading || !isReady ? {} : { scale: 0.9 }
                  }
                />
              ) : (
                <>
                  <span className="h6 !font-black text-[#212B36]">
                    {isStart ? "撮影終了" : "撮影開始"}
                  </span>
                  {!isStart ? (
                    <svg
                      className="transform hover:scale-110"
                      width="15"
                      height="17"
                      viewBox="0 0 15 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.8906 6.7689C15.224 7.5387 15.224 9.4632 13.8906 10.233L3.10974 16.4573C1.77641 17.2271 0.10974 16.2649 0.109741 14.7253L0.109741 2.2766C0.109741 0.737 1.77641 -0.22525 3.10974 0.54455L13.8906 6.7689Z"
                        fill={!isReady || isUploading ? "#919eab" : "#73BE1E"}
                      />
                    </svg>
                  ) : (
                    <motion.div
                      style={{
                        width: 18,
                        height: 18,
                        flexDirection: "row",
                        display: "flex",
                        gap: 4,
                      }}
                      whileHover={isUploading || !isReady ? {} : { scale: 1.1 }}
                      whileTap={isUploading || !isReady ? {} : { scale: 0.9 }}
                    >
                      <div
                        className={clsx(
                          "h-[18px] w-[7px]",
                          !isReady || isUploading
                            ? "bg-[#919eab]"
                            : "bg-[#F70905]",
                        )}
                      ></div>
                      <div
                        className={clsx(
                          "h-[18px] w-[7px]",
                          !isReady || isUploading
                            ? "bg-[#919eab]"
                            : "bg-[#F70905]",
                        )}
                      ></div>
                    </motion.div>
                  )}
                </>
              )}
            </Box>
            {!isAdmin && (
              <Box
                as={"button"}
                borderRadius={"999px"}
                className={clsx(
                  "!bg-[#F1F9E8] flex items-center justify-center rounded-[999px] h-[40px] py-2 px-4 gap-2 absolute right-[34px] top-[calc(50%-20px)]",
                  !isReady || isUploading || isStart
                    ? "cursor-not-allowed"
                    : "cursor-pointer",
                )}
                onClickCapture={() => {
                  if (!isUploading && isReady && !isStart) {
                    toggleCamera();
                  }
                }}
              >
                <span className="subtitle2 font-black text-[#212B36]">
                  {isShowOFFCamera || !isReady ? "ON" : "OFF"}
                </span>
                {isShowOFFCamera || !isReady ? (
                  <RecordsIcon />
                ) : (
                  <RecordsDisableIcon />
                )}
              </Box>
            )}
          </div>
        )}
      </div>
      {isMobile && (
        <div className="flex justify-center w-full mt-6 ">
          <Button
            type="button"
            variant="primary"
            size="sm"
            className="w-[358px]"
            isDisabled={
              !!currentFile.youtubeId || !!currentFile.objectURL || isUploading
            }
            onClick={() => {
              onOpenMediaRecorder();
            }}
          >
            撮影する
          </Button>
        </div>
      )}
      {/* sample video  */}
      {sampleVideo?.youtube_id && isAdmin && (
        <div className={clsx("flex flex-col", isAdmin ? "mt-2 mb-4" : "mt-2")}>
          <span className="subtitle1 text-[#637381] whitespace-nowrap">
            見本動画
          </span>
          <span
            className="body1 text-[var(--primary-main-color)] underline cursor-pointer"
            onClickCapture={() =>
              onOpenModalViewVideo(sampleVideo.youtube_id as string)
            }
          >
            {sampleVideo?.video_title || sampleVideo?.youtube_file_name}
          </span>
        </div>
      )}

      {oldYtb?.id && currentFile.youtubeId && (
        <div className="mt-4">
          <span
            className="body1 text-[var(--primary-main-color)] underline cursor-pointer mt-1"
            onClickCapture={onOpen}
          >
            {oldYtb.name}
          </span>
        </div>
      )}

      {/* {currentFile.youtubeId && (
        <div className="mt-4">
          <span
            className="body1 text-[var(--primary-main-color)] underline cursor-pointer mt-1"
            onClickCapture={onOpen}
          >
            {currentFile.name}
          </span>
        </div>
      )} */}

      {!isAdmin && (
        <div className={clsx(isAdmin ? "mt-6" : "mt-4")}>
          {errorUpload && (
            <p className="text-error--main input-error__message mb-1">
              {errorUpload}
            </p>
          )}
          <div className="flex gap-x-[10px] items-center flex-wrap">
            <span className="subtitle1 text-[#637381]">
              撮影時間：{formatTimeRecord(timeRecord)}
            </span>

            <Button
              buttonCustom={{
                padding: "4px 8px",
                bg: "#F1F9E8",
                color: "#73BE1E",
                fontSize: "14px",
                fontWeight: "700",
                lineHeight: "24px",
                borderRadius: "8px",
                circleStyles: isUploading ? "#73be1e" : "#fff",
              }}
              isLoading={isUploading}
              isDisabled={isDisableBtnUpload}
              onClick={() => {
                inputRef.current?.click();
              }}
            >
              {isAdmin ? "アップロード" : "既存のファイルをアップロード"}
              <input
                ref={inputRef}
                id="upload-input-video"
                type="file"
                accept="video/mp4, video/mpeg, video/x-flv, video/quicktime, .flv, .mov, .mpeg"
                className="hidden"
                onChange={onUploadFile}
              />
            </Button>

            <Button
              variant="danger-outline"
              buttonCustom={{
                padding: "4px 8px",
                fontSize: "14px",
                fontWeight: "700",
                lineHeight: "24px",
                borderRadius: "8px",
              }}
              isDisabled={isDisableBtnDelete}
              onClick={openModalDelete}
              isLoading={isDeleting}
            >
              削除
            </Button>
          </div>
        </div>
      )}
      {/* sample video  */}
      {sampleVideo?.youtube_id && !isAdmin && (
        <div
          className={clsx(
            "flex flex-row gap-[10px]",
            isAdmin ? "mt-4 mb-4" : "mt-4",
          )}
        >
          <span className="subtitle1 text-[#637381] whitespace-nowrap">
            見本動画：
          </span>
          <span
            className="body1 text-[var(--primary-main-color)] underline cursor-pointer"
            onClickCapture={() =>
              onOpenModalViewVideo(sampleVideo.youtube_id as string)
            }
          >
            {sampleVideo?.video_title || sampleVideo.youtube_file_name}
          </span>
        </div>
      )}

      {!isAdmin && isMobile && (
        <MediaRecorderChromeIos
          isOpen={isOpenMediaRecorder}
          onClose={onCloseMediaRecorder}
          onFinish={handleFinishRecordInMobile}
        />
      )}

      {!isAdmin && (
        <VideoViewModal
          sources={getSources(currentFile)}
          isOpen={isOpen}
          onClose={() => onClose()}
          setDuration={(duration) => {
            if (isMobile && duration && duration !== Infinity)
              setTimeRecord(duration);
          }}
        />
      )}

      <VideoViewModal
        sources={[
          {
            type: "video/youtube",
            src: getLinkYoutube(configModalViewVideo.youtubeId),
          },
        ]}
        isOpen={configModalViewVideo.isOpen}
        onClose={onCloseModalViewVideo}
      />

      {!isAdmin && isMobile && oldYtb?.id && (
        <div className="hidden">
          <VideoPlayer
            options={{
              sources: [
                {
                  type: "video/youtube",
                  src: getLinkYoutube(oldYtb?.id),
                },
              ],
              muted: true,
              autoplay: false,
            }}
            onReady={(player) => {
              player.one("loadedmetadata", function () {
                const duration = player.duration();
                if (duration && duration !== Infinity) {
                  setTimeRecord(duration);
                }
              });
            }}
          />
        </div>
      )}
      <ModalConfirm
        isOpen={isOpenModalDelete}
        onClose={closeModalDelete}
        header={`${cutString(oldYtb?.name || files?.[0]?.name)}の削除`}
        message={
          <>
            <p className="body1 text-[#212B36]">
              {cutString(oldYtb?.name || files?.[0]?.name)}を削除します。
            </p>
            <p className="body1 text-[#212B36]">よろしいですか？</p>
          </>
        }
        onSubmit={handleDeleteFile}
      />
      <ModalError
        message={
          <p className="text-error--main input-error__message mb-1">
            カメラをONにしてください。
          </p>
        }
        isOpen={isOpenModalError}
        btnCloseText="OK"
        onClose={closeModalError}
      />
    </>
  );
}
