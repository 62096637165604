import { STORAGE_KEYS } from "@/api/axios";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useAuthStore } from "@/states/auth.state";
import { useCallback, useEffect, useState } from "react";
import useToast from "@/hooks/toast";
import { useQueryClient } from "@tanstack/react-query";
import { isLoginPage, isPageWithoutAuth } from "@/shared/get/check";
import { ROUTES_CONSTANT } from "@/configs/constants";
import { fetchMyProfile } from "@/api/auth";
import ToastForFront from "@/components/molecules/Toast/toast.front";
import ToastForAdmin from "@/components/molecules/Toast/toast.admin";

export default function FrontPage() {
  const [isLoading, setLoading] = useState(true);
  const { setUserInfo } = useAuthStore();
  const { clearToast } = useToast();
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const pathnames = ["/"];
  const isBasePath = pathnames.includes(location.pathname);
  const token = Cookies.get(STORAGE_KEYS.ACCESS_TOKEN);

  const checkIsLogined = useCallback(async () => {
    try {
      const info = await fetchMyProfile(queryClient);
      setUserInfo(info);
      setLoading(false);

      if (isBasePath || isLoginPage()) {
        navigate(ROUTES_CONSTANT.DASHBOARD, {
          replace: true,
        });
      }
    } catch {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("click", () => {
      clearToast();
    });

    setLoading(true);
    if (token && !isPageWithoutAuth(false)) {
      checkIsLogined();
    } else {
      setLoading(false);
      if (isBasePath) {
        return navigate(ROUTES_CONSTANT.LOGIN, {
          replace: true,
        });
      }
    }

    return () => {
      document.removeEventListener("click", () => {});
    };
  }, []);
  if (isLoading)
    return (
      <div className="w-[100vw] h-[100vh] ">
        <div
          className="top-[50%] left-[50%] absolute w-[48px] h-[48px] z-99999  transform -translate-x-1/2 -translate-y-1/2"
          id="loading__spinner"
        >
          <div className="loader z-99999"></div>
          <p className="text-center body1 w-[100px] -ml-[25px]">
            読み込み中...
          </p>
        </div>
      </div>
    );

  return (
    <main>
      <ToastForFront />
      <ToastForAdmin />
      <Outlet />
    </main>
  );
}
