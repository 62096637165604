import { useDetailFolderQuery } from "@/api/folder";
import { DetailFolderResponseBody } from "@/api/folder/request";
import { useShowVideoQuery } from "@/api/video";
import Breadcrumb, {
  BeardcrumbOption,
} from "@/components/atoms/Breadcrumb/breadcrumb";
import CreateVideoForm from "@/components/organisms/ContentManagement/video";
import { ROUTES_CONSTANT } from "@/configs/constants";
import { ROLE_ENUM } from "@/configs/enum";
import useRole from "@/hooks/role";
import AnimatedRoute from "@/router/transition.route";
import { getFoldersBreadcrumbs } from "@/shared/transform";
import { Skeleton } from "@chakra-ui/react";
import { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";

function EditVideoPage() {
  useRole(false, [
    ROLE_ENUM.MEMBER,
    ROLE_ENUM.ADMIN_SYSTEM,
    ROLE_ENUM.CONTENT_MANAGER,
  ]);
  const params = useParams();
  const id = params.id as string;
  const folder_id = params.folder_id as string;

  const { data: videoInfo, isFetching } = useShowVideoQuery(folder_id, id);

  const {
    data: folderInfo,
    isFetching: isFetchingFolder,
    refetch,
  } = useDetailFolderQuery({
    id: Number(folder_id),
  });

  const breadcrumbs: BeardcrumbOption[] = useMemo(() => {
    const getFolders = getFoldersBreadcrumbs(folderInfo);
    const urlFolders = getFolders?.map((item: DetailFolderResponseBody) => {
      return {
        label: item?.name,
        route: `${ROUTES_CONSTANT.CONTENT_VIDEO_LIST}?id=${item?.id}&init=1`,
      };
    });

    return [
      {
        label: "コンテンツ",
        route: ROUTES_CONSTANT.CONTENT_DOCUMENT_LIST,
      },
      {
        label: "動画一覧",
        route: ROUTES_CONSTANT.CONTENT_VIDEO_LIST,
      },
      ...urlFolders,
      {
        label: `${videoInfo?.title}`,
      },
    ];
  }, [folderInfo, videoInfo, folder_id]);

  useEffect(() => {
    refetch();
  }, [folder_id]);

  return (
    <div className="m-auto max-w-[960px] w-full desktop:mt-12 mobile:mt-6 px-4">
      {isFetchingFolder ? (
        <Skeleton height={"16px"} rounded={12} />
      ) : (
        <Breadcrumb options={breadcrumbs} />
      )}
      <h4 className="mt-3 font-black">編集</h4>
      <div className="desktop:my-8 mobile:my-6">
        {isFetching ? (
          <div className="flex flex-col gap-y-8">
            <div className="shadow-card bg-white p-6 rounded-[20px]">
              <Skeleton height={"52px"} rounded={12} />
            </div>
            <div>
              <h6 className="font-black mb-3">コンテンツ</h6>
              <div className="shadow-card bg-white p-6 rounded-[20px] mb-3">
                <div className="flex items-center gap-x-4">
                  <Skeleton height={"135px"} width={"240px"} rounded={12} />
                  <Skeleton height={"40px"} width={"240px"} rounded={12} />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <CreateVideoForm
            folder_id={Number(params!.folder_id)}
            videoInfo={videoInfo}
            forUser={true}
            isAdmin={false}
          />
        )}
      </div>
    </div>
  );
}

const AnimatedFrontEditVideoPage = AnimatedRoute(EditVideoPage);
export default AnimatedFrontEditVideoPage;
