import { ModalCommon } from "@/components/atoms/Modal/modal";
import {
  getLinkYoutube,
  getMimeTypeRecorder,
  getSourceVideoJS,
} from "@/shared/get";
import { Box, ModalCloseButton, Skeleton } from "@chakra-ui/react";
import { motion } from "framer-motion";
import RecordsIcon from "@/assets/icon/records.svg";
import RecordsDisableIcon from "@/assets/icon/records-disabled.svg";
import PlusIcon from "@/assets/icon/plus.svg";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import MediaRecorder from "@/components/molecules/MediaRecorder/media-recorder";
import { useBoolean } from "usehooks-ts";
import useUpload from "@/hooks/upload";
import { useFileStore } from "@/states/file.state";
import { delay } from "lodash";
import clsx from "clsx";
import VideoView from "../VideoView/video-view";
interface ModalProps {
  id?: string;
  isOpen?: boolean;
  onClose?: () => void;
  onFinish: (recordedData: File) => void;
  onMustOnCamera: () => void;
}

const videoJsOptions = {
  controls: true,
  bigPlayButton: false,
  loop: false,
  fluid: false,
  aspectRatio: "16:9",
  language: "ja",
  controlBar: {
    fullscreenToggle: true,
    volumePanel: false,
  },
  playsinline: true,
  plugins: {
    record: {
      image: false,
      audio: true,
      video: true,
      maxLength: 3600 * 24,
      displayMilliseconds: false,
      videoMimeType: getMimeTypeRecorder(),
      maxFileSize: 2147483648, // 2GB
      debug: false,
    },
  },
};

interface MediaDataI {
  name: string;
  type: string;
  youtubeId: string;
  objectURL: string;
}

export const ModalMediaRecorder = ({ onFinish, ...prop }: ModalProps) => {
  const playerRef = useRef<any>(null);
  const imgUrl = new URL("@/assets/video.png", import.meta.url).href;
  const [isShowOFFCamera, setIsShowOFFCamera] = useState(false);
  const [videoFromCam, setVideoFromCam] = useState(false);
  const { getRandFileName } = useUpload();
  const initial = {
    name: "",
    type: "",
    youtubeId: "",
    objectURL: "",
  };
  const { setFile: setFileStore } = useFileStore();
  const [currentFile, setFile] = useState<MediaDataI>(initial);
  const [canShowMediaRecord, setCanShowMediaRecord] = useState(false);

  const {
    value: isStart,
    setTrue: setIsStart,
    setFalse: setIsNotStart,
  } = useBoolean(false);
  const {
    value: isReady,
    setTrue: setIsReady,
    setFalse: setIsNotReady,
  } = useBoolean(false);
  const {
    value: isUploading,
    setTrue: setIsUploading,
    setFalse: setIsNotUploading,
  } = useBoolean(false);

  useEffect(() => {
    if (!prop?.isOpen) {
      setIsShowOFFCamera(false);
      setVideoFromCam(false);
      setFile(initial);
      setIsNotStart();
      setIsNotReady();
      setIsNotUploading();
    }
  }, [prop?.isOpen]);

  useEffect(() => {
    delay(() => {
      if (!currentFile.youtubeId && !currentFile.objectURL) {
        setCanShowMediaRecord(true);
      }
    }, 500);
  }, []);

  const canShowPreviewInPC = useMemo(() => {
    if (currentFile.youtubeId) return true;
    if (currentFile.objectURL) return true;

    return false;
  }, [currentFile, prop?.isOpen]);

  const onPlayerReady = useCallback(
    (player: any) => {
      playerRef.current = player;
      player.on("deviceReady", () => {
        setIsReady();
        console.log("device is ready!");
      });

      player.on("enumerateReady", () => {});

      player.on("startRecord", () => {
        console.log("started recording!");
      });

      player.on("finishRecord", async () => {
        setVideoFromCam(true);
        setIsUploading();
        const fileName = getRandFileName();
        const recordedData = player.recordedData;
        setIsUploading();
        const file = new File([recordedData], fileName, {
          type: recordedData.type,
        });
        const objectURL = URL.createObjectURL(file);
        setIsNotUploading();
        setFile({
          name: fileName,
          type: recordedData.type,
          youtubeId: "",
          objectURL,
        });

        setFileStore({
          name: fileName,
          objectURL,
          type: recordedData.type,
        });
        onFinish(
          new File([recordedData], fileName, { type: recordedData.type }),
        );
      });

      // monitor stream data during recording
      player.on("timestamp", () => {
        // timestamps
      });

      player.on("error", (element: any, error: any) => {
        setIsNotReady();
        console.warn(element, error);
      });

      player.on("deviceError", () => {
        setIsNotReady();
      });
    },
    [prop?.isOpen],
  );

  const onStartRecord = useCallback(() => {
    if (isStart) {
      finishRecord();
    } else {
      startRecord();
    }
  }, [isStart]);

  const startRecord = useCallback(() => {
    if (!playerRef.current) return;
    setIsStart();
    delay(() => playerRef.current.record().start(), 200);
  }, [playerRef.current]);

  const toggleCamera = () => {
    setIsShowOFFCamera(!isShowOFFCamera);
  };

  const getSources = useCallback((mediaData: MediaDataI) => {
    if (mediaData.youtubeId) {
      return [
        {
          type: "video/youtube",
          src: getLinkYoutube(mediaData.youtubeId),
        },
      ];
    }
    if (mediaData.objectURL) {
      return [
        getSourceVideoJS(mediaData.objectURL, mediaData.type, mediaData.name),
      ];
    }

    return [];
  }, []);

  const options1 = useMemo(() => {
    let sources: any[] = [];
    if (currentFile) {
      sources = getSources(currentFile);
    }

    return {
      fullscreen: { options: { navigationUI: "show" } },
      sources,
    };
  }, [currentFile, getSources]);

  const finishRecord = useCallback(() => {
    if (!playerRef.current) return;
    setIsNotStart();
    playerRef.current.record().stop();
  }, [playerRef.current]);

  return (
    <ModalCommon
      variant="records"
      id={prop.id}
      onClose={() => {
        if (prop?.onClose) prop.onClose();
      }}
      isOpen={!!prop.isOpen}
      size="6xl"
      header={
        <div className="w-full">
          {isShowOFFCamera ? (
            <img src={imgUrl} className="w-full h-[609.75px] object-cover" />
          ) : (
            <>
              {canShowPreviewInPC && (
                <VideoView isFlip={videoFromCam} options={options1} />
              )}

              {!canShowPreviewInPC && (
                <div className="relative">
                  {canShowMediaRecord ? (
                    <MediaRecorder
                      options={videoJsOptions}
                      onReady={onPlayerReady}
                      autoGetDevice={canShowMediaRecord}
                    />
                  ) : (
                    <Skeleton height="522px" />
                  )}
                </div>
              )}
            </>
          )}
        </div>
      }
      body={
        <ModalCloseButton size="lg">
          <PlusIcon className="rotate-45" />
        </ModalCloseButton>
      }
      footer={
        <div className="flex justify-center w-full items-center">
          <div
            className={clsx(
              "bg-white flex justify-center p-3 w-full items-center rounded-bl-[16px] rounded-br-[16px]",
            )}
          >
            <Box
              as={"button"}
              borderRadius={"32px"}
              border={"1px solid #E6E8EA"}
              className={clsx(
                "bg-white flex items-center justify-center rounded-[32px] h-[52px] [box-shadow:0px_0px_16px_0px_#22390914] gap-[17px] py-3 px-[22px]",
                !isReady || isUploading ? "cursor-default" : "cursor-pointer",
              )}
              onClickCapture={() => {
                if (!isUploading && isReady && !isShowOFFCamera) {
                  onStartRecord();
                }
                if (!isUploading && isReady && isShowOFFCamera) {
                  prop?.onMustOnCamera();
                }
              }}
            >
              <span className="h6 !font-black text-[#212B36]">
                {isStart ? "撮影終了" : "撮影開始"}
              </span>
              {!isStart ? (
                <svg
                  className="transform hover:scale-110"
                  width="15"
                  height="17"
                  viewBox="0 0 15 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.8906 6.7689C15.224 7.5387 15.224 9.4632 13.8906 10.233L3.10974 16.4573C1.77641 17.2271 0.10974 16.2649 0.109741 14.7253L0.109741 2.2766C0.109741 0.737 1.77641 -0.22525 3.10974 0.54455L13.8906 6.7689Z"
                    fill={!isReady || isUploading ? "#919eab" : "#73BE1E"}
                  />
                </svg>
              ) : (
                <motion.div
                  style={{
                    width: 18,
                    height: 18,
                    flexDirection: "row",
                    display: "flex",
                    gap: 4,
                  }}
                  whileHover={isUploading || !isReady ? {} : { scale: 1.1 }}
                  whileTap={isUploading || !isReady ? {} : { scale: 0.9 }}
                >
                  <div
                    className={clsx(
                      "h-[18px] w-[7px]",
                      !isReady || isUploading ? "bg-[#919eab]" : "bg-[#F70905]",
                    )}
                  ></div>
                  <div
                    className={clsx(
                      "h-[18px] w-[7px]",
                      !isReady || isUploading ? "bg-[#919eab]" : "bg-[#F70905]",
                    )}
                  ></div>
                </motion.div>
              )}
            </Box>
            <Box
              as={"button"}
              borderRadius={"999px"}
              className={clsx(
                "!bg-[#F1F9E8] flex items-center justify-center rounded-[999px] h-[40px] py-2 px-4 gap-2 absolute right-[34px]",
                !isReady || isUploading || isStart
                  ? "cursor-not-allowed"
                  : "cursor-pointer",
              )}
              onClickCapture={() => {
                if (!isUploading && isReady && !isStart) {
                  toggleCamera();
                }
              }}
            >
              <span className="subtitle2 font-black text-[#212B36]">
                {isShowOFFCamera || !isReady ? "ON" : "OFF"}
              </span>
              {isShowOFFCamera || !isReady ? (
                <RecordsIcon />
              ) : (
                <RecordsDisableIcon />
              )}
            </Box>
          </div>
        </div>
      }
    />
  );
};
