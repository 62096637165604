import { DocumentVideosAttributesForm } from "./validation";

export function findDifferenceLink(
  array1: Array<DocumentVideosAttributesForm>,
  array2: Array<DocumentVideosAttributesForm>,
  action: "document" | "video",
) {
  const findDifference = (
    arr1: DocumentVideosAttributesForm[],
    arr2: DocumentVideosAttributesForm[],
  ) => {
    return arr1.filter(
      (item1) =>
        !arr2.some((item2) =>
          action === "document"
            ? item1?.document_id?.value === item2?.document_id?.value
            : item1?.video_id?.value === item2?.video_id?.value,
        ),
    );
  };

  const uniqueFromArray1 = findDifference(array1, array2);
  const uniqueFromArray2 = findDifference(array2, array1);

  return [...uniqueFromArray1, ...uniqueFromArray2];
}
