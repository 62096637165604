import { useCallback, useEffect, useMemo, useState } from "react";
import {
  fetchListUserNotification,
  useCheckUnreadNotification,
  useReadUserNotificationMutation,
} from "@/api/notification";
import { PAGINATION_CONSTANT, ROUTES_CONSTANT } from "@/configs/constants";
import { MESSAGES } from "@/shared/validation/message";
import { formatDate } from "@/shared/format";
import clsx from "clsx";
import { getLinkMedia } from "@/shared/get";
import { useNavigate } from "react-router-dom";
import { getRoute } from "@/shared/get/route";
import DOMPurify from "dompurify";
import Spinner from "@/components/atoms/Spinner/spinner";
import { useQueryClient } from "@tanstack/react-query";
import { useGlobalStore } from "@/states/global.state";
import { delay } from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";
interface Commentable {
  creator_id?: number;
  folder_id?: number;
  id?: number;
  title?: string;
  youtube_file_name?: string;
  youtube_id?: string;
}
type CommentableType = "Video" | "Document" | "Course";
interface INotificationDashboardFrontItem {
  id: number;
  avatar_url: string | null;
  author: string;
  title: string;
  time: string;
  content: string;
  is_read: boolean;
  commentable_type?: CommentableType;
  commentable?: Commentable;
  is_new: boolean;
  is_old: boolean;
}
export default function NotificationDashboardFront() {
  const navigate = useNavigate();
  const [hasMore, setHasMore] = useState(true);
  const queryClient = useQueryClient();
  const { setLoading } = useGlobalStore();
  const [notifications, setNotifications] = useState<
    INotificationDashboardFrontItem[]
  >([]);
  const [page, setPage] = useState<number>(PAGINATION_CONSTANT.DEFAULT_PAGE);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const { refetch: reCheck } = useCheckUnreadNotification({
    for_admin: false,
  });
  const readNotifyMutation = useReadUserNotificationMutation();

  const fetchNotifications = async (page = 1) => {
    setIsFetching(true);
    const res = await fetchListUserNotification(queryClient, {
      page,
      per_page: 3,
      order_by: "is_read",
      for_admin: false,
    });
    const datas = res.user_notifications;
    const paging = res.paging;
    setHasMore(paging.total_pages > page);
    const _notifications = datas.map((data) => {
      const commentableType =
        data?.notification?.notificationable_type === "Course"
          ? data?.notification?.notificationable_type
          : data?.notification?.notificationable?.commentable_type;

      const author = data.notification.notificationable?.user;
      const courseTitle =
        commentableType === "Course"
          ? data.notification.notificationable.title
          : data.notification.notificationable.commentable?.title ?? "";
      return {
        id: data.id,
        avatar_url: getLinkMedia(author?.avatar?.key),
        author: author?.first_name + " " + author?.last_name,
        title: `から${courseTitle}にコメントがつきました`,
        time: formatDate(data.created_at, "YYYY/MM/DD"),
        content:
          commentableType === "Course"
            ? data.notification.content
            : data.notification.notificationable.content,
        is_read: data.is_read,
        commentable_type: commentableType,
        commentable:
          commentableType === "Course"
            ? { id: data?.notification?.notificationable?.id }
            : data?.notification?.notificationable?.commentable,
        is_new: false,
        is_old: false,
      };
    });

    if (paging.current_page === 1) {
      setNotifications(_notifications as INotificationDashboardFrontItem[]);
    } else {
      setNotifications((prev) => {
        return [
          ...prev,
          ...(_notifications as INotificationDashboardFrontItem[]),
        ];
      });
    }

    setIsFetching(false);
  };

  const notificationsNew = useMemo(() => {
    return notifications
      .filter((i) => i.is_read === false)
      .map((i, index) => {
        if (index === 0) return { ...i, is_new: true };
        return { ...i, is_new: false };
      });
  }, [notifications]);

  const notificationsOld = useMemo(() => {
    return notifications
      .filter((i) => i.is_read === true)
      .map((i, index) => {
        if (index === 0) return { ...i, is_old: true };
        return { ...i, is_old: false };
      });
  }, [notifications]);

  const onNextPage = useCallback(async () => {
    if (isFetching) return;
    const nextPage = page + 1;
    await fetchNotifications(nextPage);
    setPage(nextPage);
  }, [page, isFetching]);

  const readNotifications = useCallback(
    async (autoFetchNewNotifiy = true) => {
      try {
        const idsUnread = notifications
          .filter((i) => !i.is_read)
          .map((i) => i.id);
        if (idsUnread.length > 0)
          await readNotifyMutation.mutateAsync({ ids: idsUnread });

        await reCheck();
        if (autoFetchNewNotifiy) {
          setNotifications([]);
        }
      } finally {
        if (autoFetchNewNotifiy) {
          setPage(1);
          await fetchNotifications();
        }
      }
    },
    [page, notifications],
  );

  const mergedArrNotify = useMemo(() => {
    return [...notificationsNew, ...notificationsOld];
  }, [notificationsNew, notificationsOld]);

  const goToPost = async (notification: INotificationDashboardFrontItem) => {
    setLoading(true, true);
    try {
      await readNotifications();
      setNotifications((prev) =>
        prev.map((item) => {
          if (item.id === notification.id) return { ...item, is_read: true };
          return item;
        }),
      );
      if (notification?.commentable_type === "Course") {
        navigate(
          getRoute(ROUTES_CONSTANT.ELEARNING_DETAIL, {
            id: notification.commentable?.id,
          }),
        );
      } else {
        navigate(
          getRoute(
            notification?.commentable_type === "Video"
              ? ROUTES_CONSTANT.CONTENT_VIDEO_DETAIL
              : ROUTES_CONSTANT.CONTENT_DOCUMENT_DETAIL,
            {
              id: notification?.commentable?.id,
              folder_id: notification?.commentable?.folder_id,
            },
          ),
        );
      }
    } catch {
      //
    } finally {
      delay(() => setLoading(false, false), 300);
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  useEffect(() => {
    return () => {
      readNotifications(false);
    };
  }, [page, notifications]);

  return (
    <InfiniteScroll
      next={onNextPage}
      height={"194px"}
      hasMore={hasMore}
      loader={null}
      endMessage={null}
      hasChildren={true}
      dataLength={notifications?.length}
      className="flex flex-col gap-4 w-full"
    >
      {notifications?.length > 0 ? (
        <div className="flex flex-col w-full">
          <div className="overflow-y-auto w-full">
            {mergedArrNotify.length === 0 && isFetching && (
              <div className="flex justify-center w-full mt-1">
                <Spinner
                  circleStyles={{
                    borderColor: `var(--primary-main-color) transparent transparent transparent`,
                  }}
                />
              </div>
            )}
            {mergedArrNotify.map((notification, index) => {
              return (
                <div key={notification.id} className={clsx("w-full px-5")}>
                  <div
                    onClickCapture={() => goToPost(notification)}
                    className={clsx(
                      "flex flex-row  py-3 gap-x-2 hover:bg-[#919EAB14] cursor-pointer",
                      index !== 0 &&
                        "border-solid border-b-[1px] border-[#E6E8EA]",
                    )}
                  >
                    <div className="flex flex-col w-[calc(100%-40px)]">
                      <div className="flex flex-row gap-x-2 items-center w-full justify-between">
                        <p
                          className="subtitle1 font-bold"
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(
                              notification.content ?? "",
                              {
                                ALLOWED_TAGS: ["a"],
                                ALLOWED_ATTR: ["class"],
                              },
                            ),
                          }}
                        ></p>
                        {notification.is_read ? (
                          <></>
                        ) : (
                          <div className="min-w-2 min-h-2 w-2 h-2 bg-[#F70905] rounded-full"></div>
                        )}
                      </div>
                      <span className="caption1 text-[#919EAB] mt-1">
                        {notification?.time}
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          {hasMore && (
            <div className="flex justify-center py-2 underline">
              <span
                className="body1 text-[#73be1e] cursor-pointer"
                onClickCapture={onNextPage}
              >
                {isFetching && (
                  <Spinner
                    styles={{
                      width: 34,
                      height: 34,
                    }}
                    circleStyles={{
                      width: 26,
                      height: 26,
                      borderColor: `var(--primary-main-color) transparent transparent transparent`,
                    }}
                  ></Spinner>
                )}
              </span>
            </div>
          )}
        </div>
      ) : (
        <div className="select-none block [font-size:inherit] w-full  text-[#212B36] px-[16px] py-[8px] box-border rounded-[8px] body2 text-center">
          {MESSAGES.MSG_010}
        </div>
      )}
    </InfiniteScroll>
  );
}
