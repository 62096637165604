import FolderIcon from "@/assets/icon/folder.svg";
import ThreeDotActionTable from "@/components/molecules/ActionButtonTable/three-dot";
import ThreeDotIcon from "@/assets/icon/three-dot.svg";
import { useCallback, useMemo, useState } from "react";
import Button from "@/components/atoms/Button/button";
import PlusIcon from "@/assets/icon/plus-02.svg";
import clsx from "clsx";
import PencilIcon from "@/assets/icon/pencil.svg";
import CloseIcon from "@/assets/icon/close.svg";
import { cutString } from "@/shared/transform";
import { ModalAddFolder } from "./modal-add-folder";
import { useDisclosure, useMediaQuery } from "@chakra-ui/react";
import { FilterFolderResponseBody, FolderItem } from "@/api/folder/request";
import { useDeleteFolderMutation } from "@/api/folder";
import { ModalConfirm } from "@/components/molecules/ModalConfirm/modal-confirm";
import { useGlobalStore } from "@/states/global.state";
import { handleApiError } from "@/hooks/error";
import useToast from "@/hooks/toast";
import { useSearchParams } from "react-router-dom";
import qs from "qs";
import Spinner from "@/components/atoms/Spinner/spinner";
import InfiniteScroll from "react-infinite-scroll-component";
import { Folder, TDocumentList } from "@/api/type";
import CustomTooltip from "@/components/atoms/Tooltip/tooltip";
import { useAuthStore } from "@/states/auth.state";
import { MESSAGES } from "@/shared/validation/message";
import { ModalError } from "@/components/molecules/ModalError/modal-error";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";

interface FolderLeftPanelProps {
  isAdmin: boolean;
  folderItems: FilterFolderResponseBody["folders"];
  fileItems: TDocumentList[];
  refreshSearch: () => void;
  refreshFileList: () => void;
  kind: "document" | "video";
  isFetching?: boolean;
  onNextPage: (type: "folder" | "document" | "video") => void;
  hasMore: boolean;
  totalFolder: number;
}
interface FolderActive {
  id: number;
  index: number;
  childs?: FolderActive;
}

export default function FolderLeftPanel({
  isAdmin = true,
  folderItems,
  fileItems,
  refreshSearch,
  refreshFileList,
  isFetching = false,
  onNextPage,
  hasMore,
  kind,
}: FolderLeftPanelProps) {
  const {
    isOpen: isOpenModalAdd,
    onOpen: openModalAdd,
    onClose: closeModalAdd,
  } = useDisclosure();
  const {
    isOpen: isOpenModalDelete,
    onOpen: openModalDelete,
    onClose: closeModalDelete,
  } = useDisclosure();
  const [isDesktop] = useMediaQuery("(min-width: 1024px)");
  const { showToast } = useToast();
  const [err, setError] = useState("");
  const deleteFolderApi = useDeleteFolderMutation();
  const { setLoading } = useGlobalStore();
  const [searchParams, setSearchParams] = useSearchParams();
  const { userInfo } = useAuthStore();

  const id = useMemo(() => {
    return Number(searchParams.get("id"));
  }, [searchParams.get("id")]);

  const menuItems = (folder: Folder) => {
    const result = [
      {
        icon: <PencilIcon />,
        text: <span className="body1">編集する</span>,
        onClick: (data: FolderItem) => {
          setDataModal(data);
          openModalAdd();
        },
      },
    ];
    if (folder.creator_id === userInfo?.id || isAdmin) {
      result.push({
        icon: <CloseIcon />,
        text: <span className="body1 text-error--main">削除する</span>,
        onClick: (data: FolderItem) => {
          setDataModal(data);
          openModalDelete();
        },
      });
    }
    return result;
  };
  const [dataModal, setDataModal] = useState<FolderItem>({
    id: 0,
    childs: [],
    name: "",
    kind,
    course_id: null,
    parent_id: null,
  });

  const isShowThreeDotAction = (folder: FolderItem) => {
    return (
      (folder?.parent_id && !folder?.user_course_id) ||
      (!folder?.parent_id && !folder?.course_id)
    );
  };

  const onClickFolder = (folder: FolderItem) => {
    const isShowDot = isShowThreeDotAction(folder);
    setSearchParams(
      qs.stringify({
        id: folder?.id,
        isShowDot: Boolean(isShowDot),
      }),
    );
  };

  const checkExistInFolder = useCallback(
    (folder: FolderItem) => {
      if (
        fileItems.length > 0 &&
        fileItems.findIndex((i) => i.folder_id === folder.id) !== -1
      ) {
        return true;
      }
      if (fileItems.length === 0 && searchParams.get("id")) {
        return folder.id == Number(searchParams.get("id"));
      }
      return false;
    },
    [fileItems, searchParams],
  );

  const onAddChildFolder = useCallback(
    (id: number) => {
      setDataModal({
        id: 0,
        childs: [],
        name: "",
        kind,
        parent_id: id,
        course_id: null,
      });
      openModalAdd();
    },
    [kind],
  );

  const findFolderById = (folders: FolderItem[]): FolderActive | undefined => {
    for (const [index, folder] of folders.entries()) {
      if (folder.id === id) {
        return {
          index: index,
          id: folder.id,
        };
      }
      if (folder.childs && folder.childs.length > 0) {
        const result = findFolderById(folder.childs);
        if (result) {
          return {
            index: index,
            id: folder.id,
            childs: result,
          };
        }
      }
    }
    return undefined;
  };

  const folderActive = useMemo<FolderActive | undefined>(() => {
    return findFolderById(folderItems);
  }, [folderItems]);

  const onDeleteFolder = async () => {
    try {
      setLoading(true);
      await deleteFolderApi.mutateAsync({
        id: dataModal.id,
      });
      showToast({
        title: `${cutString(dataModal.name)}を削除しました`,
        type: "success",
      });
      refreshFileList();
      refreshSearch();
      closeModalDelete();
    } catch (errRes) {
      const err = handleApiError(errRes);
      if (
        err?.[0]?.path === "id" ||
        err?.[0]?.path === "base" ||
        err?.[0]?.path === "popup"
      ) {
        closeModalDelete();
        setError(err[0].message);
      }
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 100);
    }
  };

  const renderChildFolder = useCallback(
    (item: FolderItem, itemActive?: FolderActive) => {
      return (
        <Accordion
          id={`id-item-${itemActive?.id}`}
          key={`key-item-${itemActive?.id}`}
          defaultIndex={itemActive ? [itemActive?.index] : []}
          allowMultiple
        >
          <div
            className="flex flex-col gap-y-1 min-w-full w-fit"
            style={{ paddingLeft: `${item.parent_id === 1 ? 40 : 34}px` }}
          >
            {item.childs.map((child, index) => (
              <AccordionItem
                className="border-none"
                key={`child_${child.id}`}
                id={`child_${child.id}`}
              >
                <div
                  key={child.id}
                  className="flex flex-col w-full"
                  id={`folderId_${child.id}`}
                >
                  <div
                    className={clsx(
                      "flex gap-y-1 flex-row gap-x-3 items-center hover:bg-[#F1F9E8] rounded-[8px] cursor-pointer",
                      checkExistInFolder(child) && "bg-[#F1F9E8]",
                    )}
                    onClick={() => onClickFolder(child)}
                  >
                    <AccordionButton
                      className={clsx(
                        "!px-2 !py-1 hover:!bg-[#F1F9E8] !duration-0 flex gap-y-1 flex-row gap-x-3 items-center",
                        checkExistInFolder(child) && "bg-[#F1F9E8]",
                      )}
                    >
                      <FolderIcon
                        className="folder-icon--primary min-w-[20px]"
                        width={20}
                        height={20}
                      />
                      <p className="truncate mb-0 text-base font-bold flex-1 min-w-[150px] text-left">
                        <CustomTooltip text={child.name} max={100} />
                      </p>
                      <AccordionIcon />
                    </AccordionButton>
                    {isShowThreeDotAction(child) ? (
                      <div className="py-1 px-2">
                        <ThreeDotActionTable
                          menuItems={menuItems(child)}
                          data={child}
                          classes={{ button: "p-1" }}
                          iconBtn={<ThreeDotIcon width={20} height={20} />}
                        />
                      </div>
                    ) : (
                      <div className="min-w-[44px] max-w-[44px] h-[36px]"></div>
                    )}
                  </div>
                  <AccordionPanel className="!p-0">
                    <div
                      style={{
                        marginLeft: "42px",
                      }}
                    >
                      <Button
                        size="xs"
                        variant="outline"
                        buttonCustom={{
                          borderRadius: "4px",
                          padding: "4px 6px",
                        }}
                        className={clsx("min-w-[112px] w-fit my-[2px]")}
                        startSlot={<PlusIcon />}
                        onClick={() => {
                          onAddChildFolder(child.id);
                        }}
                      >
                        フォルダ追加
                      </Button>
                    </div>
                    {renderChildFolder(
                      child,
                      index === itemActive?.index
                        ? itemActive?.childs
                        : undefined,
                    )}
                  </AccordionPanel>
                </div>
              </AccordionItem>
            ))}
          </div>
        </Accordion>
      );
    },
    [menuItems, folderActive, fileItems],
  );
  const renderParentFolder = useMemo(() => {
    return (
      <Accordion
        defaultIndex={folderActive ? [folderActive?.index] : []}
        allowMultiple
        id={`Accordion_${folderActive?.id}`}
      >
        {folderItems.map((folder, index) => (
          <AccordionItem className="border-none" key={`folder_${folder?.id}`}>
            <div
              className="flex flex-col min-w-full w-fit"
              key={folder.id}
              id={`folderId_${folder.id}`}
            >
              <div className="flex flex-row  items-center">
                <div
                  className={clsx(
                    "flex flex-row items-center gap-x-3 hover:bg-[#F1F9E8] rounded-[8px] cursor-pointer mb-[2px] w-full ",
                    checkExistInFolder(folder) && "bg-[#F1F9E8]",
                  )}
                  onClick={() => onClickFolder(folder)}
                >
                  <AccordionButton
                    className={clsx(
                      "!p-2 hover:!bg-[#F1F9E8] !duration-0 flex flex-row items-center gap-x-3",
                      checkExistInFolder(folder) && "bg-[#F1F9E8]",
                    )}
                  >
                    <FolderIcon className="folder-icon--primary min-w-[20px]" />
                    <p className="mb-0 text-base font-bold flex-1 whitespace-pre-wrap break-all text-left">
                      <CustomTooltip text={folder.name} max={100} />
                    </p>
                    <AccordionIcon />
                  </AccordionButton>
                  {isShowThreeDotAction(folder) ? (
                    <div className="p-2">
                      <ThreeDotActionTable
                        data={folder}
                        menuItems={menuItems(folder)}
                        classes={{ button: "p-1" }}
                        iconBtn={<ThreeDotIcon width={20} height={20} />}
                      />
                    </div>
                  ) : (
                    <div className="min-w-[44px] max-w-[44px] h-[44px]"></div>
                  )}
                </div>
              </div>
              <AccordionPanel className="!p-0">
                {renderChildFolder(
                  folder,
                  index === folderActive?.index
                    ? folderActive?.childs
                    : undefined,
                )}
                <div
                  style={{
                    marginLeft: "48px",
                  }}
                >
                  <Button
                    size="xs"
                    variant="outline"
                    buttonCustom={{
                      borderRadius: "4px",
                      padding: "4px 6px",
                    }}
                    className={clsx("min-w-[112px] w-fit my-[2px]")}
                    startSlot={<PlusIcon />}
                    onClick={() => {
                      onAddChildFolder(folder.id);
                    }}
                  >
                    フォルダ追加
                  </Button>
                </div>
              </AccordionPanel>
            </div>
          </AccordionItem>
        ))}
      </Accordion>
    );
  }, [folderItems, folderActive, id, fileItems]);

  return (
    <>
      <div className="flex gap-y-5 flex-col w-full" id="list-folder">
        {isFetching ? (
          <div className="flex justify-center">
            <Spinner
              circleStyles={{
                borderColor: `var(--primary-main-color) transparent transparent transparent`,
              }}
            />
          </div>
        ) : (
          <InfiniteScroll
            dataLength={folderItems.length}
            height={isDesktop ? 590 : 260}
            next={() => onNextPage("folder")}
            hasMore={hasMore}
            loader={null}
            endMessage={null}
            hasChildren={true}
            scrollableTarget={"list-folder"}
          >
            <div className="block min-w-fit">
              {folderItems.length === 0 && (
                <p className="w-full gap-2 px-4 bg-white text-center subtitle2">
                  {MESSAGES.MSG_010}
                </p>
              )}
              {folderItems.length > 0 && renderParentFolder}
            </div>
          </InfiniteScroll>
        )}
      </div>
      <ModalAddFolder
        isOpen={isOpenModalAdd}
        onClose={closeModalAdd}
        data={dataModal}
        onSuccess={() => {
          closeModalAdd();
          refreshSearch();
        }}
        isAdmin={isAdmin}
      />
      <ModalConfirm
        isOpen={isOpenModalDelete}
        onClose={() => {
          setError("");
          closeModalDelete();
        }}
        header={`${cutString(dataModal?.name)}の削除`}
        message={
          <>
            <p className="body1 text-[#212B36]">
              {cutString(dataModal?.name)}を削除します。
            </p>
            <p className="body1 text-[#212B36]">
              フォルダの中に入っているファイルも削除されます。
            </p>
            <p className="body1 text-[#212B36]">よろしいですか？</p>
          </>
        }
        onSubmit={onDeleteFolder}
      />
      <ModalError
        message={
          err.length > 0 && (
            <p className="text-error--main input-error__message mb-1 body1">
              {err.replace(/<br\s*\/?>/, "\n")}
            </p>
          )
        }
        isOpen={!!err.length}
        onClose={() => {
          setError("");
        }}
      />
    </>
  );
}
