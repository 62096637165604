import { QueryFunctionContext } from "@tanstack/react-query";
import {
  Category,
  CourseContent,
  Department,
  Office,
  OrderDirection,
  Paging,
  TELearning,
  TDetailELearning,
  User,
  UserCourse,
  TUserCourse,
  TSearchCourse,
} from "../type";
import { request } from "../axios";
import { API_PATH_CONSTANT } from "../constant";
import { getRoute } from "@/shared/get/route";
import { omit } from "lodash";
import { ELEARNING_JOINED_ENUM } from "@/configs/enum";

export type FilterELearningRequestBody = {
  page: number;
  per_page: number;
  order_by?: string;
  order_direction?: OrderDirection;
  title?: string;
  parent_category_id?: number | string;
  sub_category_id?: number | string;
  tag_id?: number | string;
  is_public?: boolean | string;
  user_name?: string;
  required?: boolean;
  user_id?: number;
  status?: string;
  join_user_name?: string;
};

export type FilterELearningResponseBody = {
  courses: TELearning[];
  paging: Paging;
};
export type DeleteELearningResponseBody = {
  id: string;
};
export const listELearningApi = (
  context: QueryFunctionContext,
): Promise<FilterELearningResponseBody> => {
  return request({
    method: "GET",
    url: getRoute(API_PATH_CONSTANT.LIST_ELEARNING),
    params: context.queryKey[1],
  });
};

export const getDetailElearningApi = (
  id: number,
): Promise<TDetailELearning> => {
  return request({
    method: "GET",
    url: getRoute(API_PATH_CONSTANT.SHOW_ELEARNING, { id }),
  });
};

export const deleteELearningApi = (
  id: number,
): Promise<DeleteELearningResponseBody> => {
  return request({
    method: "DELETE",
    url: getRoute(API_PATH_CONSTANT.DELETE_ELEARNING, { id }),
  });
};

export interface ShowElearningResponseBody {
  id: number;
  title: string;
  is_public: boolean;
  deadline_date: Date;
  parent_category: Category | null;
  sub_category: Category | null;
  department: Department | null;
  office: Office | null;
  user: User;
  course_contents: CourseContent[];
}

export const showELearningApi = (
  context: QueryFunctionContext,
): Promise<ShowElearningResponseBody> => {
  return request({
    method: "GET",
    url: getRoute(API_PATH_CONSTANT.SHOW_COURSE, {
      id: context.queryKey[1],
    }),
  });
};
export type CreateElearningRequestBody = {
  id?: number;
  title: string;
  is_public: boolean;
  deadline_date: string | null;
  parent_category_id: number | null;
  sub_category_id: number | null;
  all_office: boolean;
  all_department: boolean;
  all_target_user: boolean;
  required: boolean;
  taggables_attributes: { tag_id: number; id?: number; _destroy?: boolean }[];
  course_contents_attributes: {
    kind: number;
    document_id?: number | string;
    video_id?: number | string;
    quiz_id?: number | string;
    compliance_id?: number | string;
    roleplaying_theme?: string;
    attachment_attributes?: {
      key: string;
      name: string;
      content_type: string;
    };
    youtube_id?: string | null;
    youtube_file_name?: string | null;
    course_id?: number;
    _destroy?: boolean;
    id?: number;
  }[];
  course_target_users_attributes: {
    user_id: number;
    id?: number;
    _destroy?: boolean;
  }[];
  course_offices_attributes: {
    office_id: number;
    id?: number;
    _destroy?: boolean;
  }[];
  course_departments_attributes: {
    department_id: number;
    id?: number;
    _destroy?: boolean;
  }[];
};

export type CreateElearningResponseBody = { id: number };

export const createElearningApi = (
  data: CreateElearningRequestBody,
): Promise<CreateElearningResponseBody> => {
  return request({
    method: "POST",
    url: getRoute(API_PATH_CONSTANT.CREATE_ELEARNING),
    data: {
      course: data,
    },
  });
};

export const checkCreateElearningApi = (
  data: CreateElearningRequestBody,
): Promise<CreateElearningResponseBody> => {
  return request({
    method: "POST",
    url: getRoute(API_PATH_CONSTANT.CHECK_CREATE_ELEARNING),
    data: {
      course: data,
    },
  });
};

export const editElearningApi = (
  data: CreateElearningRequestBody,
): Promise<CreateElearningResponseBody> => {
  return request({
    method: "PUT",
    url: getRoute(API_PATH_CONSTANT.UPDATE_ELEARNING, {
      id: data.id,
    }),
    data: {
      course: omit(data, ["id"]),
    },
  });
};

export const checkEditElearningApi = (
  data: CreateElearningRequestBody,
): Promise<CreateElearningResponseBody> => {
  return request({
    method: "PUT",
    url: getRoute(API_PATH_CONSTANT.CHECK_UPDATE_ELEARNING, {
      id: data.id,
    }),
    data: {
      course: omit(data, ["id"]),
    },
  });
};

export type ListUserJoinedCourseRequestBody = {
  id: number;
  page: number;
  per_page: number;
  order_by?: string;
  order_direction?: OrderDirection;
  user_id?: number;
  status?: "done" | "doing";
};

export type ListUserJoinedCourseResponseBody = {
  user_courses: UserCourse[];
  paging: Paging;
};

export const listUserJoinedELearningApi = (
  context: QueryFunctionContext,
): Promise<ListUserJoinedCourseResponseBody> => {
  const params = context.queryKey[1] as ListUserJoinedCourseRequestBody;
  return request({
    method: "GET",
    url: getRoute(API_PATH_CONSTANT.LIST_USER_JOINED_ELEARNING, {
      id: params.id,
    }),
    params: omit(params, ["id"]),
  });
};

export type ListUserNotJoinCourseRequestBody = {
  id: number;
  page: number;
  per_page: number;
  order_by?: string;
  order_direction?: OrderDirection;
  user_id?: number;
  status?: number;
};

export type ListUserNotJoinCourseResponseBody = {
  users: User[];
  paging: Paging;
};

export const listUserNotJoinELearningApi = (
  context: QueryFunctionContext,
): Promise<ListUserNotJoinCourseResponseBody> => {
  const params = context.queryKey[1] as ListUserNotJoinCourseRequestBody;
  return request({
    method: "GET",
    url: getRoute(API_PATH_CONSTANT.LIST_USER_NOT_JOIN_ELEARNING, {
      id: params.id,
    }),
    params: omit(params, ["id"]),
  });
};

export type ShowCourseResponseBody = {
  id: number;
  title: string;
  is_public: boolean;
  deadline_date: string;
  parent_category: {
    id: number;
    name: string;
    position: number;
  };
  sub_category: {
    id: number;
    name: string;
    position: number;
  };
  folder: { id: number; name: string } | null;
  course_contents: CourseContent[];
  current_user_course: {
    id: number;
    status: "doing" | "done";
    created_at: string;
  } | null;
};

export const showCourseApi = (
  context: QueryFunctionContext,
): Promise<ShowCourseResponseBody> => {
  return request({
    method: "GET",
    url: getRoute(API_PATH_CONSTANT.FRONT_SHOW_COURSE, {
      id: context.queryKey[1],
    }),
  });
};

export type FilterListCourseRequestBody = {
  page: number;
  per_page: number;
  order_by?: string;
  order_direction?: OrderDirection;
  title?: string;
  tag_id?: string;
};

export type FilterListCourseResponseBody = {
  courses: any[];
  paging: Paging;
};

export const listCourseApi = (
  context: QueryFunctionContext,
): Promise<FilterListCourseResponseBody> => {
  return request({
    method: "GET",
    url: getRoute(API_PATH_CONSTANT.FRONT_LIST_COURSE),
    params: context.queryKey[1],
  });
};

export type FrontFilterListCourseRequestBody = {
  page: number;
  per_page: number;
  order_by?: string;
  keyword?: string;
  joined?: ELEARNING_JOINED_ENUM | "";
  parent_category_id?: number;
  order_direction?: OrderDirection;
  dashboard?: boolean;
};

export type FrontFilterListCourseResponseBody = {
  courses: TUserCourse[];
  paging: Paging;
};

export const frontListCourseApi = (
  context: QueryFunctionContext,
): Promise<FilterListCourseResponseBody> => {
  return request({
    method: "GET",
    url: getRoute(API_PATH_CONSTANT.FRONT_LIST_COURSE),
    params: context.queryKey[1],
  });
};

export type FrontFilterListSuggestionCourseResponseBody = {
  course_suggestions: TSearchCourse[];
  paging: Paging;
};

export type FrontFilterListSuggestionCourseRequestBody = {
  page: number;
  per_page: number;
  text_search: string;
};

export const frontSuggestionCourseApi = (
  context: QueryFunctionContext,
): Promise<FrontFilterListSuggestionCourseResponseBody> => {
  return request({
    method: "GET",
    url: getRoute(API_PATH_CONSTANT.FRONT_SUGGESTION_COURSE),
    params: context.queryKey[1],
  });
};

export type FilterListMyCourseRequestBody = {
  user_id?: number;
  page: number;
  per_page: number;
};

export type FilterListMyCourseResponseBody = {
  courses: TUserCourse[];
  paging: Paging;
};

export const listMyCourseApi = (
  context: QueryFunctionContext,
): Promise<FilterListMyCourseResponseBody> => {
  return request({
    method: "GET",
    url: getRoute(API_PATH_CONSTANT.FRONT_LIST_MY_COURSE),
    params: context.queryKey[1],
  });
};

export type CreateUserCourseResponseBody = {
  id: number;
};
export const createUserCourseApi = (
  course_id: number,
): Promise<CreateUserCourseResponseBody> => {
  return request({
    method: "POST",
    url: getRoute(API_PATH_CONSTANT.FRONT_CREATE_USER_COURSE, {
      course_id: course_id,
    }),
  });
};

export type UpdateUserCourseResponseBody = {
  id: number;
};

export type UpdateUserCourseRequestBody = {
  course_id: number;
  user_course_id: number;
  user_courses: {
    status?: "done";
    confirmed?: boolean;
  };
};
export const updateUserCourseApi = (
  data: UpdateUserCourseRequestBody,
): Promise<UpdateUserCourseResponseBody> => {
  return request({
    method: "PUT",
    url: getRoute(API_PATH_CONSTANT.FRONT_UPDATE_USER_COURSE, {
      course_id: data.course_id,
      user_course_id: data.user_course_id,
    }),
    data: {
      user_courses: data.user_courses,
    },
  });
};

export type CreateUserCourseContentResponseBody = {
  id: number;
};

export type CreateUserCourseContentRequestBody = {
  user_course_id: number;
  user_course_contents: {
    course_content_id: number;
    title?: string;
    pass_point?: number;
    youtubes_attributes: {
      id?: number;
      youtube_id?: string;
      youtube_file_name?: string;
      _destroy?: boolean;
    }[];
    user_quiz_questions_attributes?: {
      title: string;
      point: number;
      kind: string;
      answer: string;
      user_quiz_question_answers_attributes: {
        is_correct: boolean;
        is_answer: boolean;
        point: number;
        title: string;
      }[];
    }[];
  };
};
export const createUserCourseContentCourseApi = (
  data: CreateUserCourseContentRequestBody,
): Promise<CreateUserCourseContentResponseBody> => {
  return request({
    method: "POST",
    url: getRoute(API_PATH_CONSTANT.FRONT_CREATE_USER_COURSE_CONTENT, {
      user_course_id: data.user_course_id,
    }),
    data: {
      user_course_contents: data.user_course_contents,
    },
  });
};

export type UpdateUserCourseContentResponseBody = {
  id: number;
};

export type UpdateUserCourseContentRequestBody = {
  user_course_id: number;
  user_course_content_id: number;
  user_course_contents: {
    youtubes_attributes: {
      id?: number;
      youtube_id?: string;
      youtube_file_name?: string;
      _destroy?: boolean;
    }[];
  };
};
export const updateUserCourseContentCourseApi = (
  data: UpdateUserCourseContentRequestBody,
): Promise<UpdateUserCourseContentResponseBody> => {
  return request({
    method: "PUT",
    url: getRoute(API_PATH_CONSTANT.FRONT_UPDATE_USER_COURSE_CONTENT, {
      user_course_id: data.user_course_id,
      user_course_content_id: data.user_course_content_id,
    }),
    data: {
      user_course_contents: data.user_course_contents,
    },
  });
};

export type FilterListSuggestionCourseRequestBody = {
  page: number;
  per_page: number;
};

export type FilterListSuggestionCourseResponseBody = {
  courses: any[];
  paging: Paging;
};

export const listSuggestionCourseApi = (
  context: QueryFunctionContext,
): Promise<FilterListSuggestionCourseResponseBody> => {
  return request({
    method: "GET",
    url: getRoute(API_PATH_CONSTANT.FRONT_LIST_SUGGESTION_COURSE),
    params: context.queryKey[1],
  });
};

export type ShowUserCoursesRequestBody = {
  course_id: number;
  id: number;
};

export type ShowUserCoursesResponseBody = {
  id: number;
  status: "doing" | "done";
  created_at: string;
  user_course_contents: {
    id: number;
    title: string;
    total_point: number;
    pass_point: number;
    course_content_id: number;
    youtube_id: string | null;
    youtube_file_name: string | null;
    youtubes:
      | {
          id: number;
          youtube_id: string;
          youtube_file_name?: string;
        }[]
      | null;
    user_quiz_question: {
      id: number;
      title: string;
      kind: string;
      point: number;
      answer: string;
      user_quiz_question_answers: {
        id: number;
        title: string;
        point: number;
        is_correct: boolean;
        is_answer: boolean;
      }[];
    }[];
    quiz_id: number;
    kind: string;
  }[];
};

export const showUserCoursesApi = (
  context: QueryFunctionContext,
): Promise<ShowUserCoursesResponseBody> => {
  const params = context.queryKey[1] as ShowUserCoursesRequestBody;
  return request({
    method: "GET",
    url: getRoute(API_PATH_CONSTANT.FRONT_SHOW_USER_COURSE, {
      course_id: params.course_id,
      id: params.id,
    }),
  });
};
