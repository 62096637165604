import Button from "@/components/atoms/Button/button";
import AnimatedRoute from "@/router/transition.route";
import PlusIcon from "@/assets/icon/plus.svg";
import FolderList from "@/components/organisms/FolderManagement/folder-list";
import { ModalAddFolder } from "@/components/organisms/FolderManagement/modal-add-folder";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDisclosure } from "@chakra-ui/react";
import { OrderDirection, TDocumentList } from "@/api/type";
import { PAGINATION_CONSTANT } from "@/configs/constants";
import { useListFolderQuery, usePageFormFolderQuery } from "@/api/folder";
import useRole from "@/hooks/role";
import { ROLE_ENUM } from "@/configs/enum";
import { FolderItem } from "@/api/folder/request";
import { useListDocumentQuery } from "@/api/document";
import { useSearchParams } from "react-router-dom";
import { isNumeric } from "@/shared/get/check";
import { SuggestionSearch } from "@/components/molecules/SuggestionSearch/suggestion-search";
import { isString } from "lodash";
import { findFolderById } from "@/shared/transform";
import { scrollToTop } from "@/shared";

function ListDocumentPage() {
  useRole(true, [ROLE_ENUM.ADMIN_SYSTEM, ROLE_ENUM.CONTENT_MANAGER]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [folderList, setFolderList] = useState<FolderItem[]>([]);
  const [documentList, setDocumentList] = useState<TDocumentList[]>([]);
  const [changeQuery, setChangeQuery] = useState<boolean>(false);

  const id = useMemo(() => {
    return Number(searchParams.get("id"));
  }, [searchParams.get("id")]);

  const [isFetchingFullFolder, setIsFetchingFullFolder] =
    useState<boolean>(true);

  const init = useMemo(() => {
    return Number(searchParams.get("init"));
  }, [searchParams]);

  const [searchFolderForm, setSearchFolderForm] = useState({
    page: 1,
    per_page: PAGINATION_CONSTANT.DEFAULT_PAGE_SIZE_FOLDER,
    order_by: "updated_at",
    order_direction: "desc" as OrderDirection,
  });

  const [searchDocumentForm, setSearchDocumentForm] = useState({
    page: 1,
    per_page: PAGINATION_CONSTANT.DEFAULT_PAGE_SIZE,
    order_by: "updated_at",
    order_direction: "desc" as OrderDirection,
    folder_id: null as string | null,
    title: "",
  });

  const {
    data: folderResponse,
    refetch: refreshFolderList,
    isFetching: isFetchingFolder,
  } = useListFolderQuery({
    page: searchFolderForm.page,
    per_page: PAGINATION_CONSTANT.DEFAULT_PAGE_SIZE_FOLDER,
    order_by: searchFolderForm.order_by,
    order_direction: searchFolderForm.order_direction,
    kind: "document",
  });

  const {
    data: documentResponse,
    refetch: refreshDocumentList,
    isFetching: isFetchingFile,
  } = useListDocumentQuery(
    {
      page: searchDocumentForm.page,
      per_page:
        searchDocumentForm.page === 1
          ? 20
          : PAGINATION_CONSTANT.DEFAULT_PAGE_SIZE,
      order_by: searchDocumentForm.order_by,
      order_direction: searchDocumentForm.order_direction,
      folder_id: searchDocumentForm.folder_id
        ? Number(searchDocumentForm.folder_id)
        : null,
      title: searchDocumentForm.title,
    },
    {
      enabled:
        Boolean(searchDocumentForm.title) ||
        Boolean(searchDocumentForm.folder_id),
    },
  );

  const { data: pageResponse } = usePageFormFolderQuery({
    per_page: PAGINATION_CONSTANT.DEFAULT_PAGE_SIZE_FOLDER,
    folder_id: id,
    order_by: "updated_at",
    order_direction: "desc",
    kind: "document",
    enabled: !!init,
  });

  const totalFolder = useMemo(() => {
    return folderResponse?.paging.total_records ?? 0;
  }, [folderResponse]);

  const totalFile = useMemo(() => {
    return 0;
  }, [folderResponse]);

  const {
    isOpen: isOpenModalAdd,
    onOpen: openModalAdd,
    onClose: closeModalAdd,
  } = useDisclosure();

  const refreshSearch = useCallback(() => {
    if (searchFolderForm.page != PAGINATION_CONSTANT.DEFAULT_PAGE) {
      setSearchFolderForm((prev) => ({ ...prev, page: 1 }));
    } else {
      refreshFolderList();
    }
  }, [searchFolderForm.page]);

  const handleRefreshDocumentList = useCallback(() => {
    if (searchFolderForm.page != PAGINATION_CONSTANT.DEFAULT_PAGE) {
      setSearchDocumentForm((prev) => ({ ...prev, page: 1 }));
    } else {
      refreshDocumentList();
    }
  }, [searchDocumentForm.page]);

  const [dataModalUpsert] = useState<FolderItem>({
    id: 0,
    childs: [],
    name: "",
    kind: "document",
    parent_id: null,
  });

  const onNextPage = useCallback(
    (kind: "document" | "video" | "folder") => {
      if (kind === "folder") {
        setTimeout(() => {
          setSearchFolderForm((prev) => ({
            ...prev,
            page: Number(prev.page) + 1,
          }));
        }, 500);
      }
      if (kind === "document") {
        setTimeout(() => {
          setSearchDocumentForm((prev) => ({
            ...prev,
            page: Number(prev.page) + 1,
          }));
        }, 500);
      }
    },
    [isFetchingFolder],
  );

  useEffect(() => {
    if (!documentResponse) return;
    if (documentResponse.paging.current_page === 1) {
      setDocumentList(documentResponse.documents);
    } else {
      setDocumentList((prev) => [...prev, ...documentResponse.documents]);
    }
  }, [documentResponse]);

  useEffect(() => {
    if (!folderResponse) return;
    if (!init && !changeQuery) {
      setSearchParamsHasShowDot(
        folderResponse.folders,
        folderResponse.folders?.[0]?.id,
      );
    }
    if (folderResponse.paging.current_page === 1) {
      setFolderList(folderResponse.folders);
    } else {
      setFolderList((prev) => [...prev, ...folderResponse.folders]);
    }
  }, [folderResponse]);

  const setSearchParamsHasShowDot = (folders: FolderItem[], id: number) => {
    if (folders.length === 0) return;
    const folderItem = findFolderById(folders, id);
    if (folderItem) {
      setChangeQuery(true);
      const isShowDot =
        (folderItem?.parent_id && !folderItem?.user_course_id) ||
        (!folderItem?.parent_id && !folderItem?.course_id);
      setSearchParams(`?id=${id}&isShowDot=${isShowDot}`);
    }
  };

  useEffect(() => {
    setSearchDocumentForm((prev) => ({
      ...prev,
      folder_id: !isNumeric(searchParams.get("id"))
        ? null
        : searchParams.get("id"),
      title: "",
    }));
  }, [searchParams]);

  useEffect(() => {
    const currentPage = Math.ceil(
      folderList.length / PAGINATION_CONSTANT.DEFAULT_PAGE_SIZE_FOLDER,
    );
    const isGetDonePage = currentPage === searchFolderForm.page;
    const isGetFullCurrentPage = pageResponse?.page === currentPage;
    if (init && isGetDonePage && !changeQuery) {
      if (
        pageResponse?.page &&
        pageResponse?.page > 1 &&
        pageResponse?.page > currentPage
      ) {
        setSearchFolderForm((prev) => ({
          ...prev,
          page: Number(prev.page) + 1,
        }));
      }
      if (pageResponse?.page === 0) {
        setSearchParamsHasShowDot(folderList, folderList?.[0].id);
      }

      if (isGetFullCurrentPage) {
        setSearchParamsHasShowDot(folderList, id);
        setIsFetchingFullFolder(false);
        setTimeout(() => {
          scrollToTop(`folderId_${id}`);
        }, 500);
      }
    }
    if (!init) {
      setIsFetchingFullFolder(false);
    }
  }, [folderResponse, pageResponse, init, id, folderList]);

  return (
    <div className="max-w-[100%] m-auto my-6">
      <div className="flex mb-6 justify-between items-center">
        <h4 className="font-black">ドキュメント一覧</h4>
        <SuggestionSearch
          oldValue={searchDocumentForm.title}
          model="documents"
          labelKey={"title"}
          width="320px"
          onSearch={(data) => {
            let folder_id: string | null = null;
            if (!data) {
              folder_id = folderList.length ? String(folderList[0].id) : "";
              setSearchParams(`?id=${folder_id}`);
            }

            setSearchDocumentForm((prev) => ({
              ...prev,
              folder_id: folder_id,
              title: isString(data) ? data : data?.title ?? "",
            }));
          }}
        />
      </div>
      <Button
        size="sm"
        buttonCustom={{
          borderRadius: "6px",
          padding: "8px 14px",
        }}
        className="mb-[20px]"
        startSlot={<PlusIcon />}
        onClick={openModalAdd}
      >
        フォルダ作成
      </Button>
      <FolderList
        kind="document"
        folderItems={folderList}
        fileItems={documentList}
        refreshSearch={refreshSearch}
        refreshFileList={handleRefreshDocumentList}
        isFetchingFolder={isFetchingFullFolder}
        isFetchingFile={searchDocumentForm.page === 1 && isFetchingFile}
        onNextPage={onNextPage}
        totalFolder={totalFolder}
        totalFile={totalFile}
        hasMoreFolder={folderList.length < totalFolder}
        hasMoreFile={documentList.length < totalFile}
      />

      <ModalAddFolder
        isOpen={isOpenModalAdd}
        onClose={closeModalAdd}
        data={dataModalUpsert}
        onSuccess={() => {
          refreshSearch();
          closeModalAdd();
        }}
        isAdmin={true}
      />
    </div>
  );
}

const AnimatedListDocumentPage = AnimatedRoute(ListDocumentPage);
export default AnimatedListDocumentPage;
