import Button from "@/components/atoms/Button/button";
import { ControlledSelect } from "@/components/molecules/ControlledSelect/controlled-select";
import CloseIcon from "@/assets/icon/close.svg";
import PlusIcon from "@/assets/icon/plus-02.svg";
import { ICreateContentChildFormProps } from "./validation";
import { useFieldArray } from "react-hook-form";
import { useCallback, useMemo } from "react";
import { PAGINATION_CONSTANT } from "@/configs/constants";
import { Option } from "@/components/atoms/SelectBox/select-box";
import { useListVideoQuery } from "@/api/video";
export default function LinkVideo({
  control,
  errors,
  setValue,
  getValues,
}: ICreateContentChildFormProps) {
  const { fields, append, remove } = useFieldArray({
    control: control,
    name: "document_videos_attributes",
    keyName: "video_id",
  });
  const customTagFilter = (option: { data: Option }, inputValue: string) => {
    return ((option?.data?.label as string) || "")
      ?.toLowerCase()
      .includes(inputValue?.toLowerCase())
      ? true
      : false;
  };
  const { data: videoResponse } = useListVideoQuery({
    page: PAGINATION_CONSTANT.DEFAULT_PAGE,
    per_page: PAGINATION_CONSTANT.MAX_PAGE_SIZE,
  });

  const videos = useMemo(() => {
    return (videoResponse?.videos || []).map((tag) => ({
      value: tag.id as number,
      label: tag.title,
    }));
  }, [videoResponse]);

  const videosIndex = useCallback(
    (index: number) => {
      const otherVideos = getValues("document_videos_attributes")?.filter(
        (_, i) => i !== index,
      );
      const itemChose = videos?.filter((videoItem) => {
        return !otherVideos?.some(
          (otherItem) => otherItem.video_id?.value === videoItem.value,
        );
      });
      return itemChose;
    },
    [getValues("document_videos_attributes"), videos],
  );

  const onRemove = useCallback(
    (index: number) => {
      setValue(`document_videos_attributes.${index}`, {
        video_id: undefined,
        id: undefined,
      });
      remove(index);
    },
    [remove],
  );

  return (
    <div className="">
      <div className="flex flex-row justify-between items-center gap-x-2">
        <div className="group relative cursor-move">
          <h6 className="font-black">関連動画</h6>
        </div>
      </div>

      <div className="mt-3 shadow-card bg-white p-6 rounded-[20px] flex flex-col gap-y-4">
        <p className="subtitle1 font-bold text-[#637381]">動画を選択</p>
        {fields.map((item, index) => {
          return (
            <div key={item.video_id} data-id={item.id} className="drag-handle">
              {(errors?.document_videos_attributes?.[index] as any)?.base && (
                <div className="mt-1 w-full text-error--main input-error__message is-invalid">
                  {
                    (errors?.document_videos_attributes?.[index] as any)?.base
                      ?.message
                  }
                </div>
              )}
              <div className="flex items-center gap-x-4">
                <span className="w-2 h-2 rounded-full bg-[#637381] mt-4"></span>
                <ControlledSelect
                  label={`動画タイトル${index + 1}`}
                  isSearchable={true}
                  options={videosIndex(index)}
                  isMulti={false}
                  isHideIconDrop={true}
                  isClearable={true}
                  control={control}
                  unsetMenuPortalTarget
                  filterOption={customTagFilter}
                  formField={`document_videos_attributes.${index}.video_id`}
                  className="w-full"
                  placeholder="動画タイトルを入力して選択してください"
                  errorMessage={
                    (
                      errors?.document_videos_attributes?.[index]
                        ?.video_id as any
                    )?.message
                  }
                  onChange={() => {
                    setValue(
                      `document_videos_attributes.${index}.id`,
                      undefined,
                    );
                  }}
                />
                {index !== 0 ? (
                  <Button
                    className="whitespace-nowrap mt-4"
                    variant="danger-outline"
                    buttonCustom={{
                      padding: "6px 8px",
                    }}
                    size="xs"
                    startSlot={<CloseIcon />}
                    onClick={() => onRemove(index)}
                  >
                    削除
                  </Button>
                ) : (
                  <div className="mt-4 min-w-[66px] h-[30px]"></div>
                )}
              </div>
            </div>
          );
        })}
        <div className="flex">
          <Button
            size="xs"
            variant="outline"
            className="mt-3"
            buttonCustom={{
              padding: "8px 12px",
            }}
            startSlot={<PlusIcon width={20} height={20} />}
            onClick={() => {
              append({
                video_id: null,
              });
            }}
          >
            動画を追加する
          </Button>
        </div>
      </div>
    </div>
  );
}
