import { QueryClient } from "@tanstack/react-query";
import {
  FrontFilterListHistoryRequestBody,
  frontListHistoryApi,
} from "./request";

export const fetchFrontListHistoryQuery = async (
  queryClient: QueryClient,
  params?: Partial<FrontFilterListHistoryRequestBody>,
) => {
  return queryClient.fetchQuery({
    queryKey: ["filter-front-e-history", params],
    queryFn: frontListHistoryApi,
  });
};
