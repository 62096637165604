export const scrollToTop = (id?: string) => {
  if (id) {
    const elm = document.getElementById(id);
    if (elm) {
      elm.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }
  window.scrollTo({ top: 0, behavior: "smooth" });
};

/**
 * Function to stop the media stream.
 */
export const stopMediaStream = () => {
  navigator.mediaDevices
    .getUserMedia({ video: true, audio: true })
    .then(function (stream) {
      // Get the video track from the stream
      const videoTrack = stream.getVideoTracks()[0];
      // Stop recording
      videoTrack.stop();
    })
    .catch(function (error) {
      console.error("Error accessing camera:", error);
    });
};

export const getDurationVideo = (src: File | string): Promise<number> => {
  return new Promise((resolve) => {
    let url = "";
    if (typeof src === "string") {
      url = src;
    } else {
      url = URL.createObjectURL(src);
    }
    const video = document.createElement("video");

    video.addEventListener("loadeddata", function () {
      const duration = video.duration * 1000;
      video.remove();
      URL.revokeObjectURL(url);
      resolve(duration);
    });

    video.preload = "metadata";
    video.src = url;
    // Load video in Safari / IE11
    video.muted = true;
    video.playsInline = true;

    video.play();
  });
};

