import { ROUTES_CONSTANT } from "@/configs/constants";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { User } from "@/api/type";
import Avatar from "@/components/atoms/Avatar/avatar";
import CustomTooltip from "@/components/atoms/Tooltip/tooltip";
import { getLinkMedia } from "@/shared/get";
import { useGlobalStore } from "@/states/global.state";
// import { useLogoutMutation } from "@/api/auth";
import { delay } from "lodash";
import Cookies from "js-cookie";
import { STORAGE_KEYS } from "@/api/axios";

/** Properties for the Info component*/
interface InfoProps {
  data?: User | null;
  className?: string;
  isAdmin?: boolean;
  setPasswordRoute: string;
}

/**
 * Info is a component that displays information about info user.
 * @param props - Properties of Info
 * @returns A JSX element that displays information about Info.
 */
export const Info = ({
  data,
  className,
  isAdmin = false,
  setPasswordRoute,
}: InfoProps) => {
  const navigate = useNavigate();
  const { setLoading } = useGlobalStore();
  // const logoutMutation = useLogoutMutation();

  /**Go to Navigation Page */
  const goToPage = useCallback((patch: string) => {
    navigate(patch);
  }, []);

  const fullName = useMemo(
    () => data?.first_name + " " + data?.last_name,
    [data],
  );
  const onSetPasswordUser = () => navigate(setPasswordRoute);

  const subordination = useMemo(() => {
    let text = "";
    const department = data?.department?.name ?? "";
    const office = data?.office?.name ?? "";
    const roleName = isAdmin ? data?.role?.name : "";
    if (department) {
      text += department;
    }
    if (office) {
      text = text ? text + " / " + office : office;
    }
    return text ? text + (roleName?.length ? "・" + roleName : "") : roleName;
  }, [data, isAdmin]);

  const handleLogout = async () => {
    try {
      setLoading(true, true);
      // await logoutMutation.mutateAsync({});
    } finally {
      delay(() => {
        setLoading(false, false);
        Cookies.remove(STORAGE_KEYS.ACCESS_TOKEN);
        navigate(ROUTES_CONSTANT.LOGIN);
      }, 500);
    }
  };

  return (
    <div
      className={`${className} w-full rounded-xl flex desktop:gap-4  mobile:gap-6 p-8 border-primary bg-white flex-col`}
    >
      <div className="flex desktop:gap-5 mobile:gap-3 w-full desktop:flex-row mobile:flex-col items-center justify-between">
        <div className="flex desktop:gap-5 mobile:gap-3 desktop:flex-row mobile:flex-col items-center">
          <Avatar url={getLinkMedia(data?.avatar?.key)} size={78} />
          <div className="flex gap-1 flex-col  mobile:text-center desktop:text-left">
            <h5 className="font-black line-break-anywhere">
              <CustomTooltip text={fullName} max={120} />
            </h5>
            <div className="font-normal text-xs text-secondary-custom mobile:text-center desktop:text-left line-break-anywhere">
              <CustomTooltip text={subordination} max={230} />
            </div>
          </div>
        </div>
        {!isAdmin && (
          <button
            type="button"
            className={
              "font-normal min-w-[124px] max-w-[126px] hover:opacity-80 h-[32px] text-primary--dark border-primary-dark rounded-[7px] py-2 px-[7px] text-xs"
            }
            onClickCapture={() => goToPage(ROUTES_CONSTANT.MY_PAGE_CHANGE_INFO)}
          >
            ユーザ情報の変更
          </button>
        )}
      </div>
      <div className="flex desktop:gap-4 mobile:gap-6 w-ful flex-col">
        <div className="flex justify-between desktop:gap-8 mobile:gap-3 desktop:flex-row mobile:flex-col">
          <div className="flex desktop:items-center desktop:gap-8 mobile:gap-3 desktop:flex-row mobile:flex-col">
            <div className="text-sm font-bold leading-6 text-secondary-custom  w-[160px]">
              メールアドレス
            </div>
            <div className="font-normal text-base leading-6">
              <CustomTooltip text={data?.email} max={160} />
            </div>
          </div>
          {!isAdmin && (
            <button
              className={
                "font-normal min-w-[124px] max-w-[130px] hover:opacity-80 h-[32px] text-primary--dark border-primary-dark rounded-[7px] py-2 px-[7px] text-xs"
              }
              onClickCapture={() =>
                goToPage(ROUTES_CONSTANT.MY_PAGE_CHANGE_EMAIL)
              }
            >
              メールアドレス変更
            </button>
          )}
        </div>
        <div className="flex justify-between desktop:gap-8 mobile:gap-3 desktop:flex-row mobile:flex-col">
          <div className="flex desktop:items-center desktop:gap-8 mobile:gap-3 desktop:flex-row mobile:flex-col">
            <div className="text-sm font-bold leading-6 text-secondary-custom w-[160px]">
              パスワード
            </div>
            <div className="flex gap-8 items-center">
              <div className="font-normal text-base leading-6">********</div>
              {isAdmin && (
                <button
                  className="font-normal min-w-[124px] max-w-[126px] hover:opacity-80 h-[32px] text-primary--dark border-primary-dark rounded-[7px] py-2 px-[7px] text-xs"
                  onClickCapture={onSetPasswordUser}
                >
                  パスワード変更
                </button>
              )}
            </div>
          </div>
          {!isAdmin && (
            <button
              className="font-normal min-w-[124px] max-w-[126px] hover:opacity-80 h-[32px] text-primary--dark border-primary-dark rounded-[7px] py-2 px-[7px] text-xs"
              onClickCapture={() =>
                goToPage(ROUTES_CONSTANT.MY_PAGE_CHANGE_PASSWORD)
              }
            >
              パスワード変更
            </button>
          )}
        </div>
      </div>
      <div className="h-[1px] border-primary"></div>
      <div className="flex gap-8 items-center mobile:justify-between desktop:justify-normal">
        <div className="text-sm font-bold leading-6 text-secondary-custom w-[160px]">
          学習理解度
        </div>
        <div className="font-bold text-base leading-6 w-full">
          {data?.comprehension_level ?? 0}/100
        </div>
      </div>
      {!isAdmin && (
        <div className="flex justify-end">
          <button
            className="font-normal min-w-[126px] max-w-[126px] hover:opacity-80 h-[32px] text-error--main border-error--main rounded-[7px] py-2 px-[7px] text-xs"
            onClickCapture={handleLogout}
          >
            ログアウト
          </button>
        </div>
      )}
    </div>
  );
};
