import { getRoute } from "@/shared/get/route";
import { request } from "../axios";
import { API_PATH_CONSTANT } from "../constant";
import { Folder, OrderDirection, Paging } from "../type";
import { QueryFunctionContext } from "@tanstack/react-query";

export type UpsertFolderRequestBody = {
  id?: number;
  folders: Folder;
};

export type UpsertFolderResponseBody = {
  id: number;
};

export const upsertFolderApi = (
  data: UpsertFolderRequestBody,
): Promise<UpsertFolderResponseBody> => {
  return request({
    method: data.id ? "PUT" : "POST",
    url: data.id
      ? getRoute(API_PATH_CONSTANT.UPDATE_FOLDER, { id: data.id })
      : API_PATH_CONSTANT.CREATE_FOLDER,
    data: {
      folders: data.folders,
    },
  });
};

export type FilterFolderRequestBody = {
  page: number;
  per_page: number;
  order_by?: string | null;
  order_direction?: OrderDirection;
  kind?: "document" | "video";
};

export type FolderItem = Folder & {
  id: number;
  parent_id: number | null;
  childs: FolderItem[];
  user_course_id?: number | null;
  course_id?: number | null;
};

export type FilterFolderResponseBody = {
  folders: FolderItem[];
  paging: Paging;
};

export const listFolderApi = (
  context: QueryFunctionContext,
): Promise<FilterFolderResponseBody> => {
  return request({
    method: "GET",
    url: getRoute(API_PATH_CONSTANT.LIST_FOLDER),
    params: context.queryKey[1],
  });
};

export type DeleteFolderRequestBody = {
  id?: number;
};

export type DeleteFolderResponseBody = {
  id: number;
};

export const deleteFolderApi = (
  data: DeleteFolderRequestBody,
): Promise<DeleteFolderResponseBody> => {
  return request({
    method: "DELETE",
    url: getRoute(API_PATH_CONSTANT.DELETE_FOLDER, { id: data.id }),
  });
};

export type DetailFolderRequestBody = {
  id?: number;
};

export type DetailFolderResponseBody = {
  id: number;
  name: string;
  parent?: DetailFolderResponseBody | null;
};

export const detailFolderApi = (
  data: DetailFolderRequestBody,
): Promise<DetailFolderResponseBody> => {
  return request({
    method: "GET",
    url: getRoute(API_PATH_CONSTANT.DETAIL_FOLDER, { id: data.id }),
  });
};

export type PageFormFolderRequestBody = {
  per_page: number;
  folder_id: number;
  order_by?: null | "updated_at" | "created_at";
  order_direction?: OrderDirection;
  kind?: "document" | "video";
  enabled?: boolean;
};

export type PageFormFolderResponseBody = {
  page?: number | null;
};

export const getPageFormFolderApi = (
  data: PageFormFolderRequestBody,
): Promise<PageFormFolderResponseBody> => {
  return request({
    method: "GET",
    url: getRoute(API_PATH_CONSTANT.GET_PAGE_FOLDER),
    params: {
      per_page: data?.per_page || 10,
      folder_id: data?.folder_id,
      order_by: data?.order_by,
      order_direction: data?.order_direction,
      kind: data?.kind,
    },
  });
};
