import { documentVideos } from "@/api/document/request";
import { useDetailFolderQuery } from "@/api/folder";
import { DetailFolderResponseBody } from "@/api/folder/request";
import { useDeleteVideoMutation, useShowVideoQuery } from "@/api/video";
import Breadcrumb, {
  BeardcrumbOption,
} from "@/components/atoms/Breadcrumb/breadcrumb";
import Button from "@/components/atoms/Button/button";
import CustomTooltip from "@/components/atoms/Tooltip/tooltip";
import CommentElearning from "@/components/molecules/CommentElearning/comment";
import { ModalConfirm } from "@/components/molecules/ModalConfirm/modal-confirm";
import { ModalError } from "@/components/molecules/ModalError/modal-error";
import ElearningVideoContent from "@/components/organisms/ElearningContentDetail/video-content";
import { ROUTES_CONSTANT } from "@/configs/constants";
import { COMMENT_TYPE_ENUM, ROLE_ENUM } from "@/configs/enum";
import { handleApiError } from "@/hooks/error";
import useRole from "@/hooks/role";
import useToast from "@/hooks/toast";
import AnimatedRoute from "@/router/transition.route";
import { getRoute } from "@/shared/get/route";
import { cutString, getFoldersBreadcrumbs } from "@/shared/transform";
import { useAuthStore } from "@/states/auth.state";
import { useGlobalStore } from "@/states/global.state";
import { Skeleton, useDisclosure } from "@chakra-ui/react";
import clsx from "clsx";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

function DetailVideoPage() {
  useRole(false, [
    ROLE_ENUM.MEMBER,
    ROLE_ENUM.ADMIN_SYSTEM,
    ROLE_ENUM.CONTENT_MANAGER,
  ]);
  const navigate = useNavigate();
  const params = useParams();
  const id = params.id as string;
  const folder_id = params.folder_id as string;
  const { showToast } = useToast();
  const { setLoading } = useGlobalStore();
  const {
    isOpen: isOpenModalDelete,
    onOpen: openModalDelete,
    onClose: closeModalDelete,
  } = useDisclosure();
  const deleteVideoMutation = useDeleteVideoMutation();
  const [err, setError] = useState("");
  const { userInfo } = useAuthStore();
  const { data: videoInfo, isFetching } = useShowVideoQuery(folder_id, id);

  const {
    data: folderInfo,
    isFetching: isFetchingFolder,
    refetch,
  } = useDetailFolderQuery({
    id: Number(folder_id),
  });

  const documentsLink = useMemo(() => {
    return videoInfo?.document_videos || [];
  }, [videoInfo]);

  const onDeleteFile = async () => {
    try {
      setLoading(true);
      await deleteVideoMutation.mutateAsync({
        id: Number(id),
        folder_id: Number(folder_id),
      });
      showToast({
        title: `${cutString(videoInfo?.title)}を削除しました`,
        type: "success",
      });
      closeModalDelete();
      navigate(`${ROUTES_CONSTANT.CONTENT_VIDEO_LIST}`);
    } catch (errRes) {
      const err = handleApiError(errRes);
      if (
        err?.[0]?.path === "id" ||
        err?.[0]?.path === "base" ||
        err?.[0]?.path === "popup"
      ) {
        closeModalDelete();
        setError(err[0].message);
      }
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 100);
    }
  };

  const goToEditPage = () => {
    navigate(
      getRoute(ROUTES_CONSTANT.CONTENT_VIDEO_EDIT, {
        id: id,
        folder_id: folder_id,
      }),
    );
  };

  const breadcrumbs: BeardcrumbOption[] = useMemo(() => {
    const getFolders = getFoldersBreadcrumbs(folderInfo);
    const urlFolders = getFolders?.map((item: DetailFolderResponseBody) => {
      return {
        label: item?.name,
        route: `${ROUTES_CONSTANT.CONTENT_VIDEO_LIST}?id=${item?.id}&init=1`,
      };
    });

    return [
      {
        label: "コンテンツ",
        route: ROUTES_CONSTANT.CONTENT_DOCUMENT_LIST,
      },
      {
        label: "動画一覧",
        route: ROUTES_CONSTANT.CONTENT_VIDEO_LIST,
      },
      ...urlFolders,
      {
        label: `${videoInfo?.title}`,
      },
    ];
  }, [folderInfo, videoInfo, folder_id]);

  useEffect(() => {
    refetch();
  }, [folder_id]);

  const isDeleted = useMemo(() => {
    return videoInfo?.creator_id === userInfo?.id;
  }, [videoInfo, userInfo]);

  const goToDocumentPage = (item: documentVideos) => {
    navigate(
      getRoute(ROUTES_CONSTANT.CONTENT_DOCUMENT_DETAIL, {
        id: item?.document?.id,
        folder_id: item?.document?.folder_id,
      }),
    );
  };

  return (
    <div className="m-auto max-w-[960px] w-full desktop:mt-12 mobile:mt-6 px-4">
      {isFetchingFolder ? (
        <Skeleton height={"16px"} rounded={12} />
      ) : (
        <Breadcrumb options={breadcrumbs} />
      )}
      <div
        className={clsx(
          "flex items-center gap-2 mt-5 mobile:flex-wrap desktop:flex-nowrap justify-end",
        )}
      >
        <h4
          className={clsx(
            "font-black mobile:w-full",
            isDeleted
              ? "desktop:w-[calc(100%-206px)]"
              : "desktop:w-[calc(100%-98px)]",
          )}
        >
          <CustomTooltip text={videoInfo?.title} />
        </h4>
        <div className="flex flex-row gap-4 break-keep">
          {isDeleted && (
            <Button
              variant="outline"
              size="sm"
              buttonCustom={{
                borderRadius: "6px",
                padding: "8px 13px",
              }}
              className=""
              onClick={openModalDelete}
            >
              削除する
            </Button>
          )}
          <Button
            size="sm"
            buttonCustom={{
              borderRadius: "6px",
              padding: "8px 13px",
            }}
            className=""
            onClick={goToEditPage}
          >
            編集する
          </Button>
        </div>
      </div>
      {isFetching ? (
        <div className="my-6 min-h-[640px]">
          <Skeleton height={"640px"} rounded={12} />
        </div>
      ) : (
        <div className="my-6 min-h-[640px]">
          <div className="desktop:mx-[52px] mb-6">
            <ElearningVideoContent
              attachment={
                videoInfo?.youtube_id
                  ? {
                      youtube_file_name: videoInfo.youtube_file_name,
                      youtube_id: videoInfo.youtube_id,
                    }
                  : undefined
              }
              videoAttachment={
                videoInfo?.youtube_id
                  ? {
                      youtube_file_name: videoInfo.youtube_file_name,
                      youtube_id: videoInfo.youtube_id,
                    }
                  : undefined
              }
              key={videoInfo?.id}
            />
          </div>
          {documentsLink?.length > 0 && (
            <div
              className={
                "shadow-card bg-white rounded-[20px] mt-6 w-full ck-content p-8 mb-6"
              }
            >
              <p className="subtitle1 font-bold text-[#637381]">
                関連ドキュメント
              </p>
              <div className="flex flex-col gap-2">
                {documentsLink?.map((item, index) => {
                  return (
                    <span
                      key={index}
                      className="body1 text-[var(--primary-main-color)] underline cursor-pointer"
                      onClickCapture={() => goToDocumentPage(item)}
                    >
                      {item?.document?.title}
                    </span>
                  );
                })}
              </div>
            </div>
          )}
          <div
            className="flex desktop:px-5 mobile:px-3 desktop:pb-5 mobile:pb-3 rounded-[20px] bg-primary--lighter flex-col"
            id="comment_block"
          >
            <h5 className="font-black px-5 py-4">コメント</h5>
            <CommentElearning
              className="bg-white rounded-[10px]"
              id={Number(id)}
              commentType={COMMENT_TYPE_ENUM.VIDEO}
            />
          </div>
        </div>
      )}
      <ModalConfirm
        isOpen={isOpenModalDelete}
        onClose={() => {
          setError("");
          closeModalDelete();
        }}
        header={`${cutString(videoInfo?.title)}の削除`}
        message={
          <>
            <p className="body1 text-[#212B36]">
              {cutString(videoInfo?.title)}を削除します。
            </p>
            <p className="body1 text-[#212B36]">よろしいですか？</p>
          </>
        }
        onSubmit={onDeleteFile}
      />
      <ModalError
        message={
          err.length > 0 && (
            <p className="text-error--main input-error__message mb-1">
              {err.replace(/<br\s*\/?>/, "\n")}
            </p>
          )
        }
        isOpen={!!err.length}
        onClose={() => {
          setError("");
        }}
      />
    </div>
  );
}

const AnimatedDetailVideoPage = AnimatedRoute(DetailVideoPage);
export default AnimatedDetailVideoPage;
