import { useDetailFolderQuery } from "@/api/folder";
import { DetailFolderResponseBody } from "@/api/folder/request";
import Breadcrumb, {
  BeardcrumbOption,
} from "@/components/atoms/Breadcrumb/breadcrumb";
import CreateDocumentForm from "@/components/organisms/ContentManagement/document";
import { ROUTES_CONSTANT } from "@/configs/constants";
import { ROLE_ENUM } from "@/configs/enum";
import useRole from "@/hooks/role";
import AnimatedRoute from "@/router/transition.route";
import { getFoldersBreadcrumbs } from "@/shared/transform";
import { Skeleton } from "@chakra-ui/react";
import { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";

function CreateDocumentPage() {
  useRole(false, [
    ROLE_ENUM.MEMBER,
    ROLE_ENUM.ADMIN_SYSTEM,
    ROLE_ENUM.CONTENT_MANAGER,
  ]);
  const params = useParams();
  const folder_id = params.folder_id as string;
  const {
    data: folderInfo,
    isFetching: isFetchingFolder,
    refetch,
  } = useDetailFolderQuery({
    id: Number(folder_id),
  });

  const breadcrumbs: BeardcrumbOption[] = useMemo(() => {
    const getFolders = getFoldersBreadcrumbs(folderInfo);
    const urlFolders = getFolders?.map((item: DetailFolderResponseBody) => {
      return {
        label: item?.name,
        route: `${ROUTES_CONSTANT.CONTENT_DOCUMENT_LIST}?id=${item?.id}&init=1`,
      };
    });

    return [
      {
        label: "コンテンツ",
        route: ROUTES_CONSTANT.CONTENT_DOCUMENT_LIST,
      },
      {
        label: "ドキュメント一覧",
        route: ROUTES_CONSTANT.CONTENT_DOCUMENT_LIST,
      },
      ...urlFolders,
      {
        label: "新規作成",
      },
    ];
  }, [folderInfo]);

  useEffect(() => {
    refetch();
  }, [folder_id]);

  return (
    <div className="m-auto max-w-[960px] w-full desktop:mt-12 mobile:mt-6 px-4">
      {isFetchingFolder ? (
        <Skeleton height={"16px"} rounded={12} />
      ) : (
        <Breadcrumb options={breadcrumbs} />
      )}
      <h4 className="mt-3 font-black">新規作成</h4>
      <div className="desktop:my-8 mobile:my-6">
        <CreateDocumentForm
          folder_id={Number(params!.folder_id)}
          forUser={true}
          isAdmin={false}
        />
      </div>
    </div>
  );
}

const AnimatedCreateDocumentPage = AnimatedRoute(CreateDocumentPage);
export default AnimatedCreateDocumentPage;
