import NavItem from "@/components/atoms/NavItems/nav-items";
import BellIcon from "@/assets/icon/bell-02.svg";
import Logo from "@/assets/logo-front.svg";
import Logo2 from "@/assets/logo-front-sp2.svg";
import LogoFooter from "@/assets/logo-front-sp3.svg";
import SidebarIcon from "@/assets/icon-sp/sidebar.svg";
import Close from "@/assets/icon/close-01.svg";
import useScreenSize from "@/hooks/screen";
import useIcon from "@/hooks/get-icon";
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { ROUTES_CONSTANT } from "@/configs/constants";
import clsx from "clsx";
import { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import NotificationFront from "../Notification/notification.front";

export default function FrontHeader() {
  const { isMobile } = useScreenSize();
  const navigate = useNavigate();
  const location = useLocation();
  const logo = useIcon(<Logo2 />, <Logo />);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const navItems = useMemo(() => {
    return [
      {
        text: "ホーム",
        isActive: location.pathname.startsWith(ROUTES_CONSTANT.DASHBOARD),
        route: ROUTES_CONSTANT.DASHBOARD,
      },
      {
        text: "Eラーニング",
        isActive: location.pathname.startsWith(ROUTES_CONSTANT.ELEARNING),
        route: ROUTES_CONSTANT.ELEARNING_LIST,
      },
      {
        text: "コンテンツ一覧",
        isActive: location.pathname.startsWith(ROUTES_CONSTANT.CONTENT),
        route: ROUTES_CONSTANT.CONTENT_DOCUMENT_LIST,
      },
      {
        text: "マイページ",
        isActive: location.pathname.startsWith(ROUTES_CONSTANT.MY_PAGE),
        route: ROUTES_CONSTANT.MY_PAGE,
      },
    ];
  }, [location.pathname]);

  const handleChangePage = (route: string) => {
    navigate(route);
  };

  return (
    <header className="desktop:h-[64px] mobile:h-[56px] flex desktop:gap-5 justify-between items-center bg-white border-b border-gray-200 border-solid desktop:px-8 mobile:px-4 fixed w-full z-[999] max-w-[100vw]">
      <div className="flex items-center gap-[33px]">
        <div
          className="desktop:py-4 mobile:py-[14px] cursor-pointer"
          onClickCapture={() => handleChangePage(ROUTES_CONSTANT.DASHBOARD)}
        >
          {logo}
        </div>
        {!isMobile && (
          <nav className="flex gap-4">
            {navItems.map((item, index) => (
              <NavItem
                key={index}
                text={item.text}
                isActive={item.isActive}
                onClick={() => handleChangePage(item.route)}
              />
            ))}
          </nav>
        )}
        {!isMobile && (
          <div className="flex justify-center items-center p-2 my-auto w-10 h-10 bg-[var(--primary-lighter-color)] rounded-3xl relative">
            <NotificationFront bellIcon={<BellIcon />} />
          </div>
        )}
      </div>
      {isMobile && (
        <div className="flex gap-2 items-center">
          <div className="flex justify-center items-center p-2 my-auto w-10 h-10 bg-[var(--primary-lighter-color)] rounded-3xl">
            <NotificationFront bellIcon={<BellIcon />} />
          </div>
          <div className="cursor-pointer" onClick={onOpen}>
            <SidebarIcon />
          </div>
          <Drawer
            isOpen={isOpen}
            placement="right"
            onClose={onClose}
            autoFocus={false}
          >
            <DrawerOverlay />

            <DrawerContent
              style={{
                borderBottomLeftRadius: "32px",
                borderTopLeftRadius: "32px",
                width: 300,
              }}
            >
              <DrawerBody style={{ padding: 0 }}>
                <div className="flex justify-end w-full">
                  <Box
                    as="button"
                    className="mt-5 mr-6 w-fit"
                    onClickCapture={onClose}
                  >
                    <Close />
                  </Box>
                </div>
                <div className="px-4 pt-5 flex flex-col gap-y-4">
                  {navItems.map((item, index) => (
                    <div
                      className={clsx(
                        "p-2 cursor-pointer",
                        item.isActive &&
                          "text-[#73be1e] bg-[#f1f9e8] rounded-md",
                      )}
                      key={index}
                      onClickCapture={() => handleChangePage(item.route)}
                    >
                      <h4
                        className={clsx("text-[20px] leading-[32px] font-bold")}
                      >
                        {item.text}
                      </h4>
                    </div>
                  ))}
                </div>
              </DrawerBody>
              <DrawerFooter className="pb-6 pt-4 pl-4 flex !justify-start">
                <LogoFooter
                  className="cursor-pointer"
                  onClickCapture={() =>
                    handleChangePage(ROUTES_CONSTANT.DASHBOARD)
                  }
                />
              </DrawerFooter>
            </DrawerContent>
          </Drawer>
        </div>
      )}
    </header>
  );
}
