import { Input } from "@/components/atoms/Input/Input";
import { formatNumberToHalfWidth } from "@/shared/format";
import { convertToNumber } from "@/shared/transform";
import clsx from "clsx";
import { ReactNode, useMemo, useState } from "react";
import {
  Control,
  Controller,
  FieldValue,
  FieldValues,
  Path,
} from "react-hook-form";

interface ControlledInputProps {
  id?: string;
  name?: string;
  autoComplete?: string;
  type?: React.HTMLInputTypeAttribute;
  value: string | null;
  className?: string;
  label?: string;
  note?: string;
  isRequired?: boolean;
  startSlot?: ReactNode | string | null;
  endSlot?: ReactNode | string | null;
  errorMessage?: string;
  placeholder?: string;
  min?: number;
  max?: number;
  isNumber?: boolean;
  isInteger?: boolean;
  autoConvertToHalfWidth?: boolean;
  maxLength?: number;
  isTrim?: boolean;
  readOnly?: boolean;
  onChange?: (v: string) => void;
}

interface OwnProps<Type extends FieldValues>
  extends Omit<ControlledInputProps, "value"> {
  control: Control<FieldValue<Type>>;
  formField: Path<FieldValue<Type>>;
}

export const ControlledInput = <T extends FieldValues>({
  type,
  control,
  formField,
  className,
  label,
  note,
  isRequired,
  endSlot,
  startSlot,
  errorMessage,
  placeholder,
  id,
  name,
  autoComplete,
  max,
  min,
  isNumber,
  isInteger,
  autoConvertToHalfWidth,
  maxLength,
  isTrim = false,
  onChange,
  readOnly = false,
}: OwnProps<T>) => {
  const [isFocusing, setIsFocus] = useState(false);
  const isError = useMemo(
    () => errorMessage && errorMessage.length > 0,
    [errorMessage],
  );

  const [nativeEventInputType, setNativeEventInputType] = useState<string>("");

  return (
    <Controller
      control={control}
      name={formField}
      render={({ field }) => {
        return (
          <div className={className}>
            {label?.length && (
              <div className="flex gap-2 mb-1 justify-between">
                <div className="flex flex-col justify-center text-xs max-md:max-w-full">
                  <div className="flex gap-1 max-md:flex-wrap">
                    <div
                      className={clsx(
                        isFocusing && !isError
                          ? "text-[var(--primary-main-color)]"
                          : "text-[var(--text-secondary)]",
                      )}
                    >
                      {label}
                    </div>
                    {isRequired && <div className="text-error--main">*</div>}
                  </div>
                </div>
                {note && (
                  <div className="text-[var(--text-secondary)] caption1">
                    {note}
                  </div>
                )}
              </div>
            )}
            <Input
              inputId={id}
              inputName={name}
              inputAutoComplete={autoComplete}
              type={type}
              value={field?.value ?? ""}
              readOnly={readOnly}
              onChange={(e) => {
                let value = e.target.value;
                const type = (e.nativeEvent as InputEvent)?.inputType;
                if (
                  nativeEventInputType === "insertCompositionText" &&
                  type === "insertText"
                ) {
                  return e.preventDefault();
                }

                if (autoConvertToHalfWidth) {
                  value = formatNumberToHalfWidth(value);

                  if (min && Number(value) < min) value = min.toString();
                  if (max && Number(value) > max) value = max.toString();
                }
                if (isNumber) {
                  if (isInteger) value = convertToNumber(value);

                  if (min && Number(value) < min) value = min.toString();
                  if (max && Number(value) > max) value = max.toString();
                }
                setNativeEventInputType(type);
                onChange?.(value);
                return field.onChange(value);
              }}
              startSlot={startSlot}
              endSlot={endSlot}
              errorMessage={errorMessage}
              placeholder={placeholder}
              max={max}
              maxLength={maxLength}
              onFocus={() => {
                setIsFocus(true);
              }}
              onBlur={(e) => {
                let val = e.target.value;
                if (isTrim) {
                  val = val.trim();
                }
                if (val && isNumber) {
                  val = Number(val).toString();
                }
                onChange?.(val);
                field.onChange(val);
                setIsFocus(false);
              }}
            />
          </div>
        );
      }}
    />
  );
};
