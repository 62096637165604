import { create } from "zustand";
import { v4 as uuidv4 } from "uuid";

export interface Notification {
  site: "admin" | "front";
  id: string;
  type: "success" | "warning" | "error" | "info";
  title: string;
  timeout: number;
}

export interface NotificationPayload {
  site?: "admin" | "front";
  type: "success" | "warning" | "error" | "info";
  title: string;
  timeout?: number;
}

type State = {
  facingMode: "user" | "environment";
  notifications: Notification[];
  loading: {
    open: boolean;
    isFC: boolean;
  };
  loadingFC: {
    open?: boolean;
    messageFC?: string;
  };
};

type Action = {
  setLoading: (flag: boolean, isFC?: boolean) => void;
  setLoadingFC: (flag: boolean, messageFC?: string) => void;
  notify: (data: NotificationPayload) => { id: string; timeout: number };
  clearNotification: (id?: string) => void;
  setFacingMode: (facingMode: "user" | "environment") => void;
};

const useGlobalStore = create<State & Action>((set) => ({
  notifications: [],
  facingMode: "user",
  setFacingMode(facingMode) {
    set((state) => ({
      ...state,
      facingMode: facingMode,
    }));
  },
  loading: {
    open: false,
    /** fullscreen */
    isFC: false,
  },
  /** fullscreen single*/
  loadingFC: {
    open: false,
    messageFC: "読み込み中...",
  },
  setLoading: (flag: boolean, isFC = false) =>
    set({
      loading: {
        open: flag,
        isFC: flag === false ? false : isFC,
      },
    }),
  setLoadingFC: (flag: boolean, messageFC: string = "読み込み中...") =>
    set({
      loadingFC: {
        open: flag,
        messageFC: messageFC,
      },
    }),
  notify: (data: NotificationPayload) => {
    const id = uuidv4();
    const timeout = data.timeout || 2000;
    set((state) => ({
      notifications: [
        {
          ...data,
          site: data.site ?? "admin",
          id,
          timeout,
        },
        ...state.notifications,
      ],
    }));

    return { id, timeout };
  },
  clearNotification: (id?: string) => {
    if (id) {
      set(({ notifications }) => {
        const i = notifications.findIndex((item) => item.id === id);
        if (i === -1) return { notifications };

        return {
          notifications: [
            ...notifications.slice(0, i),
            ...notifications.slice(i + 1),
          ],
        };
      });
    } else {
      set({
        notifications: [],
      });
    }
  },
}));

export { useGlobalStore };
