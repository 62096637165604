import { useEffect, useMemo, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { fetchFrontListCourseQuery } from "@/api/e-learning";
import {
  ELEARNING_CONTENT_TYPE_LABEL_2,
  ROUTES_CONSTANT,
} from "@/configs/constants";
import {
  FilterListCourseResponseBody,
  FrontFilterListCourseRequestBody,
} from "@/api/e-learning/request";
import { TParentCategory, TUserCourse } from "@/api/type";
import { cutString } from "@/shared/transform";
import { formatDate } from "@/shared/format";
import Spinner from "@/components/atoms/Spinner/spinner";
import { ELearningDashboardItem } from "@/components/molecules/ELearningItem/e-learning-item.dashboard";
import { useNavigate } from "react-router-dom";
import { getRoute } from "@/shared/get/route";
import dayjs from "dayjs";
import { MESSAGES } from "@/shared/validation/message";
import { delay } from "lodash";
import { useQueryClient } from "@tanstack/react-query";

interface ELearningItemProps {
  id: number;
  isError?: boolean;
  className?: string;
  titleName?: string;
  timeLearning?: string | null;
  dateLearning?: string | null;
  description?: string;
  type?: string;
  isJoin?: boolean;
  required?: boolean;
}

const getDeadlineStatus = (course: TUserCourse) => {
  const now = dayjs().format("YYYY-MM-DD");
  const isJoined = ["done"].includes(course.current_user_course?.status ?? "");

  const deadline = dayjs(course.deadline_date);
  const dayBeforeDeadline = deadline.subtract(7, "day").format("YYYY-MM-DD"); // 7 days before the deadline date (= 6 is because the deadline date is subtracted)

  if (!isJoined) {
    if (dayjs(now) < dayjs(dayBeforeDeadline)) return false;
    return true;
  } else {
    return false;
  }
};

const formatCourse = (course: TUserCourse) => {
  return {
    id: course.id,
    titleName: course?.title ?? "",
    required: course?.required ?? false,
    type:
      course.category && course.sub_category
        ? cutString(course.category?.name, 30) +
          " / " +
          cutString(course.sub_category.name, 30)
        : cutString(course.category?.name, 30) ?? "",
    description:
      (
        course.kinds?.map((kind) => {
          return ELEARNING_CONTENT_TYPE_LABEL_2?.[
            kind as keyof typeof ELEARNING_CONTENT_TYPE_LABEL_2
          ] as string;
        }) ?? []
      ).join(" - ") ?? "",
    isJoin: course.joined_at ? true : false,
    dateLearning:
      course.current_user_course?.status !== "done" && course.deadline_date
        ? formatDate(course.deadline_date)
        : null,
    isError: getDeadlineStatus(course),
  };
};

interface IELearningListCategoryWithCourseItems
  extends Partial<TParentCategory> {
  courses: ELearningItemProps[];
}

export default function ELearningDashboardList() {
  const navigate = useNavigate();
  const [totalPage, setTotalPage] = useState(1);
  const [searchCourseForm, setSearchCourseForm] =
    useState<FrontFilterListCourseRequestBody>({
      page: 1,
      per_page: 10,
      order_by: "courses.deadline_date",
      order_direction: "asc",
    });

  const queryClient = useQueryClient();
  const [isFetching, setIsFetching] = useState(false);
  const [data, setData] = useState<FilterListCourseResponseBody | undefined>();
  const hasMore = useMemo(() => {
    return searchCourseForm.page < totalPage;
  }, [searchCourseForm.page, totalPage]);

  const [categoryList, setCategoryList] = useState<
    IELearningListCategoryWithCourseItems[]
  >([]);

  const dataLength = useMemo(() => {
    return categoryList.reduce(
      (total, category) => total + category.courses.length,
      0,
    );
  }, [categoryList]);
  const handleLoadMore = () => {
    delay(() => {
      setSearchCourseForm((prev) => ({ ...prev, page: prev.page + 1 }));
    }, 300);
  };

  const handleClickCourse = (id: number) => {
    navigate(getRoute(ROUTES_CONSTANT.ELEARNING_DETAIL, { id: id }));
  };

  useEffect(() => {
    setIsFetching(true);
    fetchFrontListCourseQuery(queryClient, searchCourseForm)
      .then((res) => setData(res))
      .finally(() => {
        setIsFetching(false);
      });
  }, [searchCourseForm]);

  useEffect(() => {
    if (!data) {
      return;
    }
    setTotalPage(data.paging.total_pages);
    let newCategoryList = [...categoryList];
    if (data.paging.current_page === 1) {
      newCategoryList = [];
      setCategoryList(newCategoryList);
    }

    let courseCategory: IELearningListCategoryWithCourseItems[] = [];
    data.courses.forEach((course) => {
      const lastCategory = courseCategory[courseCategory.length - 1];
      if (!lastCategory) {
        courseCategory = [
          {
            id: undefined,
            name: undefined,
            courses: [],
          },
        ];
      }
      courseCategory[0].courses.push(formatCourse(course));
    });

    if (
      newCategoryList.length &&
      newCategoryList[newCategoryList.length - 1].id == courseCategory[0]?.id
    ) {
      const firstCategory = courseCategory.shift();
      if (firstCategory?.courses) {
        newCategoryList[newCategoryList.length - 1].courses.push(
          ...firstCategory.courses,
        );
      }
    }
    newCategoryList.push(...courseCategory);

    setCategoryList(newCategoryList);
  }, [data]);

  return (
    <InfiniteScroll
      next={handleLoadMore}
      height={"276px"}
      hasMore={hasMore}
      loader={null}
      endMessage={null}
      hasChildren={true}
      dataLength={dataLength}
      className="flex flex-col gap-4 px-5"
    >
      {isFetching && searchCourseForm.page === 1 ? (
        <div className="flex justify-center items-center min-h-40">
          <Spinner
            circleStyles={{
              borderColor: `var(--primary-main-color) transparent transparent transparent`,
            }}
          />
        </div>
      ) : categoryList.length === 0 ? (
        <p className="w-full rounded-xl gap-2 p-4 border-primary bg-white text-center subtitle2">
          {MESSAGES.MSG_010}
        </p>
      ) : (
        categoryList.map((category, index) => (
          <div className="flex flex-col gap-4" key={index}>
            {category?.name && <h5 className="font-black">{category.name}</h5>}
            <div className="flex flex-col">
              {category.courses?.map((item, index) => (
                <ELearningDashboardItem
                  data={{
                    ...item,
                    className: `cursor-pointer ${
                      index !== 0
                        ? "border-t-[1px] border-[#E6E8EA] border-solid"
                        : ""
                    }`,
                  }}
                  key={item.id}
                  onClick={() => handleClickCourse(item.id)}
                  isDashboard={true}
                />
              ))}
            </div>
          </div>
        ))
      )}
    </InfiniteScroll>
  );
}
