import {
  ChangeEvent,
  useRef,
  useEffect,
  useMemo,
  useState,
  useCallback,
  DragEvent,
} from "react";
import { MESSAGES } from "@/shared/validation/message";
import {
  convertGBtoByte,
  convertKBtoByte,
  convertMBtoByte,
  convertToMp4,
  removeExtension,
} from "@/shared/transform/file";
import { getErrorMessage } from "@/shared/validation/yup";
import clsx from "clsx";
import Button from "../Button/button";
import CloseIcon from "@/assets/icon/close.svg";
import VideoView from "@/components/molecules/VideoView/video-view";
import useScreenSize from "@/hooks/screen";
import { isObjectURL, isValidMimeTypeVideo } from "@/shared/get/check";
import { useBoolean } from "usehooks-ts";
import {
  getExtensionFromTypeFile,
  getSourceVideoJS,
  isFLV,
  isMpeg,
} from "@/shared/get";
import { FFmpeg } from "@ffmpeg/ffmpeg";
import { FileItem, useFileStore } from "@/states/file.state";
import { ModalMediaRecorder } from "@/components/molecules/ModalMediaRecorder/modal-media-recorder";
import { useDisclosure } from "@chakra-ui/react";
import MediaRecorderChromeIos from "@/components/molecules/MediaRecorder/media-recorder.chrome-ios";
import useUpload from "@/hooks/upload";
import { useGlobalStore } from "@/states/global.state";
import { ModalError } from "@/components/molecules/ModalError/modal-error";

interface UploadVideoProps {
  sizeUnit?: "KB" | "MB" | "GB";
  mimeSize?: number;
  isDisabled?: boolean;
  onChange: (content: FileItem | null) => void;
  onRemove?: (content: FileItem | null) => void;
  onError: (msg: string) => void;
  isError?: boolean;
  youtubeInfo?: YoutubeInfo | null;
  placeholder?: string;
  isTakeOfPhoto?: boolean;
}

interface YoutubeInfo {
  name: string | null;
  id: string | null;
}

export default function UploadVideo({
  isError,
  sizeUnit = "MB",
  mimeSize = 20,
  isDisabled = false,
  onError,
  onChange,
  onRemove,
  youtubeInfo,
  placeholder = "flv・mp4・mov・mpegファイルのみ対応",
  isTakeOfPhoto = false,
}: UploadVideoProps): React.ReactElement {
  const facingMode = useGlobalStore((state) => state.facingMode); // 'user' (front) or 'environment' (back)
  const [isFlip, setIFlip] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { value: isConverting, setTrue, setFalse } = useBoolean();

  const {
    isOpen: isOpenModalError,
    onOpen: openModalError,
    onClose: closeModalError,
  } = useDisclosure();

  const { getRandFileName } = useUpload();
  const initial = {
    name: "",
    type: "",
    youtubeId: "",
    objectURL: "",
  };

  const {
    isOpen: isOpenMediaRecorder,
    onOpen: onOpenMediaRecorder,
    onClose: onCloseMediaRecorder,
  } = useDisclosure();
  const { isMobile } = useScreenSize();

  const [currentFile, setFile] = useState(initial);
  const [process, setProcess] = useState(0);
  const ffmpegRef = useRef(new FFmpeg());
  const inputRef = useRef<HTMLInputElement>(null);
  const { screenWidth } = useScreenSize();
  const videoJsOptions = useMemo(() => {
    if (currentFile.objectURL || currentFile.youtubeId) {
      return {
        autoplay: false,
        width: 240,
        height: 135,
        controls: true,
        sources: [
          getSourceVideoJS(
            currentFile.objectURL || currentFile.youtubeId,
            currentFile.type,
            currentFile.name,
          ),
        ],
      };
    }

    return {};
  }, [currentFile]);
  const hasFile = useMemo(() => {
    return currentFile.youtubeId || currentFile.objectURL;
  }, [currentFile]);

  const clickInput = () => {
    !isDisabled && inputRef.current?.click();
  };

  const { removeFile: removeFileStore, setFile: setFileStore } = useFileStore();

  const clickRemove = useCallback(async () => {
    if (isLoading) return;
    onRemove?.(null);
    currentFile.objectURL && removeFileStore(currentFile.objectURL);
    setFile(initial);

    if (inputRef.current) {
      inputRef.current.value = "";
    }
  }, [inputRef, currentFile]);

  const onChangeFile = async (file: File, isCheckType = true) => {
    if (!isValidMimeTypeVideo(file) && isCheckType) {
      return onError(
        getErrorMessage(MESSAGES.MSG_016, {
          type: `flv・mp4・mov・mpeg`,
        }),
      );
    }

    if (sizeUnit === "GB" && file.size > convertGBtoByte(mimeSize)) {
      return onError(
        getErrorMessage(MESSAGES.MSG_014, {
          max: `${mimeSize}GB`,
        }),
      );
    }

    if (sizeUnit === "MB" && file.size > convertMBtoByte(mimeSize)) {
      return onError(
        getErrorMessage(MESSAGES.MSG_014, {
          max: `${mimeSize}MB`,
        }),
      );
    }

    if (sizeUnit === "KB" && file.size > convertKBtoByte(mimeSize)) {
      return onError(
        getErrorMessage(MESSAGES.MSG_014, {
          max: `${mimeSize}KB`,
        }),
      );
    }

    ffmpegRef.current.on("progress", ({ progress: _process }) => {
      setProcess(_process * 100);
    });

    onError("");
    if (file) {
      try {
        setTrue();
        let objectURL = "";
        let type = file.type;

        if (isFLV(file.type, file.name) || isMpeg(file.type)) {
          setLoading(true);
          const blob = await convertToMp4(ffmpegRef.current, file);
          setLoading(false);
          if (blob) {
            type = "video/mp4";
            objectURL = URL.createObjectURL(blob);
          }
        } else {
          objectURL = URL.createObjectURL(file);
        }

        const name = `${removeExtension(file.name)}.${
          type === "video/mp4" ? "mp4" : getExtensionFromTypeFile(type)
        }`;

        setFile({
          name,
          type,
          youtubeId: "",
          objectURL,
        });
        setFileStore({
          name,
          objectURL,
          type,
        });
        setProcess(0);
        setFalse();
        onChange({
          name,
          type,
          objectURL,
        });
      } catch (err) {
        console.error(err);
      }
    } else {
      if (inputRef.current) inputRef.current.value = "";
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (isDisabled) return;
    if (e.target.files && e.target.files[0]) {
      onChangeFile(e.target.files[0]);
    }
  };

  const handleDrop = async (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    if (isLoading || isDisabled) return;
    if (e?.dataTransfer?.files?.length > 1) {
      onError(MESSAGES.MS_048);
      return;
    }
    const file = e.dataTransfer.files[0];
    if (file) {
      onChangeFile(file);
    }
  };

  const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (isDisabled) return;
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const { files: filesStore } = useFileStore();

  const youtubeInfoRef = useRef<string | null>(null);

  useEffect(() => {
    youtubeInfoRef.current = youtubeInfo ? JSON.stringify(youtubeInfo) : null;
  }, [youtubeInfo]);

  useEffect(() => {
    if (youtubeInfoRef.current) {
      const newYtbInfo = JSON.parse(youtubeInfoRef.current);
      let type = "";
      const isLinkLocal = isObjectURL(newYtbInfo?.id);
      if (isLinkLocal) {
        const fileStore = filesStore.find(
          (i) => i.objectURL === newYtbInfo?.id,
        );
        if (fileStore) type = fileStore.type;
      } else {
        type = "video/youtube";
      }
      setFile({
        name: newYtbInfo?.name as string,
        type,
        youtubeId: isLinkLocal ? "" : (newYtbInfo?.id as string),
        objectURL: isLinkLocal ? (newYtbInfo?.id as string) : "",
      });
    } else {
      setFile(initial);
      if (inputRef.current) inputRef.current.value = "";
    }
  }, [youtubeInfoRef.current]);

  const handleFinishRecord = (file: File) => {
    onChangeFile(file, false);
    onClose();
  };

  const handleFinishRecordInMobile = async (recordedData: Blob) => {
    const fileName = getRandFileName();
    const file = new File([recordedData], fileName, {
      type: recordedData.type,
    });
    onChangeFile(file, false);
    setIFlip(facingMode === "user");
    onCloseMediaRecorder();
  };

  return (
    <div
      className={clsx(
        "w-full flex justify-center bg-[#F8F8F8] border-dashed border-[1px] border-[#E6E8EA]  p-4 rounded-[8px]",
        isError && "border-red-600 !border-solid",
        isDragging ? "bg-[#d0eaff]" : "bg-[#F8F8F8]",
      )}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
    >
      {isConverting ? (
        <div className="flex flex-col justify-center">
          <span className="text-[#73be1e] font-bold text-sm text-center">
            ファイルをMP4へ変換しています。しばらくお待ちください。
            <br />
            {process.toFixed(2)} %
          </span>
        </div>
      ) : hasFile ? (
        <div
          className={`flex items-center justify-start w-full ${
            screenWidth <= 475 ? "flex-wrap" : ""
          }`}
        >
          <div
            className={`rounded-[8px] ${
              screenWidth <= 320 ? "min-w-[200px]" : "min-w-[240px]"
            } w-[240px] 
            ${screenWidth <= 320 ? "h-[115px] " : "h-[135px]"}
            border-[1px] border-solid border-[#d4d4d4] overflow-hidden`}
          >
            <VideoView options={videoJsOptions} isFlip={isFlip} />
          </div>
          <div className="flex items-center">
            <span className="body1 ml-2 mr-4 text-[#212B36] line-break-anywhere line-clamp-3">
              {currentFile?.name}
            </span>
            <div className={"cursor-pointer mt-[1px]"}>
              <CloseIcon width={16} height={16} onClick={clickRemove} />
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center gap-y-[10px]">
          <span className="caption1 text-[#919EAB]">
            ドラッグ&ドロップでもファイルをアップロードすることができます
          </span>
          <Button
            size="sm"
            className={"w-[160px]"}
            buttonCustom={{
              padding: "8px 16px",
              fontSize: "14px",
              lineHeight: "24px",
              fontWeight: "700",
              borderRadius: "8px",
            }}
            onClick={clickInput}
            isDisabled={isDisabled}
          >
            アップロード
          </Button>
          <span className="caption1 text-[#919EAB]">
            {isTakeOfPhoto ? "または" : placeholder}
          </span>
          {isTakeOfPhoto && (
            <Button
              size="sm"
              className={"w-[160px]"}
              buttonCustom={{
                padding: "8px 16px",
                fontSize: "14px",
                lineHeight: "24px",
                fontWeight: "700",
                borderRadius: "8px",
              }}
              onClick={() => {
                if (isMobile) {
                  onOpenMediaRecorder();
                } else {
                  onOpen();
                }
              }}
              isDisabled={isDisabled}
            >
              撮影する
            </Button>
          )}
          {isTakeOfPhoto && (
            <span className="caption1 text-[#919EAB]">{placeholder}</span>
          )}
        </div>
      )}
      <input
        ref={inputRef}
        id="upload-input"
        type="file"
        accept="video/mp4, video/mpeg, video/x-flv, video/quicktime, .flv, .mov, .mpeg"
        className="hidden"
        onChange={handleInputChange}
      />
      {!isMobile && (
        <ModalMediaRecorder
          isOpen={isOpen}
          onClose={onClose}
          onFinish={handleFinishRecord}
          onMustOnCamera={openModalError}
        ></ModalMediaRecorder>
      )}
      {isMobile && (
        <MediaRecorderChromeIos
          isOpen={isOpenMediaRecorder}
          onClose={onCloseMediaRecorder}
          onFinish={handleFinishRecordInMobile}
        />
      )}
      <ModalError
        message={
          <p className="text-error--main input-error__message mb-1">
            カメラをONにしてください。
          </p>
        }
        isOpen={isOpenModalError}
        btnCloseText="OK"
        onClose={closeModalError}
      />
    </div>
  );
}
