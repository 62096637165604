import {
  getStatusJob,
  useDeleteYoutubeMutation,
  useUploadFileMutation,
  useUploadFileToYoutubeMutation,
} from "@/api/upload";
import { StatusJobResponseBody } from "@/api/upload/request";
import {
  getFileExtension,
  getMimeTypeRecorder,
  getSleepTimeAdjustedToFileSize,
} from "@/shared/get";
import { isObjectURL } from "@/shared/get/check";
import { convertBytetoMB } from "@/shared/transform/file";
import { useGlobalStore } from "@/states/global.state";
import { useQueryClient } from "@tanstack/react-query";
import { HttpStatusCode } from "axios";
import { useCallback, useState } from "react";
import { v4 as uuidv4 } from "uuid";
export type UploadResponse = {
  key: string;
  name: string;
  content_type: string;
  id?: number;
  error?: string;
  _destroy?: boolean;
};

export type UploadYoutubeResponse = {
  key?: string;
  name?: string;
  youtube_id?: string;
  youtube_file_name?: string;
  error?: string;
  _destroy?: boolean;
};

export default function useUpload() {
  const { setLoading } = useGlobalStore();
  const { mutateAsync, progress: processNum } = useUploadFileMutation();
  const { mutateAsync: mutateYtbAsync, progress: processNumYtb } =
    useUploadFileToYoutubeMutation();
  const { mutateAsync: mutateDeleteYtbAsync } = useDeleteYoutubeMutation();
  const [loading, setLoadingUpload] = useState(false);
  const queryClient = useQueryClient();

  const uploadFn = async (file: File): Promise<UploadResponse | null> => {
    try {
      setLoading(true);
      setLoadingUpload(true);
      const key = `${uuidv4()}.${getFileExtension(file.name)}`;
      const res = await mutateAsync({
        file,
        object_name: key,
      });

      const fileSize = convertBytetoMB(file.size);
      const sleep = getSleepTimeAdjustedToFileSize(fileSize);
      const job = await checkStatusJob(res.job_id, sleep);

      return {
        key,
        name: file.name,
        error: job?.message?.replace(/<br\s*\/?>/, "\n"),
        content_type: file.type,
      };
    } catch (e) {
      return null;
    } finally {
      setLoading(false);
      setLoadingUpload(false);
    }
  };

  const checkStatusJob = async (
    jobId: string,
    sleepTime = 5000,
  ): Promise<StatusJobResponseBody | null> => {
    let res: StatusJobResponseBody | null = null;
    let status = "processing";
    while (["processing"].includes(status)) {
      res = await getStatusJob(queryClient, jobId);
      status = res.status;
      await new Promise((resolve) => setTimeout(resolve, sleepTime));
    }

    return res;
  };

  const uploadYoutubeFn = async (
    file: File,
    folder_id?: number,
  ): Promise<UploadYoutubeResponse> => {
    try {
      setLoading(true);
      setLoadingUpload(true);
      const key = `${uuidv4()}.${getFileExtension(file.name)}`;
      const res = await mutateYtbAsync({
        file,
        folder_id,
      });

      const fileSize = convertBytetoMB(file.size);
      const sleep = getSleepTimeAdjustedToFileSize(fileSize);
      const job = await checkStatusJob(res.job_id, sleep);
      if (job?.message)
        return {
          error: job.message.replace(/<br\s*\/?>/, "\n"),
        };

      return {
        key,
        name: file.name,
        youtube_id: job?.youtube_id as string,
        youtube_file_name: job?.youtube_file_name as string,
      };
    } catch (e: any) {
      if (e.response?.status === HttpStatusCode.GatewayTimeout) {
        alert("timeout");
        return {
          error:
            "ファイルのアップロードに失敗しました。\n時間をおいて再度お試しください。",
        };
      }

      return {
        error: e.response.data.error_message,
      };
    } finally {
      setLoading(false);
      setLoadingUpload(false);
    }
  };

  const deleteYoutubeFn = useCallback(async (id: string) => {
    if (isObjectURL(id)) return;
    try {
      setLoading(true);
      await mutateDeleteYtbAsync(id);
    } catch (e) {
      console.error(e);
      return null;
    } finally {
      setLoading(false);
    }
  }, []);

  const getRandFileName = useCallback((isOnlyExt: boolean = false) => {
    let ext = "mp4";
    const mimeType = getMimeTypeRecorder();
    if (mimeType?.startsWith("video/webm")) ext = "webm";
    return isOnlyExt ? ext : `record_${uuidv4()}.${ext}`;
  }, []);

  return {
    getRandFileName,
    uploadFn,
    uploadYoutubeFn,
    deleteYoutubeFn,
    processNum: processNum > 1 ? processNum - 1 : processNum,
    processNumYtb: processNumYtb > 1 ? processNumYtb - 1 : processNumYtb,
    loading,
  };
}
