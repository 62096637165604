import { ChangeEventHandler, ReactNode, useMemo } from "react";
import "./input.scss";
import clsx from "clsx";

interface InputProps {
  inputId?: string;
  inputName?: string;
  inputAutoComplete?: string;
  type?: React.HTMLInputTypeAttribute;
  value?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  startSlot?: ReactNode | string | null;
  endSlot?: ReactNode | string | null;
  placeholder?: string;
  size?: "sm" | "md" | "lg";
  errorMessage?: string;
  max?: number;
  readOnly?: boolean;
  maxLength?: number;
}

export const Input = ({
  startSlot,
  endSlot,
  placeholder = "パスワードを入力してください",
  size = "md",
  type = "text",
  errorMessage = "",
  onChange,
  onFocus,
  onBlur,
  inputAutoComplete,
  inputName,
  inputId,
  max,
  maxLength,
  value = "",
  readOnly,
}: InputProps) => {
  const isError = useMemo(
    () => errorMessage && errorMessage.length > 0,
    [errorMessage],
  );

  return (
    <div>
      <div
        className={clsx(
          "input__group p-3 rounded-[12px]",
          `input__size--${size}`,
          isError && "is-invalid",
        )}
      >
        {startSlot && <div className="input__start-element">{startSlot}</div>}
        <input
          id={inputId}
          name={inputName}
          autoComplete={inputAutoComplete}
          type={type}
          value={value}
          className="body1"
          placeholder={placeholder}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          onInput={(e) => {
            console.log("input", e);
          }}
          maxLength={maxLength}
          readOnly={readOnly}
          max={max}
        />
        {endSlot && <div className="input__end-element">{endSlot}</div>}
      </div>
      {isError && (
        <div className="mt-1 w-full text-error--main input-error__message">
          {errorMessage}
        </div>
      )}
    </div>
  );
};
