import { ColumnDef, SortingState } from "@tanstack/react-table";
import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { OrderDirection } from "@/api/type";
import Avatar from "@/components/atoms/Avatar/avatar";
import DataTable from "@/components/molecules/Table/table";
import {
  ADMIN_ROUTES_CONSTANT,
  PAGINATION_CONSTANT,
} from "@/configs/constants";
import { getRoute } from "@/shared/get/route";
import { getLinkMedia } from "@/shared/get";
import CustomTooltip from "@/components/atoms/Tooltip/tooltip";
import {
  showUserCoursesQuery,
  useListUserJoinedCourseQuery,
  useListUserNotJoinCourseQuery,
  useUpdateUserCourseMutation,
} from "@/api/e-learning";
import useRole from "@/hooks/role";
import { ELEARNING_CONTENT_KIND_ENUM, ROLE_ENUM } from "@/configs/enum";
import clsx from "clsx";
import { ExamResultModal } from "./exam-result.modal";
import { useDisclosure } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { ShowUserCoursesResponseBody } from "@/api/e-learning/request";
import useToast from "@/hooks/toast";
import { handleApiError } from "@/hooks/error";

/**
 * Interface: ElearningUserListProps
 *
 * Represents the props for the ElearningUserList component.
 *
 * @property id - The id of the quizz.
 *
 */
interface ElearningUserListProps {
  id: number;
  title: string;
  kind: "joined" | "not-join";
  totalScore?: number;
}

const pageSize = PAGINATION_CONSTANT.DEFAULT_PAGE_SIZE;

export default function ElearningUserList({
  id,
  title,
  kind,
  totalScore,
}: ElearningUserListProps) {
  const { isAdminSystem } = useRole(true, [
    ROLE_ENUM.ADMIN_SYSTEM,
    ROLE_ENUM.CONTENT_MANAGER,
  ]);
  const [isConfirmed, setConfirmed] = useState<boolean>(false);
  const [course_id, setCourse_id] = useState<number>();
  const [userCourseId, setUserCourseId] = useState<number>();
  const { showToast } = useToast();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [searchForm, setSearchForm] = useState({
    page: 1,
    order_by: "updated_at",
    order_direction: "desc" as OrderDirection,
  });

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [oldCourseContents, setOldCourseContents] = useState<
    ShowUserCoursesResponseBody["user_course_contents"] | []
  >([]);

  const {
    data: listUserJoinedResponse,
    isFetching: isFetching1,
    refetch,
  } = useListUserJoinedCourseQuery(
    {
      id: id,
      page: searchForm.page,
      per_page: PAGINATION_CONSTANT.DEFAULT_PAGE_SIZE,
      order_by: searchForm.order_by || "updated_at",
      order_direction: searchForm.order_direction,
      status: "done",
    },
    {
      enabled: kind === "joined",
    },
  );

  const updateUserCourseMutation = useUpdateUserCourseMutation();

  const { data: listUserNotJoinResponse, isFetching: isFetching2 } =
    useListUserNotJoinCourseQuery(
      {
        id: id,
        page: searchForm.page,
        per_page: PAGINATION_CONSTANT.DEFAULT_PAGE_SIZE,
        order_by:
          searchForm?.order_by === "no_sort"
            ? undefined
            : searchForm?.order_by || "updated_at",
        order_direction:
          searchForm?.order_by === "no_sort"
            ? undefined
            : searchForm.order_direction,
      },
      {
        enabled: kind === "not-join",
      },
    );

  const users = useMemo(() => {
    if (kind === "joined") {
      return listUserJoinedResponse?.user_courses?.map((user_course) => ({
        id: user_course.user.id,
        avatar: getLinkMedia(
          user_course.user.thumbnail?.key ?? user_course.user.avatar?.key,
        ),
        user_course_id: user_course.id,
        fullname: `${user_course.user.first_name} ${user_course.user.last_name}`,
        department: `${user_course.user.department?.name || ""} ${
          user_course.user.office?.name
            ? " / " + user_course.user.office?.name
            : ""
        }`,
        email: user_course.user.email,
        score: `${user_course.total_point ?? 0}/${user_course.point ?? 0}`,
        confirmed: user_course?.confirmed,
      }));
    } else {
      return listUserNotJoinResponse?.users?.map((user) => ({
        id: user.id,
        user_course_id: null,
        avatar: getLinkMedia(user.thumbnail?.key || user.avatar?.key),
        fullname: `${user.first_name} ${user.last_name}`,
        department: `${user.department?.name || ""} ${
          user.office?.name ? " / " + user.office?.name : ""
        }`,
        email: user.email,
        score: `${user.score ?? 0}/${totalScore ?? 0}`,
      }));
    }
  }, [listUserNotJoinResponse, listUserJoinedResponse, kind]);

  const paging = useMemo(() => {
    if (kind === "joined") {
      return listUserJoinedResponse?.paging;
    } else {
      return listUserNotJoinResponse?.paging;
    }
  }, [listUserNotJoinResponse, listUserJoinedResponse, kind]);

  const getUserCoursesData = async (
    course_id: number,
    user_course_id: number,
  ) => {
    try {
      const res = await showUserCoursesQuery(queryClient, {
        course_id: course_id,
        id: user_course_id,
      });
      const filterQuizResults = res.user_course_contents.filter(
        (r) => r.kind === ELEARNING_CONTENT_KIND_ENUM.QUIZ,
      );
      return filterQuizResults;
    } catch {
      return [];
    }
  };

  const goToShowUser = async (
    user_id: number,
    user_course_id: number,
    confirmed: boolean,
  ) => {
    if (kind === "joined") {
      const courseContents = await getUserCoursesData(id, user_course_id);
      setOldCourseContents(courseContents);
      if (courseContents.length) {
        setConfirmed(confirmed);
        setCourse_id(id);
        setUserCourseId(user_course_id);
        onOpen();
        return;
      }
    }

    if (isAdminSystem)
      navigate(
        getRoute(ADMIN_ROUTES_CONSTANT.MANAGEMENT.ELEARNING_DETAIL_USER, {
          elearning_id: id,
          id: user_id,
        }),
      );
  };

  const onSorting = useCallback((sortState: SortingState) => {
    if (!sortState[0]) return;

    setSearchForm((prev) => ({
      ...prev,
      order_by: sortState[0].id,
      order_direction: sortState[0].desc ? "desc" : "asc",
    }));
  }, []);

  const onChangePage = useCallback((page: number) => {
    setSearchForm((prev) => ({ ...prev, page }));
  }, []);

  const columns = useMemo<ColumnDef<any>[]>(() => {
    const result = [
      {
        id: kind === "joined" ? "users.fullname" : "fullname",
        accessorKey: "fullname",
        header: () => "ユーザー名",
        cell: (info: any) => {
          return (
            <div
              className={clsx(
                "flex gap-2 items-center",
                isAdminSystem && "cursor-pointer",
              )}
              onClick={() =>
                goToShowUser(
                  info.row.original.id,
                  info.row.original.user_course_id,
                  info.row.original.confirmed,
                )
              }
            >
              <Avatar url={info.row.original.avatar} size="sm" />

              <span className="body1 text-black-custom break-all whitespace-normal">
                <CustomTooltip text={info.row.original.fullname} max={30} />
              </span>
            </div>
          );
        },
        meta: {
          minWidth: 200,
        },
      },
      {
        id: "departments.name",
        accessorKey: "department",
        header: () => "所属情報",
        cell: (info: any) => {
          return (
            <div className="body1 text-black-custom">
              <CustomTooltip text={info.row.original.department} max={30} />
            </div>
          );
        },
      },
      {
        id: kind === "joined" ? "users.email" : "email",
        accessorKey: "email",
        header: () => "メールアドレス",
        cell: (info: any) => (
          <CustomTooltip text={info.row.original.email} max={30} />
        ),
      },
      {
        accessorKey: kind === "joined" ? "total_point" : "no_sort",
        header: () => "テスト点数",
        cell: (info: any) => (
          <div className="body1 text-black-custom">
            {info.row.original.score}
          </div>
        ),
        meta: {
          width: 160,
        },
      },
    ];
    if (kind === "joined") {
      result.push({
        accessorKey: "confirmed",
        header: () => "確認ステータス",
        cell: (info) => {
          return (
            <div className="body1 text-black-custom">
              {info.row.original?.confirmed ? "確認済" : ""}
            </div>
          );
        },
        meta: {
          width: 160,
        },
      });
    }
    return result;
  }, [kind]);

  const onChangeConfirmed = async () => {
    try {
      setConfirmed(true);
      await updateUserCourseMutation.mutateAsync({
        course_id: Number(course_id),
        user_course_id: Number(userCourseId),
        user_courses: {
          confirmed: true,
        },
      });
      showToast({
        title: `変更を保存しました`,
        type: "success",
      });
      refetch();
    } catch (errRes) {
      const error = handleApiError(errRes);
      showToast({
        title: error?.message,
        type: "error",
      });
    }
  };

  return (
    <div>
      <div className="mb-5 flex items-center justify-between">
        <h5>{title}</h5>
      </div>

      <DataTable
        className="w-full"
        data={users ?? []}
        columns={columns}
        total={paging?.total_records ?? 0}
        defaultPage={searchForm.page}
        defaultPageSize={pageSize}
        defaultSort={[
          {
            id: searchForm.order_by ?? "updated_at",
            desc: searchForm.order_direction === "desc",
          },
        ]}
        onChangePage={onChangePage}
        onSorting={onSorting}
        headerSlot={null}
        loading={isFetching1 || isFetching2}
      />

      <ExamResultModal
        isOpen={isOpen}
        onClose={onClose}
        isConfirmed={isConfirmed}
        onChangeConfirmed={() => onChangeConfirmed()}
        oldCourseContents={oldCourseContents}
      />
    </div>
  );
}
