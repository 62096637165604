import DataTable from "@/components/molecules/Table/table";
import { ColumnDef, SortingState } from "@tanstack/react-table";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSearchELearningForm } from "./search.validate";
import { ControlledSelect } from "@/components/molecules/ControlledSelect/controlled-select";
import ThreeDotActionTable from "@/components/molecules/ActionButtonTable/three-dot";
import PencilIcon from "@/assets/icon/pencil.svg";
import CloseIcon from "@/assets/icon/close.svg";
import { ModalConfirm } from "@/components/molecules/ModalConfirm/modal-confirm";
import CustomTooltip from "@/components/atoms/Tooltip/tooltip";
import { OrderDirection, TELearning } from "@/api/type";
import {
  ADMIN_ROUTES_CONSTANT,
  PAGINATION_CONSTANT,
  STATUS_OPTION,
} from "@/configs/constants";
import {
  useDeleteELearningMutation,
  useListELearningQuery,
} from "@/api/e-learning";
import { useListCategoryQuery } from "@/api/category";
import { Option } from "@/components/atoms/SelectBox/virtual-select-box";
import { useListTagQuery } from "@/api/tag";
import { formatDate } from "@/shared/format";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getRoute } from "@/shared/get/route";
import { cutString } from "@/shared/transform";
import { handleApiError } from "@/hooks/error";
import qs from "qs";
import { SuggestionSearch } from "@/components/molecules/SuggestionSearch/suggestion-search";
import clsx from "clsx";
import { useGlobalStore } from "@/states/global.state";
import { isString } from "lodash";
import { ModalError } from "@/components/molecules/ModalError/modal-error";
import useToast from "@/hooks/toast";

type ELearning = {
  id: number;
  title: string;
  category?: string;
  tag_names?: string;
  status?: string;
  created_at?: string;
  updated_at?: string;
  user_courses_count?: number;
};
const pageSize = PAGINATION_CONSTANT.DEFAULT_PAGE_SIZE;

export default function ELearningListForAdmin(): JSX.Element {
  const { setLoading } = useGlobalStore();
  const { control, reset } = useSearchELearningForm();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [err, setError] = useState("");
  const deleteMutation = useDeleteELearningMutation();
  const { showToast } = useToast();

  const [modalConfig, setModalConfig] = useState({
    open: false,
    data: {
      id: 0,
      title: "",
    },
    folder: null as { id: number; name: string } | null,
  });
  const [searchForm, setSearchForm] = useState({
    page: parseInt(searchParams.get("page") ?? "1"),
    title: searchParams.get("title") ?? "",
    fullname: searchParams.get("fullname") ?? "",
    join_user_name: searchParams.get("join_user_name") ?? "",
    parent_category_id: searchParams.get("parent_category_id") ?? "",
    sub_category_id: searchParams.get("sub_category_id") ?? "",
    tag_id: searchParams.get("tag_id") ?? "",
    status: searchParams.get("status") ?? "",
    is_public: searchParams.get("is_public") ?? "",
    created_at: searchParams.get("created_at") ?? "",
    updated_at: searchParams.get("updated_at") ?? "",
    order_by: searchParams.get("order_by")?.length
      ? searchParams.get("order_by")
      : "updated_at",
    order_direction: (searchParams.get("order_direction") ??
      "desc") as OrderDirection,
  });
  const {
    data: eLearningResponse,
    refetch,
    isFetching,
  } = useListELearningQuery({
    page: searchForm.page,
    per_page: pageSize,
    title: searchForm.title ?? "",
    parent_category_id: !isNaN(parseInt(searchForm.parent_category_id))
      ? parseInt(searchForm.parent_category_id)
      : "" ?? "",
    sub_category_id: !isNaN(parseInt(searchForm.sub_category_id))
      ? parseInt(searchForm.sub_category_id)
      : "" ?? "",
    tag_id: !isNaN(parseInt(searchForm.tag_id))
      ? parseInt(searchForm.tag_id)
      : "" ?? "",
    status: searchForm.status ?? "",
    join_user_name: searchForm.join_user_name ?? "",
    is_public: searchForm.is_public ?? "",
    order_by: searchForm.order_by || "updated_at",
    order_direction: searchForm.order_direction,
  });

  const { data: categoryRestponse } = useListCategoryQuery({
    page: 1,
    per_page: PAGINATION_CONSTANT.MAX_PAGE_SIZE,
    for_admin: true,
  });
  const { data: tagRestponse } = useListTagQuery({
    page: 1,
    per_page: PAGINATION_CONSTANT.MAX_PAGE_SIZE,
  });

  const categoryOptions = useMemo(() => {
    const result: Option[] = [];
    categoryRestponse?.categories
      ?.sort((a, b) => a.position - b.position)
      ?.forEach((category) => {
        if (category.sub_categories && category.sub_categories.length > 0) {
          category.sub_categories
            .sort((a, b) => a.position - b.position)
            .forEach((sub) => {
              result.push({
                value: `${category.id}-${sub.id}`,
                label: `${category.name} / ${sub.name}`,
              });
            });
        } else {
          result.push({ value: category.id, label: category.name });
        }
      });
    return [
      {
        value: "",
        label: "全てのカテゴリ",
      },
      ...result,
    ];
  }, [categoryRestponse]);
  const tagOptions = useMemo(() => {
    const list =
      tagRestponse?.tags?.map((tag) => ({
        value: tag?.id?.toString() ?? "",
        label: tag.name,
      })) ?? [];
    return [
      {
        value: "",
        label: "全てのタグ",
      },
      ...list,
    ];
  }, [tagRestponse]);
  const statusOptions = STATUS_OPTION;
  const datas: ELearning[] = useMemo(() => {
    return (
      eLearningResponse?.courses?.map((course) => {
        return {
          id: course.id,
          title: course.title,
          category:
            course.category && course.sub_category
              ? course.category?.name + " / " + course.sub_category.name
              : course.category?.name,
          tag_names: course.tag_names,
          status: course.is_public ? "公開" : "非公開",
          user_courses_count: course.user_courses_count ?? 0,
          created_at: formatDate(course.created_at),
          updated_at: formatDate(course.updated_at),
          folder: course.folder,
        };
      }) || []
    );
  }, [eLearningResponse]);
  const paging = useMemo(() => eLearningResponse?.paging, [eLearningResponse]);
  const menuItems = useMemo(() => {
    return [
      {
        icon: <PencilIcon />,
        text: <span className="body1 ">編集する</span>,
        onClick: (data: TELearning) => {
          goToEditPage(data);
        },
      },
      {
        icon: <CloseIcon />,
        text: <span className="body1 text-error--main">削除する</span>,
        onClick: (data: TELearning) => {
          openModalDelete(data);
        },
      },
    ];
  }, []);
  const columns = useMemo<ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "title",
        header: () => "タイトル",
        cell: (info) => {
          return (
            <div
              className="text-base font-bold cursor-pointer"
              onClick={() => goToDetailPage(info.row.original.id)}
            >
              <CustomTooltip
                max={50}
                text={info.row.original.title}
              ></CustomTooltip>
            </div>
          );
        },
        meta: {
          width: 211,
        },
      },
      {
        accessorKey: "parent_category_id",
        header: () => "カテゴリ",
        cell: (info) => {
          return (
            <div className="body1 w-fit !font-bold">
              <div
                className={clsx(
                  info.row.original.category &&
                    "rounded px-2 py-1 text-primary--main bg-primary--lighter",
                )}
              >
                <CustomTooltip
                  max={50}
                  text={info.row.original.category}
                ></CustomTooltip>
              </div>
            </div>
          );
        },
        meta: {
          width: 160,
        },
      },
      {
        accessorKey: "tag_names",
        header: () => "タグ",
        cell: (info) => (
          <div>
            <CustomTooltip
              max={50}
              text={info.row.original.tag_names}
            ></CustomTooltip>
          </div>
        ),
        meta: {
          width: 160,
        },
      },
      {
        accessorKey: "is_public",
        header: () => "ステータス",
        cell: (info) => (
          <div className="body1 text-black-custom">
            <CustomTooltip text={info.row.original.status}></CustomTooltip>
          </div>
        ),
        meta: {
          width: 106,
        },
      },
      {
        accessorKey: "user_courses_count",
        header: "受講数(人)",
        cell: (info) => {
          return (
            <div className="body1 text-black-custom">
              <CustomTooltip
                text={info.row.original.user_courses_count}
              ></CustomTooltip>
            </div>
          );
        },
        meta: {
          width: 115,
        },
      },
      {
        accessorKey: "created_at",
        header: "作成日",
        cell: (info) => {
          return (
            <div className="body1 text-black-custom min-w-[106px]">
              {info.row.original.created_at}
            </div>
          );
        },
        meta: {
          width: 106,
        },
      },
      {
        accessorKey: "updated_at",
        header: "更新日",
        cell: (info) => {
          return (
            <div className="body1 text-black-custom">
              {info.row.original.updated_at}
            </div>
          );
        },
        meta: {
          width: 106,
        },
      },
      {
        id: "action",
        accessorKey: "",
        enableSorting: false,
        meta: {
          width: 80,
        },
        cell: (info) => {
          return (
            <div className="max-w-[46px]">
              <ThreeDotActionTable
                menuItems={menuItems}
                data={info.row.original}
              />
            </div>
          );
        },
      },
    ],
    [],
  );
  const onChangePage = useCallback((p: number) => {
    setSearchForm((prev) => ({ ...prev, page: p }));
  }, []);

  const onSorting = useCallback((sortState: SortingState) => {
    if (!sortState[0]) return;

    setSearchForm((prev) => ({
      ...prev,
      order_by: sortState[0].id,
      order_direction: sortState[0].desc ? "desc" : "asc",
    }));
  }, []);

  /**
   * Function to open the delete modal.
   */
  const openModalDelete = (data: TELearning) => {
    setModalConfig({
      open: true,
      data: {
        id: data.id,
        title: data.title,
      },
      folder: data.folder,
    });
  };

  const goToEditPage = (data: TELearning) => {
    navigate(
      `${getRoute(ADMIN_ROUTES_CONSTANT.MANAGEMENT.ELEARNING_EDIT, {
        id: data.id,
      })}`,
    );
  };

  const goToDetailPage = (id: number) => {
    return navigate(
      `${getRoute(ADMIN_ROUTES_CONSTANT.MANAGEMENT.ELEARNING_DETAIL, {
        id: id,
      })}`,
    );
  };

  const handleChangeTag = useCallback((option: Option) => {
    const tagId = String(option?.value ?? "");

    setSearchForm((prev) => ({
      ...prev,
      tag_id: tagId ?? "",
      page: 1,
    }));
  }, []);

  const handleChangeStatus = useCallback((option: Option) => {
    const isPublic = String(option?.value ?? "");

    setSearchForm((prev) => ({
      ...prev,
      is_public: isPublic ?? "",
      page: 1,
    }));
  }, []);

  const handleChangeCategory = useCallback((option: Option) => {
    const category = String(option?.value ?? "");
    const temp = {
      parent_category_id: "",
      sub_category_id: "",
    };
    if (category.toString().includes("-")) {
      temp.parent_category_id = category?.split("-")[0] ?? "";
      temp.sub_category_id = category?.split("-")[1] ?? "";
    } else {
      temp.parent_category_id = category;
    }
    setSearchForm((prev) => ({
      ...prev,
      ...temp,
      page: 1,
    }));
  }, []);

  const refreshSearch = useCallback(() => {
    if (searchForm.page != PAGINATION_CONSTANT.DEFAULT_PAGE) {
      setSearchForm((prev) => ({ ...prev, page: 1 }));
    } else {
      refetch();
    }
  }, [searchForm.page]);

  /** handle close modal delete */
  const onCloseModal = useCallback(() => {
    setError("");
    setModalConfig({
      open: false,
      data: {
        id: 0,
        title: "",
      },
      folder: null,
    });
  }, []);
  /** handle confirm delete */
  const onSubmitDelete = useCallback(async () => {
    setLoading(true);
    try {
      await deleteMutation.mutateAsync(modalConfig.data.id);
      refreshSearch();
      showToast({
        title: `${cutString(modalConfig.data.title)}を削除しました`,
        type: "success",
      });
      onCloseModal();
    } catch (errRes) {
      const err = handleApiError(errRes);
      if (
        err?.[0]?.path === "id" ||
        err?.[0]?.path === "base" ||
        err?.[0]?.path === "popup"
      ) {
        onCloseModal();
        setError(err[0].message);
      }
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 100);
    }
  }, [modalConfig, deleteMutation, onCloseModal]);

  useEffect(() => {
    reset((prev) => ({
      ...prev,
      tag: tagOptions.find((i) => {
        return i.value == searchForm.tag_id;
      }),
      category: categoryOptions.find(
        (i) =>
          i.value ===
          `${searchForm.parent_category_id}-${searchForm.sub_category_id}`,
      ),
      is_public: statusOptions.find((i) => i.value == searchForm.is_public),
    }));
  }, [statusOptions, tagOptions, categoryOptions]);

  useEffect(() => {
    const params = qs.stringify(searchForm);
    setSearchParams(params);
    return () => {};
  }, [searchForm, setSearchParams]);

  return (
    <>
      <DataTable
        className="mt-6"
        data={datas}
        columns={columns}
        total={paging?.total_records || 0}
        loading={isFetching}
        onChangePage={onChangePage}
        onSorting={onSorting}
        defaultPage={searchForm.page}
        defaultPageSize={pageSize}
        headerSlot={
          <div className="flex gap-3">
            <SuggestionSearch
              oldValue={searchParams.get("title") as string}
              model="elearning"
              labelKey={"title"}
              width="33%"
              className="h-[50px]"
              placeholder="タイトルで絞り込み"
              onSearch={(data) => {
                if (data) {
                  setSearchForm((prev) => ({
                    ...prev,
                    title: isString(data) ? data : data?.title,
                    page: 1,
                  }));
                } else {
                  setSearchForm((prev) => ({
                    ...prev,
                    title: "",
                    page: 1,
                  }));
                }
              }}
            />
            <SuggestionSearch
              oldValue={searchParams.get("fullname") as string}
              model="elearning-users-join"
              labelKey={"fullname"}
              width="33%"
              className="h-[50px]"
              placeholder="ユーザーで絞り込み"
              onSearch={(data) => {
                if (data) {
                  setSearchForm((prev) => ({
                    ...prev,
                    fullname: isString(data) ? data : data?.fullname,
                    join_user_name: isString(data) ? data : data?.fullname,
                    status: "done",
                    page: 1,
                  }));
                } else {
                  setSearchForm((prev) => ({
                    ...prev,
                    fullname: "",
                    join_user_name: "",
                    status: "",
                    page: 1,
                  }));
                }
              }}
            />

            <ControlledSelect
              options={categoryOptions}
              onChangeCallback={handleChangeCategory}
              control={control}
              isClearable={true}
              formField="category"
              placeholder="全てのカテゴリ"
              isVirtualList={true}
              className="w-[33%]"
            />
            <ControlledSelect
              options={tagOptions}
              onChangeCallback={handleChangeTag}
              control={control}
              isClearable={true}
              isVirtualList={true}
              formField="tag"
              placeholder="全てのタグ"
              className="w-[33%]"
            />
            <ControlledSelect
              options={statusOptions}
              onChangeCallback={handleChangeStatus}
              control={control}
              isClearable={true}
              formField="is_public"
              placeholder="公開,非公開"
              className="desktop:min-w-[180px] mobile:min-w-[146px]"
            />
          </div>
        }
        defaultSort={[
          {
            id: searchForm.order_by ?? "updated_at",
            desc: searchForm.order_direction === "desc",
          },
        ]}
      />
      <ModalConfirm
        isOpen={modalConfig.open}
        header={`${cutString(modalConfig.data.title)}の削除`}
        message={
          <>
            <p className="body1 text-[#212B36]">
              {cutString(modalConfig.data?.title)}を削除します。
            </p>
            <p className="body1 text-[#212B36]">よろしいですか？</p>
          </>
        }
        onClose={onCloseModal}
        onSubmit={onSubmitDelete}
      />
      <ModalError
        message={
          err.length > 0 && (
            <p className="text-error--main input-error__message mb-1">
              {err.replace(/<br\s*\/?>/, "\n")}
            </p>
          )
        }
        isOpen={!!err.length}
        onClose={() => {
          setError("");
        }}
      />
    </>
  );
}
