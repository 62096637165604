import {
  useDeleteDocumentMutation,
  useShowDocumentQuery,
} from "@/api/document";
import Breadcrumb, {
  BeardcrumbOption,
} from "@/components/atoms/Breadcrumb/breadcrumb";
import Button from "@/components/atoms/Button/button";
import CustomTooltip from "@/components/atoms/Tooltip/tooltip";
import ContentSkeleton from "@/components/organisms/ComplianceTrainingManagement/content.skeleton";
import { ROUTES_CONSTANT } from "@/configs/constants";
import AnimatedRoute from "@/router/transition.route";
import { Skeleton, useDisclosure } from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { API_PATH_CONSTANT } from "@/api/constant";
import DOMPurify from "dompurify";
import PDFViewer from "@/components/molecules/PDFview/pdf-viewer";
import { getRoute } from "@/shared/get/route";
import { useDetailFolderQuery } from "@/api/folder";
import { ModalConfirm } from "@/components/molecules/ModalConfirm/modal-confirm";
import { cutString, getFoldersBreadcrumbs } from "@/shared/transform";
import { useGlobalStore } from "@/states/global.state";
import useToast from "@/hooks/toast";
import { handleApiError } from "@/hooks/error";
import { ModalError } from "@/components/molecules/ModalError/modal-error";
import { DetailFolderResponseBody } from "@/api/folder/request";
import useRole from "@/hooks/role";
import { ROLE_ENUM } from "@/configs/enum";
import clsx from "clsx";
import { useAuthStore } from "@/states/auth.state";
import { documentVideos } from "@/api/video/request";

function DetailDocumentPage() {
  useRole(false, [
    ROLE_ENUM.MEMBER,
    ROLE_ENUM.ADMIN_SYSTEM,
    ROLE_ENUM.CONTENT_MANAGER,
  ]);
  const navigate = useNavigate();
  const params = useParams();
  const { showToast } = useToast();
  const { setLoading } = useGlobalStore();
  const {
    isOpen: isOpenModalDelete,
    onOpen: openModalDelete,
    onClose: closeModalDelete,
  } = useDisclosure();
  const { userInfo } = useAuthStore();
  const deleteDocumentMutation = useDeleteDocumentMutation();
  const [err, setError] = useState("");
  const id = params.id as string;
  const folder_id = params.folder_id as string;

  const { data: documentInfo, isFetching } = useShowDocumentQuery(
    folder_id,
    id,
  );

  const {
    data: folderInfo,
    isFetching: isFetchingFolder,
    refetch,
  } = useDetailFolderQuery({
    id: Number(folder_id),
  });

  const onDeleteFile = async () => {
    try {
      setLoading(true);
      await deleteDocumentMutation.mutateAsync({
        id: Number(id),
        folder_id: Number(folder_id),
      });

      showToast({
        title: `${cutString(documentInfo?.title)}を削除しました`,
        type: "success",
      });
      closeModalDelete();
      navigate(`${ROUTES_CONSTANT.CONTENT_DOCUMENT_LIST}`);
    } catch (errRes) {
      const err = handleApiError(errRes);
      if (
        err?.[0]?.path === "id" ||
        err?.[0]?.path === "base" ||
        err?.[0]?.path === "popup"
      ) {
        closeModalDelete();
        setError(err[0].message);
      }
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 100);
    }
  };

  const url = useMemo(() => {
    if (!documentInfo?.attachment) return "";

    return (
      import.meta.env.VITE_API_URL +
      API_PATH_CONSTANT.GET_FILE +
      "?object_name=" +
      documentInfo?.attachment?.key
    );
  }, [documentInfo]);

  const videoLink = useMemo(() => {
    return documentInfo?.document_videos || [];
  }, [documentInfo]);

  const htmlContent = useMemo(() => {
    const sanitizedHtml = DOMPurify.sanitize(documentInfo?.content ?? "", {
      ADD_TAGS: ["iframe"],
      ADD_ATTR: [
        "allow",
        "allowfullscreen",
        "frameborder",
        "scrolling",
        "src",
        "title",
        "width",
        "height",
        "target",
      ],
      ADD_URI_SAFE_ATTR: ["src"],
    });
    return sanitizedHtml;
  }, [documentInfo]);

  const breadcrumbs: BeardcrumbOption[] = useMemo(() => {
    const getFolders = getFoldersBreadcrumbs(folderInfo);
    const urlFolders = getFolders?.map((item: DetailFolderResponseBody) => {
      return {
        label: item?.name,
        route: `${ROUTES_CONSTANT.CONTENT_DOCUMENT_LIST}?id=${item?.id}&init=1`,
      };
    });

    return [
      {
        label: "コンテンツ",
        route: ROUTES_CONSTANT.CONTENT_DOCUMENT_LIST,
      },
      {
        label: "ドキュメント一覧",
        route: ROUTES_CONSTANT.CONTENT_DOCUMENT_LIST,
      },
      ...urlFolders,
      {
        label: `${documentInfo?.title}`,
      },
    ];
  }, [folderInfo, documentInfo]);

  const goToEditPage = () => {
    navigate(
      getRoute(ROUTES_CONSTANT.CONTENT_DOCUMENT_EDIT, {
        id: id,
        folder_id: folder_id,
      }),
    );
  };

  useEffect(() => {
    refetch();
  }, [folder_id]);

  const isDeleted = useMemo(() => {
    return documentInfo?.creator_id === userInfo?.id;
  }, [documentInfo, userInfo]);

  const goToVideoPage = (item: documentVideos) => {
    navigate(
      getRoute(ROUTES_CONSTANT.CONTENT_VIDEO_DETAIL, {
        id: item?.video?.id,
        folder_id: item?.video?.folder_id,
      }),
    );
  };

  return (
    <div className="m-auto max-w-[960px] w-full desktop:mt-12 mobile:mt-6 px-4">
      {isFetchingFolder ? (
        <Skeleton height={"16px"} rounded={12} />
      ) : (
        <Breadcrumb options={breadcrumbs} />
      )}
      <div
        className={clsx(
          "flex items-center gap-2 mt-5 mobile:flex-wrap desktop:flex-nowrap justify-end",
        )}
      >
        <h4
          className={clsx(
            "font-black mobile:w-full",
            isDeleted
              ? "desktop:w-[calc(100%-206px)]"
              : "desktop:w-[calc(100%-98px)]",
          )}
        >
          <CustomTooltip text={documentInfo?.title} />
        </h4>

        <div className="flex flex-row gap-4 break-keep">
          {isDeleted && (
            <Button
              variant="outline"
              size="sm"
              buttonCustom={{
                borderRadius: "6px",
                padding: "8px 13px",
              }}
              className=""
              onClick={openModalDelete}
            >
              削除する
            </Button>
          )}
          <Button
            size="sm"
            buttonCustom={{
              borderRadius: "6px",
              padding: "8px 13px",
            }}
            className=""
            onClick={goToEditPage}
          >
            編集する
          </Button>
        </div>
      </div>
      <div className="my-6 min-h-[640px]">
        {isFetching ? (
          <ContentSkeleton />
        ) : (
          <>
            {url && (
              <div className="desktop:mx-[52px] mb-6">
                <PDFViewer file={url} />
              </div>
            )}
            {htmlContent && (
              <div
                className={
                  "shadow-card bg-white rounded-[20px] p-8 w-full ck-content"
                }
                dangerouslySetInnerHTML={{ __html: htmlContent ?? "" }}
              ></div>
            )}
            {videoLink?.length > 0 && (
              <div
                className={
                  "shadow-card bg-white rounded-[20px] mt-6 w-full ck-content p-8"
                }
              >
                <p className="subtitle1 font-bold text-[#637381]">関連動画</p>
                <div className="flex flex-col gap-2">
                  {videoLink?.map((item, index) => {
                    return (
                      <span
                        key={index}
                        className="body1 text-[var(--primary-main-color)] underline cursor-pointer"
                        onClickCapture={() => goToVideoPage(item)}
                      >
                        {item?.video?.title}
                      </span>
                    );
                  })}
                </div>
              </div>
            )}
          </>
        )}
      </div>
      <ModalConfirm
        isOpen={isOpenModalDelete}
        onClose={() => {
          setError("");
          closeModalDelete();
        }}
        header={`${cutString(documentInfo?.title)}の削除`}
        message={
          <>
            <p className="body1 text-[#212B36]">
              {cutString(documentInfo?.title)}を削除します。
            </p>
            <p className="body1 text-[#212B36]">よろしいですか？</p>
          </>
        }
        onSubmit={onDeleteFile}
      />
      <ModalError
        message={
          err.length > 0 && (
            <p className="text-error--main input-error__message mb-1">
              {err.replace(/<br\s*\/?>/, "\n")}
            </p>
          )
        }
        isOpen={!!err.length}
        onClose={() => {
          setError("");
        }}
      />
    </div>
  );
}

const AnimatedDetailDocumentPage = AnimatedRoute(DetailDocumentPage);
export default AnimatedDetailDocumentPage;
