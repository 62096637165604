import AdminPage from "@/pages/admin";
import ResetPasswordPage from "@/pages/admin/auth/forgot-password";
import ResetPasswordSuccessPage from "@/pages/admin/auth/forgot-password/success";
import LoginPage from "@/pages/admin/auth/login";
import SettingInformationFirstLogin from "@/pages/admin/auth/setting-account/basic-information";
import SettingPasswordFirstLogin from "@/pages/admin/auth/setting-account/password";
import SetPasswordPage from "@/pages/admin/auth/setting-password";
import SetPasswordSuccessPage from "@/pages/admin/auth/setting-password/success";
import ManagementPage from "@/pages/admin/management";
import AffiliationPage from "@/pages/admin/management/affiliation";
import AnimatedDepartmentListPage from "@/pages/admin/management/affiliation/department";
import AnimatedOfficeListPage from "@/pages/admin/management/affiliation/office";
import CategoryTagPage from "@/pages/admin/management/category-tag";
import AnimatedCategoryListPage from "@/pages/admin/management/category-tag/category";
import AnimatedTagListPage from "@/pages/admin/management/category-tag/tag";
import ContentPage from "@/pages/admin/management/contents";
import ELearningManagementPage from "@/pages/admin/management/e-learning";
import AnimatedCreateELearningPage from "@/pages/admin/management/e-learning/create";
import UserManagementPage from "@/pages/admin/management/users";
import AnimatedCreateUserPage from "@/pages/admin/management/users/create";
import AnimatedUserDetailPage from "@/pages/admin/management/users/detail";
import AnimatedEditUserPage from "@/pages/admin/management/users/edit";
import AnimatedUserListPage from "@/pages/admin/management/users/list";
import AnimatedELearningListPage from "@/pages/admin/management/e-learning/list";
import AnimatedELearningDetailPage from "@/pages/admin/management/e-learning/detail";
import AnimatedEditELearningPage from "@/pages/admin/management/e-learning/edit";
import ContentDocumentPage from "@/pages/admin/management/contents/document";
import AnimatedListDocumentPage from "@/pages/admin/management/contents/document/list";
import ContentVideoPage from "@/pages/admin/management/contents/video";
import AnimatedListVideoPage from "@/pages/admin/management/contents/video/list";
import AnimatedCreateVideoPage from "@/pages/admin/management/contents/video/create";
import AnimatedCreateDocumentPage from "@/pages/admin/management/contents/document/create";
import SetPasswordFailPage from "@/pages/admin/auth/setting-password/fail";
import PageInternalServer from "@/pages/errors/500";
import PageNotFound from "@/pages/errors/404";
import PageForbidden from "@/pages/errors/403";
import AnimatedSetPasswordUserPage from "@/pages/admin/management/users/setting-password";
import ComplieanceTrainingManagementPage from "@/pages/admin/management/compliance-training";
import AnimatedComplieanceTrainingListPage from "@/pages/admin/management/compliance-training/list";
import AnimatedCreateComplianceTrainingPage from "@/pages/admin/management/compliance-training/create";
import AnimatedDetailComplianceTrainingPage from "@/pages/admin/management/compliance-training/detail";
import AnimatedEditComplianceTrainingPage from "@/pages/admin/management/compliance-training/edit";
import SetAccountPasswordFailPage from "@/pages/admin/auth/setting-account/fail";
import QuizzManagementPage from "@/pages/admin/management/quizz";
import AnimatedQuizzListPage from "@/pages/admin/management/quizz/list";
import AnimatedCreateQuizzPage from "@/pages/admin/management/quizz/create";
import AnimatedDetailQuizzPage from "@/pages/admin/management/quizz/detail";
import AnimatedEditQuizzPage from "@/pages/admin/management/quizz/edit";
import AnimatedEditDocumentPage from "@/pages/admin/management/contents/document/edit";
import AnimatedDetailDocumentPage from "@/pages/admin/management/contents/document/detail";
import AnimatedEditVideoPage from "@/pages/admin/management/contents/video/edit";
import AnimatedDetailVideoPage from "@/pages/admin/management/contents/video/detail";
import ErrorBoundary from "@/pages/errors/ErrorBoundary";
// const appName = "Green Book Cloud";

export default {
  path: "/admin",
  element: <AdminPage />,
  children: [
    {
      path: "login",
      element: <LoginPage />,
    },
    {
      path: "forgot-password",
      element: <ResetPasswordPage />,
    },
    {
      path: "forgot-password/success",
      element: <ResetPasswordSuccessPage />,
    },
    {
      path: "setting-password",
      element: <SetPasswordPage />,
    },
    {
      path: "setting-password/success",
      element: <SetPasswordSuccessPage />,
    },
    {
      path: "setting-password/fail",
      element: <SetPasswordFailPage />,
    },
    {
      path: "setting-account/password",
      element: <SettingPasswordFirstLogin />,
    },
    {
      path: "setting-account/password/fail",
      element: <SetAccountPasswordFailPage />,
    },
    {
      path: "setting-account/basic-information",
      element: <SettingInformationFirstLogin />,
    },
    {
      path: "403",
      element: <PageForbidden isAdmin={true} />,
    },
    {
      path: "404",
      element: <PageNotFound isAdmin={true} />,
    },
    {
      path: "500",
      element: <PageInternalServer isAdmin={true} />,
    },
    {
      path: "management",
      element: <ManagementPage />,
      children: [
        {
          path: "user",
          element: <UserManagementPage />,
          children: [
            {
              path: "list",
              element: <AnimatedUserListPage />,
            },
            {
              path: "create",
              element: <AnimatedCreateUserPage />,
            },
            {
              path: ":id/edit",
              element: <AnimatedEditUserPage />,
            },
            {
              path: ":id",
              element: <AnimatedUserDetailPage />,
            },
            {
              path: ":id/setting-password",
              element: <AnimatedSetPasswordUserPage />,
            },
            {
              path: ":id/setting-password/success",
              element: <AnimatedSetPasswordUserPage />,
            },
          ],
        },
        {
          path: "e-learning",
          element: <ELearningManagementPage />,
          children: [
            {
              path: "list",
              element: <AnimatedELearningListPage />,
            },
            {
              path: "create",
              element: <AnimatedCreateELearningPage />,
            },
            {
              path: ":id",
              element: <AnimatedELearningDetailPage />,
            },
            {
              path: ":id/edit",
              element: <AnimatedEditELearningPage />,
            },
            {
              path: ":elearning_id/user/:id",
              element: <AnimatedUserDetailPage />,
            },
            {
              path: ":elearning_id/user/:id/edit",
              element: <AnimatedEditUserPage />,
            },
            {
              path: ":elearning_id/user/:id/setting-password",
              element: <AnimatedSetPasswordUserPage />,
            },
            {
              path: ":elearning_id/user/:id/setting-password/success",
              element: <AnimatedSetPasswordUserPage />,
            },
          ],
        },
        {
          path: "compliance-training",
          element: <ComplieanceTrainingManagementPage />,
          children: [
            {
              path: "list",
              element: <AnimatedComplieanceTrainingListPage />,
            },
            {
              path: "create",
              element: <AnimatedCreateComplianceTrainingPage />,
            },
            {
              path: ":id",
              element: <AnimatedDetailComplianceTrainingPage />,
            },
            {
              path: ":id/edit",
              element: <AnimatedEditComplianceTrainingPage />,
            },
          ],
        },
        {
          path: "quizz",
          element: <QuizzManagementPage />,
          children: [
            {
              path: "list",
              element: <AnimatedQuizzListPage />,
            },
            {
              path: "create",
              element: <AnimatedCreateQuizzPage />,
            },
            {
              path: ":id",
              element: <AnimatedDetailQuizzPage />,
            },
            {
              path: ":id/edit",
              element: <AnimatedEditQuizzPage />,
            },
            {
              path: ":quiz_id/user/:id",
              element: <AnimatedUserDetailPage />,
            },
            {
              path: ":quiz_id/user/:id/edit",
              element: <AnimatedEditUserPage />,
            },
            {
              path: ":quiz_id/user/:id/setting-password",
              element: <AnimatedSetPasswordUserPage />,
            },
            {
              path: ":quiz_id/user/:id/setting-password/success",
              element: <AnimatedSetPasswordUserPage />,
            },
          ],
        },
        {
          path: "content",
          element: <ContentPage />,
          children: [
            {
              path: "document",
              element: <ContentDocumentPage />,
              children: [
                {
                  path: "list",
                  element: <AnimatedListDocumentPage />,
                },
                {
                  path: ":folder_id/create",
                  element: <AnimatedCreateDocumentPage />,
                },
                {
                  path: ":folder_id/:id/edit",
                  element: <AnimatedEditDocumentPage />,
                },
                {
                  path: ":folder_id/:id/detail",
                  element: <AnimatedDetailDocumentPage />,
                },
              ],
            },
            {
              path: "video",
              element: <ContentVideoPage />,
              children: [
                {
                  path: "list",
                  element: <AnimatedListVideoPage />,
                },
                {
                  path: ":folder_id/create",
                  element: <AnimatedCreateVideoPage />,
                },
                {
                  path: ":folder_id/:id/edit",
                  element: <AnimatedEditVideoPage />,
                },
                {
                  path: ":folder_id/:id/detail",
                  element: <AnimatedDetailVideoPage />,
                },
              ],
            },
          ],
        },
        {
          path: "category-tag",
          element: <CategoryTagPage />,
          children: [
            {
              path: "category/list",
              element: <AnimatedCategoryListPage />,
            },
            {
              path: "tag/list",
              element: <AnimatedTagListPage />,
            },
          ],
        },
        {
          path: "affiliation",
          element: <AffiliationPage />,
          children: [
            {
              path: "office/list",
              element: <AnimatedOfficeListPage />,
            },
            {
              path: "department/list",
              element: <AnimatedDepartmentListPage />,
            },
          ],
        },
      ],
    },
  ],
  errorElement: <ErrorBoundary />,
};
