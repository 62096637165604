import { ChangeEvent, useState, useRef, useEffect, DragEvent } from "react";
import { MESSAGES } from "@/shared/validation/message";
import { convertKBtoByte, convertMBtoByte } from "@/shared/transform/file";
import { getErrorMessage } from "@/shared/validation/yup";
import clsx from "clsx";
import Button from "../Button/button";
import PdfIcon from "@/assets/icon/pdf.svg";
import CloseIcon from "@/assets/icon/close.svg";
import useUpload, { UploadResponse } from "@/hooks/upload";
import ProcessIcon from "./process";
import { Attachment } from "@/api/type";
import { isValidMimeTypePDF } from "@/shared/get/check";

interface UploadPdfProps {
  sizeUnit?: "KB" | "MB";
  mimeSize?: number;
  isDisabled?: boolean;
  isError?: boolean;
  onChange: (content: UploadResponse | null) => void;
  onRemove?: (content: UploadResponse | null) => void;
  onError: (msg: string) => void;
  attachment?: Attachment;
}

export default function UploadPdf({
  sizeUnit = "MB",
  mimeSize = 20,
  isDisabled = false,
  isError = false,
  onError,
  onChange,
  onRemove,
  attachment,
}: UploadPdfProps): React.ReactElement {
  const { loading, uploadFn, processNum } = useUpload();
  const [fileName, setFileName] = useState("");
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const clickInput = () => {
    !isDisabled && inputRef.current?.click();
  };

  const clickRemove = () => {
    if (attachment)
      onRemove?.({
        ...attachment,
        _destroy: true,
      });
    else {
      onRemove?.(null);
    }
    setFileName("");
    if (inputRef.current) {
      inputRef.current.value = "";
    }
  };

  const _onChange = async (file: File) => {
    if (!isValidMimeTypePDF(file)) {
      return onError(
        getErrorMessage(MESSAGES.MSG_016, {
          type: `pdf`,
        }),
      );
    }
    if (sizeUnit === "MB" && file.size > convertMBtoByte(mimeSize)) {
      return onError(
        getErrorMessage(MESSAGES.MSG_014, {
          max: `${mimeSize}MB`,
        }),
      );
    }
    if (sizeUnit === "KB" && file.size > convertKBtoByte(mimeSize)) {
      return onError(
        getErrorMessage(MESSAGES.MSG_014, {
          max: `${mimeSize}KB`,
        }),
      );
    }

    if (file) {
      const content = await uploadFn(file);
      if (content && !content.error) {
        setFileName(file.name);
        onChange(content);
        onError("");
      } else {
        if (inputRef.current) inputRef.current.value = "";
        return onError(content?.error ?? MESSAGES.MSG_036);
      }
    } else {
      if (inputRef.current) inputRef.current.value = "";
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      _onChange(e.target.files[0]);
    }
  };

  const handleDrop = async (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    if (loading || isDisabled) return;
    if (e?.dataTransfer?.files?.length > 1) {
      onError(MESSAGES.MS_048);
      return;
    }
    const file = e.dataTransfer.files[0];
    if (file) {
      _onChange(file);
    }
  };

  const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (isDisabled) return;
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    if (attachment) setFileName(attachment.name);
  }, [attachment]);

  return (
    <div
      className={clsx(
        "w-full flex justify-center border-[1px] border-dashed border-[#E6E8EA] p-4 rounded-[8px] h-[120px]",
        isError && "border-red-600 !border-solid",
        isDragging ? "bg-[#d0eaff]" : "bg-[#F8F8F8]",
      )}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
    >
      {loading ? (
        <div className="flex flex-col justify-center">
          <ProcessIcon />
          <span className="text-[#73be1e] font-bold text-sm text-center">
            {processNum}%
          </span>
        </div>
      ) : fileName ? (
        <div className="flex items-center justify-start w-full">
          <PdfIcon className="flex-shrink-0" />
          <span className="body1 ml-2 mr-4 text-[#212B36] line-break-anywhere line-clamp-3">
            {fileName}
          </span>
          <div className="cursor-pointer">
            <CloseIcon
              width={16}
              height={16}
              onClickCapture={clickRemove}
              className="mt-[1px]"
            />
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center gap-y-[10px] justify-center">
          <span className="caption1 text-[#919EAB]">
            ドラッグ&ドロップでもファイルをアップロードすることができます
          </span>
          <Button
            size="sm"
            className={"w-[160px]"}
            buttonCustom={{
              padding: "8px 16px",
              fontSize: "14px",
              lineHeight: "24px",
              fontWeight: "700",
              borderRadius: "8px",
            }}
            onClick={clickInput}
            isDisabled={isDisabled}
          >
            アップロード
          </Button>
          <span className="caption1 text-[#919EAB]">PDFファイルのみ対応</span>
        </div>
      )}
      <input
        ref={inputRef}
        id="upload-input"
        type="file"
        accept="application/pdf, .pdf"
        className="hidden"
        onChange={handleInputChange}
      />
    </div>
  );
}
