import { QUESTION_KIND_ENUM } from "./enum";

export const COURSE_DEADLINE_7_DAYS = 7;

export const PAGINATION_CONSTANT = {
  DEFAULT_PAGE: 1,
  DEFAULT_PAGE_SIZE: 10,
  MAX_PAGE_SIZE: 999999,
  DEFAULT_PAGE_SIZE_FOLDER: 20,
} as const;

export const ROUTES_CONSTANT = {
  FORBIDDEN: "/403",
  LOGIN: "/login",
  FORGOT: "/forgot-password",
  FORGOT_SUCCESS: "/forgot-password/success",
  SET_PASSWORD: "/setting-password",
  SET_PASSWORD_SUCCESS: "/setting-password/success",
  SET_PASSWORD_FAIL: "/setting-password/fail",
  SET_PASSWORD_FIRST_LOGIN: "/setting-account/password",
  SET_INFOR_FIRST_LOGIN: "/setting-account/basic-information",
  SET_FIRST_PASSWORD_FAIL: "/setting-account/password/fail",
  MY_PAGE: "/my-page",
  MY_PAGE_CHANGE_EMAIL: "/my-page/change-email",
  MY_PAGE_CHANGE_INFO: "/my-page/change-infor",
  MY_PAGE_CHANGE_PASSWORD: "/my-page/change-password",
  CONTENT: "/content",
  CONTENT_LIST: "/content/list",
  CONTENT_DOCUMENT_LIST: "/content/document/list",
  CONTENT_DOCUMENT_EDIT: "/content/document/:folder_id/:id/edit",
  CONTENT_DOCUMENT_DETAIL: "/content/document/:folder_id/:id/detail",
  CONTENT_DOCUMENT_CREATE: "/content/document/:folder_id/create",
  CONTENT_VIDEO_LIST: "/content/video/list",
  CONTENT_VIDEO_EDIT: "/content/video/:folder_id/:id/edit",
  CONTENT_VIDEO_DETAIL: "/content/video/:folder_id/:id/detail",
  CONTENT_VIDEO_CREATE: "/content/video/:folder_id/create",
  ELEARNING: "/e-learning",
  ELEARNING_LIST: "/e-learning/list",
  ELEARNING_DETAIL: "/e-learning/:id",
  MYPAGE_ELEARNING_DETAIL: "/my-page/e-learning/:id",
  DASHBOARD: "/dashboard",
} as const;

export const ADMIN_ROUTES_CONSTANT = {
  FORBIDDEN: "/admin/403",
  LOGIN: "/admin/login",
  FORGOT: "/admin/forgot-password",
  FORGOT_SUCCESS: "/admin/forgot-password/success",
  SET_PASSWORD: "/admin/setting-password",
  SET_PASSWORD_SUCCESS: "/admin/setting-password/success",
  SET_PASSWORD_FIRST_LOGIN: "/admin/setting-account/password",
  SET_INFOR_FIRST_LOGIN: "/admin/setting-account/basic-information",
  SET_PASSWORD_FAIL: "/admin/setting-password/fail",
  SET_FIRST_PASSWORD_FAIL: "/admin/setting-account/password/fail",
  MANAGEMENT: {
    USER: "/admin/management/user",
    USER_LIST: "/admin/management/user/list",
    USER_CREATE: "/admin/management/user/create",
    USER_EDIT: "/admin/management/user/:id/edit",
    USER_DETAIL: "/admin/management/user/:id",
    USER_SET_PASSWORD: "/admin/management/user/:id/setting-password",
    ELEARNING: "/admin/management/e-learning",
    ELEARNING_LIST: "/admin/management/e-learning/list",
    ELEARNING_CREATE: "/admin/management/e-learning/create",
    ELEARNING_EDIT: "/admin/management/e-learning/:id/edit",
    ELEARNING_DETAIL: "/admin/management/e-learning/:id",
    ELEARNING_DETAIL_USER:
      "/admin/management/e-learning/:elearning_id/user/:id",
    ELEARNING_EDIT_USER:
      "/admin/management/e-learning/:elearning_id/user/:id/edit",
    ELEARNING_DETAIL_USER_SET_PASSWORD:
      "/admin/management/e-learning/:elearning_id/user/:id/setting-password",
    COMPLIANCE_TRAINING: "/admin/management/compliance-training",
    COMPLIANCE_TRAINING_LIST: "/admin/management/compliance-training/list",
    COMPLIANCE_TRAINING_CREATE: "/admin/management/compliance-training/create",
    COMPLIANCE_TRAINING_EDIT: "/admin/management/compliance-training/:id/edit",
    COMPLIANCE_TRAINING_DETAIL: "/admin/management/compliance-training/:id",
    QUIZZ: "/admin/management/quizz",
    QUIZZ_LIST: "/admin/management/quizz/list",
    QUIZZ_CREATE: "/admin/management/quizz/create",
    QUIZZ_EDIT: "/admin/management/quizz/:id/edit",
    QUIZZ_DETAIL: "/admin/management/quizz/:id",
    QUIZZ_DETAIL_USER: "/admin/management/quizz/:quiz_id/user/:id",
    QUIZZ_EDIT_USER: "/admin/management/quizz/:quiz_id/user/:id/edit",
    QUIZZ_DETAIL_USER_SET_PASSWORD:
      "/admin/management/quizz/:quiz_id/user/:id/setting-password",
    CONTENT: "/admin/management/content",
    CONTENT_DOCUMENT: "/admin/management/content/document",
    CONTENT_DOCUMENT_LIST: "/admin/management/content/document/list",
    CONTENT_DOCUMENT_CREATE:
      "/admin/management/content/document/:folder_id/create",
    CONTENT_DOCUMENT_EDIT:
      "/admin/management/content/document/:folder_id/:id/edit",
    CONTENT_DOCUMENT_DETAIL:
      "/admin/management/content/document/:folder_id/:id/detail",
    CONTENT_VIDEO: "/admin/management/content/video",
    CONTENT_VIDEO_LIST: "/admin/management/content/video/list",
    CONTENT_VIDEO_CREATE: "/admin/management/content/video/:folder_id/create",
    CONTENT_VIDEO_EDIT: "/admin/management/content/video/:folder_id/:id/edit",
    CONTENT_VIDEO_DETAIL:
      "/admin/management/content/video/:folder_id/:id/detail",
    CATEGORY_TAG: "/admin/management/category-tag",
    CATEGORY: "/admin/management/category-tag/category/list",
    TAG: "/admin/management/category-tag/tag/list",
    AFFILIATION: "/admin/management/affiliation",
    OFFICE: "/admin/management/affiliation/office/list",
    DEPARTMENT: "/admin/management/affiliation/department/list",
  },
} as const;

export const ELEARNING_CONTENT_TYPE = {
  VIDEO: {
    value: 2,
    label: "動画学習",
  },
  DOCUMENT: {
    value: 1,
    label: "ドキュメント",
  },
  TEST: {
    value: 3,
    label: "テスト",
  },
  ROLE_PLAYING: {
    value: 4,
    label: "ロールプレイング",
  },
  COMPLIANCE_TRAINING: {
    value: 5,
    label: "コンプライアンス研修",
  },
} as const;

export const ELEARNING_CONTENT_TYPE_OPTIONS = Object.entries(
  ELEARNING_CONTENT_TYPE,
).map((option) => {
  return {
    label: option[1].label,
    value: option[1].value,
  };
});

export const ELEARNING_CONTENT_TYPE_LABEL = {
  document: "ドキュメント",
  video: "動画学習",
  quiz: "テスト",
  roleplaying_theme: "ロールプレイング",
  compliance: "コンプライアンス研修",
} as const;

export const ELEARNING_CONTENT_TYPE_LABEL_2 = {
  document: "ドキュメント学習",
  video: "動画視聴",
  quiz: "テスト",
  roleplaying_theme: "ロールプレイング",
  compliance: "コンプライアンス研修",
} as const;

export const QUESTION_KIND = {
  MULTI_ANSWER: {
    value: QUESTION_KIND_ENUM.MULTI_ANSWER,
    label: "複数選択",
  },
  SINGLE_ANSWER: {
    value: QUESTION_KIND_ENUM.SINGLE_ANSWER,
    label: "単一選択",
  },
  FREE_INPUT: {
    value: QUESTION_KIND_ENUM.FREE_INPUT,
    label: "自由入力",
  },
} as const;

export const QUESTION_KIND_OPTIONS = Object.entries(QUESTION_KIND).map(
  (option) => {
    return {
      label: option[1].label,
      value: option[1].value,
    };
  },
);

export const MAX_LENGTH = {
  TEXT: 65535,
  MINI_TEXT: 1000,
  VARCHAR: 255,
  PASSWORD: 128,
  EMAIL: 100,
} as const;

export const STATUS_OPTION = [
  {
    value: "",
    label: "公開・非公開",
  },
  {
    value: "true",
    label: "公開",
  },
  {
    value: "false",
    label: "非公開",
  },
];
