import { ControlledInput } from "@/components/molecules/ControlledInput/controlled-input";
import {
  ILoginForm,
  useLoginForm,
} from "@/components/organisms/FormLogin/validation";
import MailIcon from "@/assets/icon/mail.svg";
import LockIcon from "@/assets/icon/lock.svg";
import EyeCloseIcon from "@/assets/icon/eye-close.svg";
import EyeOpenIcon from "@/assets/icon/eye-open.svg";
import { useCallback, useState } from "react";
import Button from "@/components/atoms/Button/button";
import { useNavigate } from "react-router-dom";
import {
  ADMIN_ROUTES_CONSTANT,
  MAX_LENGTH,
  ROUTES_CONSTANT,
} from "@/configs/constants";
import { fetchMyProfile, useLoginMutation } from "@/api/auth";
import { MESSAGES } from "@/shared/validation/message";
import Cookies from "js-cookie";
import { STORAGE_KEYS } from "@/api/axios";
import { useGlobalStore } from "@/states/global.state";
import { useScrollToFirstError } from "@/hooks/error";
import { ROLE_ENUM } from "@/configs/enum";
import { useQueryClient } from "@tanstack/react-query";
import { useAuthStore } from "@/states/auth.state";

interface FormLoginProps {
  isAdminPage?: boolean;
}

/**
 * This component renders a form for login.
 */
export default function FormLogin({ isAdminPage = false }: FormLoginProps) {
  const { setUserInfo } = useAuthStore();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [loginFail, setLoginFail] = useState(false);
  const loginMutation = useLoginMutation();
  const { setLoading, loading } = useGlobalStore();

  const [typeInput, setTypeInput] = useState<"password" | "text">("password");
  const { control, formState, handleSubmit } = useLoginForm();
  const { errors } = formState;
  useScrollToFirstError(formState);

  /**
   * Handle form submission for login.
   *
   * @param {ILoginForm} datas - The login form data.
   * @return {void} No return value.
   */
  const onSubmit = async (datas: ILoginForm) => {
    Cookies.remove(STORAGE_KEYS.ACCESS_TOKEN);
    setLoginFail(false);
    setLoading(true);
    try {
      await loginMutation.mutateAsync({
        email: datas.email,
        password: datas.password,
        is_admin: isAdminPage,
      });
      const info = await fetchMyProfile(queryClient);
      setUserInfo(info);

      if (isAdminPage) {
        if (info.role.id === ROLE_ENUM.ADMIN_SYSTEM) {
          navigate(ADMIN_ROUTES_CONSTANT.MANAGEMENT.USER_LIST);
        } else {
          navigate(ADMIN_ROUTES_CONSTANT.MANAGEMENT.ELEARNING_LIST);
        }
      } else {
        navigate(ROUTES_CONSTANT.DASHBOARD);
      }
    } catch (e) {
      setLoginFail(true);
    } finally {
      setLoading(false);
    }
  };

  const goToForgotPage = useCallback(() => {
    navigate(
      isAdminPage ? ADMIN_ROUTES_CONSTANT.FORGOT : ROUTES_CONSTANT.FORGOT,
    );
  }, []);

  return (
    <div className="flex flex-col justify-center mobile:py-8 mobile:px-4 desktop:p-8 whitespace-nowrap bg-white rounded-3xl w-full max-w-[572px]">
      <form
        className="flex flex-col max-md:max-w-full"
        onSubmit={handleSubmit(onSubmit, () => setLoginFail(false))}
      >
        {loginFail && (
          <div className="text-wrap input-error__message text-error--main mb-2 is-invalid">
            {MESSAGES.MSG_017}
          </div>
        )}
        <ControlledInput
          control={control}
          formField="email"
          label="メールアドレス"
          isRequired={true}
          startSlot={<MailIcon />}
          placeholder="sample@greenbookcloud.co.jp"
          errorMessage={errors.email?.message}
          id="email"
          name="email"
          autoComplete="email"
          maxLength={100}
          isTrim={true}
        />

        <ControlledInput
          id="password"
          name="password"
          autoComplete="password"
          type={typeInput}
          className="mt-8"
          control={control}
          formField="password"
          label="パスワード"
          note="※半角英数記号10文字以上"
          isRequired={true}
          placeholder="パスワードを入力してください"
          startSlot={<LockIcon />}
          maxLength={MAX_LENGTH.PASSWORD}
          isTrim={true}
          endSlot={
            <div
              className="cursor-pointer"
              onClick={() => {
                setTypeInput((prev) => (prev === "text" ? "password" : "text"));
              }}
            >
              {typeInput === "text" ? <EyeOpenIcon /> : <EyeCloseIcon />}
            </div>
          }
          errorMessage={errors.password?.message}
        />
        <div className="justify-center py-1 mt-1 text-right max-md:max-w-full">
          <span
            className="text-[#3287EB] caption1 cursor-pointer"
            onClick={goToForgotPage}
          >
            パスワードをお忘れの方はこちら
          </span>
        </div>

        <Button
          size="sm"
          className={"mt-12 max-w-[335px] w-full m-auto"}
          type="submit"
          isLoading={loading?.open}
        >
          ログイン
        </Button>
      </form>
    </div>
  );
}
